import React, { Fragment, useState } from 'react';
import { LayoutProvider } from '../../layouts';
import { Card, Row, Col, Avatar, Button, Divider, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Logic from '../../../logic';
import { numberBreakpoints, stringBreakpoints } from '../../constants/breakpoints';
import withSizes from 'react-sizes';
import { PopoutMenu } from '../../components/popoutMenu';
import {
  CloseOutlined,
  SettingOutlined
} from '@ant-design/icons';

const { deleteOrganization } = Logic.general.organizations.actions;

const OrganizationStyleWrapper = styled.div`
  padding: 10vh 7vw;

  .ant-divider {
    margin: 5px 0px;
  }
  .organization {
    &__image {
      width: 100%
    }
    &__action-column {
      text-align: center;
      &__edit-button {
        margin-top: 10px;
      }
    }
  }

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    padding: 10vh 0;

    .organization {
      &__right-col {
        padding: 0 34px !important;
      }
    }    
  }
`;

const ActionButton = styled(Button)`
  background-color: #5BBA6F;
  color: white;
  :hover {
    background-color: #5BBA6F;
    color: white;
  }
`;

const OrganizationOptionsHeader = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #5BBA6F;
`;

const ManagersHeader = styled.span`
  font-size: 20px;
  font-weight: 400;
  font-family: Times New Roman;

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    padding-left: 20px;
  }
`;

const MobileViewWrapper = styled.div`
  padding: 10px 20px;
  width: 100%;
  background: ${p => p.isOdd ? '#f2f2f2' : 'none'};

  .managers {
    &__first-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__second-row {
      padding-top: 20px;
    }

    &__info-section {
      width: 70%;
    }

    &__full-name {
      font-weight: bold;
    }

    &__popout {
      width: 10%;
    }
  }
`;

const StyledActionButton = styled.div`
  padding: 12.5px;
  background: #d5733f;
  cursor: pointer;
`;


const columns = [
  {
    render: (manager) => {
      return <Avatar src={manager.picture} />;
    },
    key: 'avatar',
  },
  {
    title: 'Name',
    render: (manager) => {
      return <p>{manager.firstName} {manager.lastName}</p>;
    },
    key: 'name'
  },
  {
    title: 'Email address',
    render: (manager) => {
      console.log("manager", manager, manager.email);
      return <p>{manager.email}</p>;
    },
    key: 'email',
  },
  {
    title: 'Review Status',
    render: () => {
      return <p></p>;
    },
    key: 'managers',
  },
  {
    render: () => {
      // cool '...' buttons should go here
      return <p>...</p>;
    }
  }
];

export const Dashboard = ({ isMobile }) => {
  const dispatch = useDispatch();
  const organizations = useSelector(state => state.containers['general::organizations'].organizations);
  const currentOrganizationId = useSelector(state => state.containers['general::router'].parameters.params.orgId);
  const [areOptionsShown, setAreOptionsShown] = useState(false);

  let currentOrganization = null;
  if (organizations && currentOrganizationId) {
    currentOrganization = organizations.find(x => x.id === currentOrganizationId);
  }

  if (!currentOrganization) { return null; }

  const actionIconStyle = { fontSize: 30, color: 'white' };

  return (
    <LayoutProvider
      type='internal'
      backgroundImageStyle={{ backgroundPosition: "center", backgroundSize: 'cover', backgroundImage: isMobile ? (currentOrganization.picture || "url(https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-business-building.png)") : "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-business.png')" }}
      pageTitle={`${currentOrganization.company}`}
      noGutter={isMobile}
      actionButton={
        isMobile && (
          <StyledActionButton onClick={() => setAreOptionsShown(!areOptionsShown)}>
            {!areOptionsShown ? <SettingOutlined style={actionIconStyle} /> : <CloseOutlined style={actionIconStyle} />}
          </StyledActionButton>
        )
      }
    >
      <OrganizationStyleWrapper>
        <Row gutter={32}>
          <Col span={isMobile ? 24 : 16} style={isMobile && areOptionsShown ? { display: "none" } : {}}>
            <Card>
              {
                isMobile ? (
                  <Fragment>
                    <Row style={{ marginBottom: 10 }}>
                      <Col span={24}>
                        <p style={{ fontSize: 15 }}>Business Address</p>
                        <div>
                          <p style={{ fontSize: 26 }}>{currentOrganization.address}</p>
                          <p style={{ fontSize: 26 }}>{currentOrganization.city}, Washington {currentOrganization.zipCode}</p>
                          <p style={{ fontSize: 13 }}>{currentOrganization.contactEmail|| currentOrganization.owner.email || 'example@example.com' }</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className={'organization__action-column'} style={{ textAlign: 'left' }}>
                        {/* <div><Avatar size={64} src={'https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-business-building.png'} /></div> */}
                        <ActionButton
                          onClick={() => {
                            dispatch(Logic.general.router.actions.navigateToRoute({ to: `/ma/organizations/${currentOrganization.id}/edit`, replace: false }));
                          }}
                        >
                          Edit Details
                        </ActionButton>
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <Row>
                    <Col span={18}>
                      <div><span>Business Address</span> </div>
                      <div>
                        <div><h3>{currentOrganization.address}</h3></div>
                        <div><h3>{currentOrganization.city}, Washington {currentOrganization.zipCode}</h3></div>
                        <div><h3>{currentOrganization.contactEmail}</h3></div>
                      </div>
                    </Col>
                    <Col span={6} className={'organization__action-column'}>
                      <div><Avatar size={64} src={'https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-business-building.png'} /></div>
                      <div className={'organization__action-column__edit-button'}><ActionButton onClick={() => {
                        dispatch(Logic.general.router.actions.navigateToRoute({ to: `/ma/organizations/${currentOrganization.id}/edit`, replace: false }));
                      }}>Edit Details</ActionButton></div>
                    </Col>
                  </Row>
                )
              }
            </Card>
            <br />
            <div>
              <ManagersHeader>
                Managers
                </ManagersHeader>
              <Divider />
              {
                isMobile ? (
                  currentOrganization.managers.map((manager, i) => {
                    return (
                      <MobileViewWrapper
                        key={manager.id}
                        className="managers"
                        isOdd={(i + 1) % 2}
                        onClick={() => dispatch(Logic.general.router.actions.navigateToRoute({ to: `/ma/managers/${manager.id}`, replace: false }))}
                      >
                        <div className="managers__first-row">
                          <Avatar
                            className="managers__avatar"
                            src={manager.picture ? manager.picture.link : 'https://invigor-dev.s3-us-west-2.amazonaws.com/user1.png'}
                          />
                          <div className="managers__info-section">
                            <p className="managers__full-name">{manager.firstName} {manager.lastName}</p>
                            <p className="managers__email">{manager.email}</p>
                          </div>
                          <PopoutMenu className="managers__popout" />
                        </div>
                        <div className="managers__second-row">
                        </div>
                      </MobileViewWrapper>
                    );
                  })
                ) : (
                    <Table
                      size={'medium'}
                      pagination={false}
                      dataSource={currentOrganization.managers}
                      columns={columns}
                      className="custom-table"
                      rowKey="manager"
                    />
                  )
              }
            </div>
          </Col>
          <Col className="organization__right-col" span={isMobile ? 24 : 8} style={isMobile && !areOptionsShown ? { display: "none" } : {}}>
            <OrganizationOptionsHeader>
              Business Options
              </OrganizationOptionsHeader>
            <Divider />
            <div>
              <p>If you would like to remove this business:</p>
              <div style={{ marginTop: '10px' }}>
                <ActionButton onClick={() => { dispatch(deleteOrganization({ id: currentOrganization.id })); }}>Remove Business</ActionButton>
              </div>
            </div>
          </Col>
        </Row>
      </OrganizationStyleWrapper>
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.md,
});

export default withRouter(withSizes(mapSizesToProps)(Dashboard));
