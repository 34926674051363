import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Logic from '../../../logic';
import { Form, Row, Col, Icon, Input, Button } from 'antd';
import { LayoutProvider } from '../../layouts';
import styled from 'styled-components';

const FormItem = Form.Item;

const LoginStyleWrapper = styled.div`
  .login {
    &__welcome {
      font-family: Times New Roman;
      font-size: 30px;
      color: green;
    }
  }
`;

class ResetPassword extends Component {
  constructor (props) {
    super(props);

    this.state = {
      
    };
  }

  handleSubmit (values) {
    values['access_token'] = this.props.query.access_token;
    this.props.resetPassword(values);
  }

  handleFormFailure (errorInfo) {
    console.log('Failed:', errorInfo);
  }

  render () {
    return (
      <LayoutProvider type='external' >
        <LoginStyleWrapper>
        <div style={{ padding: '40px'}}>
          <div className={'login'}>
            <div style={{paddingBottom: '20px'}}>
              <h3 className={'login__welcome'}>Ready To Reset Your Password?</h3>
              <div>Take a moment to set a new password.</div>
            </div>
            <Form onFinish={(values) => { this.handleSubmit(values); }} onFailure={(error) => { this.handleFormFailure(error); }} className="login-form">
              <FormItem 
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)', width: '100%' }} />} type='password' placeholder="Password" />
              </FormItem>
              <FormItem 
                name="passwordConfirm"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)', width: '100%' }} />} type='password' placeholder="Password" />
              </FormItem>
              <Row>
                <Col span={24} style={{textAlign: 'right'}}>
                  <Button size='large' htmlType="submit" className="login-form-button" style={{borderRadius: '0px', cursor: 'pointer',  zIndex: '100', backgroundColor: '#5BBA6F', color: 'white'}}>
                    Reset Password
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        </LoginStyleWrapper>
      </LayoutProvider>
    );
  }
}

const { navigateToRoute } = Logic.general.router.actions;
const { resetPassword } = Logic.general.session.actions;

function mapStateToProps(state) {
  const { query } = state.containers['general::router'];
  return {
    query
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    navigateToRoute,
    resetPassword
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
