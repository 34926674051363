import { default as Dashboard } from './dashboard';
import { default as ContentEdit } from './content-edit';
import { default as Collections } from './collections';
import { default as Collection } from './collection';
import { default as Organization } from './organization';
import { default as OrganizationEdit } from './organizationEdit';
import { default as PastContentView } from './past-content-view';

export default {
  Dashboard,
  ContentEdit,
  Collection,
  Collections,
  Organization,
  OrganizationEdit,
  PastContentView
};
