import axios from 'axios';

const BACKEND_URL = process.env.WFIA_API_URL;

export default class Api {
  static createContentCompletionRecord (data) {
    let { access_token } = data;

    return axios({
      method: 'POST',
      url: `${BACKEND_URL}/contentCompletions`,
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        access_token
      },
      data,
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }
}
