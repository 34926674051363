import * as actions from './actions';

const initState = {
  organizations: []
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.payload
      };
    case actions.FETCH_ORGANIZATION_SUCCESS:
    case actions.UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizations: [action.payload]
      };

    case actions.DELETE_ORGANIZATION:
      return {
        ...state,
        organizations: state.organizations.filter(x => x.id !== action.payload)
      };      

    default:
      return state;
  }
};
