import * as routerActions from '../../general/router/actions';
import { put, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import { notification } from 'antd';
// import { sessionService } from 'redux-react-session';

function * updateContentCompletionRoute () {
  try {
    yield notification['success']({
      message: `Successfully removed business.`,
    });
    yield put({
      type: routerActions.NAVIGATE_TO_ROUTE,
      payload: { to: `/ma/dashboard`, replace: true }
    });
  } catch (err) {
    console.log(err);
  }
}

function * updateOrganizationRoute () {
  try {
    yield notification['success']({
      message: `Successfully Updated Organization`,
    });

    // let user = yield sessionService.loadUser();

    // yield put({
    //   type: routerActions.NAVIGATE_TO_ROUTE,
    //   payload: { to: `/${user.role}/dashboard`, replace: true }
    // });
  } catch (err) {
    console.log(err);
  }
}

function * sagas () {
  yield takeEvery(actions.DELETE_ORGANIZATION_SUCCESS, updateContentCompletionRoute);
  yield takeEvery(actions.UPDATE_ORGANIZATION_SUCCESS, updateOrganizationRoute);

}

export default sagas;


