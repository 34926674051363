import { default as NotFound } from './404Unauthenticated';
import { default as ForgotPassword } from './forgot';
import { default as Home } from './home';
import { default as IsLoadingUnauthenticated } from './isLoadingUnauthenticated';
import { default as Register } from './register';
import { default as ResetPassword } from './resetPassword';
import { default as AccountCreate } from './accountCreate';

export default {
	NotFound,
	ForgotPassword,
	Home,
	IsLoadingUnauthenticated,
	Register,
	ResetPassword,
	AccountCreate
};
