import styled from 'styled-components';
import { stringBreakpoints } from '../../app/constants/breakpoints';

export const CustomGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px 30px;

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    display: block;
    & > * {
      margin-bottom: 25px;
    }
  }
`;