import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from './actions';
import api from './api';
import * as sessionActions from '../session/actions';
import { message } from 'antd';

function * makeApiRequestRoute (action) {
  try {
    const { payload, apiCall } = action;
    yield put(actions.setFetchingStatus({ isFetching: true }));
    if (action.begin) {
      for (var beginAction of action.begin.actions) {
        yield put({
          type: beginAction,
          payload: response
        });  
      }
    }
    const response = yield call(api.makeApiRequest, { payload, apiCall });
    if (response) {
      for (var successAction of action.success.actions) {
        yield put({
          type: successAction,
          payload: response
        });
      }
      yield put({
        type: 'APP/MAKE_API_REQUEST_SUCCESS',
        payload: {}
      });
      if (action.success.message) { message.success(action.success.message); }
    } else {
      for (let errorAction of action.error.actions) {
        yield put({
          type: errorAction,
          payload: response
        });  
      }
      if (action.error.message) { message.error(action.error.message); }
    }
  } catch (err) {
    for (let errorAction of action.error.actions) {
      yield put({
        type: errorAction,
        payload: err
      });  
    }
  } finally {
    yield put(actions.setFetchingStatus({ isFetching: false }));
  }
}

function * handleErrorsRoute (action) {
  try {
    const { payload } = action;
    switch (payload.message) {
      case 'jwt expired':
        yield put({
          type: sessionActions.SESSION_LOGOUT,
          payload: {  }
        });
        break;
      default:
        break;
    }
  } catch (err) {
    throw err;
  }
}

function * sagas () {
  yield takeEvery(actions.MAKE_API_REQUEST, makeApiRequestRoute);
  yield takeEvery(action => /ERROR/.test(action.type), handleErrorsRoute);
}

export default sagas;