import axios from 'axios';
import React from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import Logic from '../../logic';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import { message } from 'antd';
import parse from 'react-html-parser';

const { setFetchingStatus } = Logic.general.app.actions;

// should be used to display editor content
export const EditorResponsiveWrapper = styled(({ children, className }) => <div className={className}>{parse(children || '')}</div>)`
  & .ql-video,
  & img {
    width: 100%;
  }
`;

// HELPERS
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const saveImageHandler = async ({ fileName, data }) => axios.post(
  `${process.env.WFIA_API_URL}/contents/editor/file?access_token=${localStorage.getItem('redux-react-session/USER-SESSION').replace(/"/g, '')}`,
  { fileName, data }
).then(res => res.data);

const EditorStyles = styled(ReactQuill)`
  .ql-editor {
    min-height: 250px;
  }
`;

class PureEditor extends React.Component {
  imageHandler = (that) => function () {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      try {
        const file = input.files[0];
        const base64File = await toBase64(file);

        // Save current cursor state
        const range = this.quill.getSelection(true);
        this.quill.setSelection(range.index + 1);

        that.props.setFetchingStatus({ isFetching: true });
        const res = await saveImageHandler({ data: base64File, fileName: file.name });

        this.quill.insertEmbed(range.index, 'image', res);
      } catch (err) {
        if (process.env.NODE_ENV !== 'production') {
          console.log("Editor's file upload error", err);
        }
        message.error('Some error happened!');
      } finally {
        that.props.setFetchingStatus({ isFetching: false });
      }
    };
  }

  render() {
    return (
      <EditorStyles
        ref={el => {
          this.quill = el;
        }}
        onChange={this.props.onChange}
        defaultValue={this.props.defaultValue}
        placeholder={this.props.placeholder}
        modules={{
          toolbar: {
            container: [
              [{ header: 1 }, { header: 2 }, { header: [3, 4] }, { font: [] }],
              [{ size: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link', 'image', 'video'],
              ['clean'],
              ['code-block']
            ],
            handlers: {
              // original editors content should be saved - thats why
              // we are using wrapper function to transfer component's props using closure 
              image: this.imageHandler(this)
            }
          }
        }}
      />
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  setFetchingStatus
};

export const Editor = connect(mapStateToProps, mapDispatchToProps)(PureEditor);