import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import RouteWrapperData from './base-routes-wrapper-data';
import * as pages from '../app/pages';
import Logic from '../logic';

const { sessionLogout } = Logic.general.session.actions;
const { fetchCollections, fetchSingleCollection, resetCollectionsReducer } = Logic.general.collections.actions;
const { fetchOrganizations, fetchOrganization } = Logic.general.organizations.actions;
const { resetContentsReducer, fetchContent } = Logic.ma.contents.actions;

export default class MARoutes extends Component {
  constructor (props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount () {
    const { session } = this.props;
    const isAuthenticatedUser = session.checked && session.authenticated && (session.user && true);
    if (isAuthenticatedUser) {
      this.props.fetchInitialData();
    }
  }

  render () {
    const { session } = this.props;
    return (
      <Switch>
        <Route
          exact
          path='/dashboard'
          render={() => {
            return <Redirect to={'/ma/dashboard'} key={'redirect-ma-'}/>;
          }}
        />
        <RouteWrapperData
          exact
          path='/profile'
          component={pages.General.Profile}
          routeId='MA-PROFILE'
          key={'ma-profile-'}
          session={session}
          dataFetch
          dataActions={
            [
            ]
          }
        />
        <Route
          exact
          path='/'
          render={() => {
            return <Redirect to={'/ma/dashboard'} key={'redirect-ma-'}/>;
          }}
        />
        <RouteWrapperData
          exact
          path='/ma/dashboard'
          component={pages.MA.Dashboard}
          routeId='ma-DASHBOARD'
          key={'ma-dashboard-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchOrganizations, data: {} }
            ]
          }
        />
        
        <RouteWrapperData
          exact
          path='/ma/organizations/:orgId'
          component={pages.MA.Organization}
          routeId='ma-ORGANIZATION'
          key={'ma-organization-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchOrganization, data: {} }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/ma/organizations/:orgId/edit'
          component={pages.MA.OrganizationEdit}
          routeId='ma-ORGANIZATION-EDIT'
          key={'ma-organization-edit'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchOrganization, data: {} }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/ma/collections/:collection/content-edit'
          component={pages.MA.ContentEdit}
          routeId='ma-CONTENT'
          key={'ma-content-' + Math.random()}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchSingleCollection, data: {} },
              { action: fetchCollections, data: {} }
            ]
          }
          unmountActions={[
            resetCollectionsReducer,
            resetContentsReducer
          ]}
        />
        <RouteWrapperData
          exact
          path='/ma/collections/:collection'
          component={pages.MA.Collection}
          routeId='ma-SINGE-COLLECTION'
          key={'ma-single-collection-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchSingleCollection, data: {} }
            ]
          }
          unmountActions={[
            resetCollectionsReducer
          ]}
        />
        <RouteWrapperData
          exact
          path='/ma/collections'
          component={pages.MA.Collections}
          routeId='ma-COLLECTIONS'
          key={'ma-collections-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchCollections, data: {} }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/ma/content/past-view'
          component={pages.MA.PastContentView}
          routeId='ma-CONTENT-VIEW'
          key={'ma-content-view'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchContent, data: { } }
            ]
          }
          unmountActions={[
            resetCollectionsReducer
          ]}
        />
        <RouteWrapperData
          exact
          path='/logout'
          component={pages.General.Logout}
          routeId='LOG_OUT'
          session={session}
          key={'logout-auth'}
          dataFetch
          dataActions={
            [
              { action: sessionLogout, data: { } }
            ]
          }
        />
        <Route
          exact
          path='/login'
          render={() => {
            return <Redirect to={'/ma/dashboard'} key={'redirect-ma-'}/>;
          }}
        />
        <Route
          exact
          path='/register'
          render={() => {
            return <Redirect to={'/ma/dashboard'} key={'redirect-ma-'}/>;
          }}
        />
        <Route render={() => { return (<pages.General.NotFound />); }}/>
      </Switch>
    );
  }
}
