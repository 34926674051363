import { default as Dashboard } from './dashboard';
import { default as Employee } from './employee';
import { default as CompanyProgress } from './company-progress';
import { default as CompanyProfile } from './company-profile';

export default {
  Dashboard,
  Employee,
  CompanyProgress,
  CompanyProfile
};
