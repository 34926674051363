const ROOT = 'APP';

export const TOGGLE_ALL = ROOT + '/TOGGLE_ALL';
export const TOGGLE_COLLAPSED = ROOT + '/TOGGLE_COLLAPSED';

export const TOGGLE_BLOCK_UI = ROOT + '/TOGGLE_BLOCK_UI';
export const TOGGLE_FILTER_DRAWER = ROOT + '/TOGGLE_FILTER_DRAWER';

export const MAKE_API_REQUEST = ROOT + '/MAKE_API_REQUEST';
export const MAKE_API_REQUEST_SUCCESS = ROOT + '/MAKE_API_REQUEST_SUCCESS';
export const MAKE_API_REQUEST_ERROR = ROOT + '/MAKE_API_REQUEST_ERROR';

export const EXTERNAL_COMMENT = ROOT + '/EXTERNAL_COMMENT';
export const EXTERNAL_COMMENT_SUCCESS = ROOT + '/EXTERNAL_COMMENT_SUCCESS';
export const EXTERNAL_COMMENT_ERROR = ROOT + '/EXTERNAL_COMMENT_ERROR';


export function externalComment (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/users/feedback`,
      method: 'POST',
      params: {},
      query: {},
      body: payload,
      auth: null
    },
    success: {
      actions: [EXTERNAL_COMMENT_SUCCESS]
    },
    error: {
      actions: [EXTERNAL_COMMENT_ERROR]
    }

  };
}

export function getView (width) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}

export function toggleAll (width, height) {
  const view = getView(width);
  const collapsed = view !== 'DesktopView';
  return {
    type: TOGGLE_ALL,
    collapsed,
    view,
    height
  };
}

export const toggleCollapsed = (payload) => ({
  type: TOGGLE_COLLAPSED,
  payload
});

export const toggleFilterDrawer = () => ({
  type: TOGGLE_FILTER_DRAWER
});

export const toggleBlockUi = (payload) => ({
  type: TOGGLE_BLOCK_UI,
  payload
});

// GET AVAILABLE STATES
export const FETCH_AVAILABLE_STATES = `${ROOT}/FETCH_AVAILABLE_STATES`;
export const FETCH_AVAILABLE_STATES_SUCCESS = `${ROOT}/FETCH_AVAILABLE_STATES_SUCCESS`;
export const FETCH_AVAILABLE_STATES_FAILURE = `${ROOT}/FETCH_AVAILABLE_STATES_FAILURE`;

export function getAvailableStates () {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/states`,
      method: 'GET',
      params: {},
      query: {},
      body: {},
      auth: null
    },
    success: {
      actions: [FETCH_AVAILABLE_STATES_SUCCESS]
    },
    error: {
      actions: [FETCH_AVAILABLE_STATES_FAILURE]
    }
  };
}

export const SET_FETCHING_STATUS = `${ROOT}/SET_FETCHING_STATUS`;

export const setFetchingStatus = (payload) => ({
  type: SET_FETCHING_STATUS,
  payload
});
