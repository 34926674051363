import React, { useState } from 'react';
import { LayoutProvider } from '../../layouts';
import { Row, Col, Button, Divider, Form, Input, Icon, Popover, Table, Avatar } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withSizes from 'react-sizes';
import Logic from '../../../logic';
import styled from 'styled-components';

import { USER_STATUSES } from '../../constants/user';

import { MailOutlined, CloseOutlined, DashboardOutlined } from '@ant-design/icons';
import { PopoutMenu } from '../../components/popoutMenu';
import { numberBreakpoints } from '../../constants/breakpoints';

const FormItem = Form.Item;

const ActionButton = styled(Button)`
  background-color: #5BBA6F;
  color: white;
  :hover {
    background-color: #5BBA6F;
    color: white;
  }
  border-radius: 0;
  cursor: pointer;
  z-index: 100;
`;

const OrganizationOptionsHeader = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #5BBA6F;
`;

const OrganizationStyleWrapper = styled.div`
  .ant-divider {
    margin: 5px 0px;
  }
  .invite-form {
    margin-top: 20px;
  }
`;

const MonthWrapper = styled.div`
  display: flex;
`;
const MonthBox = styled.div`
  border: 1px solid #5bbb6f;
  margin: 0 2px;
  padding: 3px 7px;
  cursor: pointer;
`;

const RightColumn = styled.div`
  min-width: 22%;
`;
RightColumn.Header = styled.h3`
  color: #549770;
  font-size: 22px;
  border-bottom: 3px solid #549770;
  margin-bottom: 25px;
`;
RightColumn.DescriptionText = styled.p`
  color: #b8b8b8;
  margin-bottom: 5px;
`;
RightColumn.ActionButton = styled.button`
  padding: 5px 23px;
  color: white;
  background: #5bba6f;
  border: none;
  box-shadow: none;
  margin-bottom: 20px;
  cursor: pointer;
`;

const MobileViewWrapper = styled.div`
  padding: 10px 20px;
  width: 100%;
  background: ${p => p.isOdd ? '#f2f2f2' : 'none'};

  .progress {
    &__first-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__second-row {
      padding-top: 20px;
    }

    &__info-section {
      width: 70%;
    }

    &__full-name {
      font-weight: bold;
    }

    &__popout {
      width: 10%;
    }
  }
`;

const StyledActionButton = styled.div`
  padding: 12.5px;
  background: #d5733f;
  cursor: pointer;
`;

// const monthAbbreviations = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

const getMonthStyle = (done, of) => {
  if (!done) {
    return { background: 'none' };
  }

  if (done === of) {
    return { background: '#5bbb6f' };
  }

  return { background: '#f5f063' };
};

const renderProgressLine = (employee) => {
  let januaryLocation = employee.stats.findIndex(x => x.month === 'January');
  let relevantStats = [...employee.stats].splice(januaryLocation, employee.stats.length);
  return (
    <MonthWrapper>
      {employee.status === USER_STATUSES.INVITED
        ? <p style={{ color: 'red' }}>User has pending invitation.</p>
        : relevantStats.map((stat) => {
          const content = stat.wasActive ? (
            <div>
              <p>Collection: {stat.collection}</p>
              <p>Finished {stat.done} of {stat.of}</p>
            </div>
          ) : <p>This user was not active. No training was required at this time.</p>;

          const style = getMonthStyle(stat.done, stat.of);

          return (
            <Popover
              content={content}
              title="Info"
              key={stat.month}
            >
              <MonthBox style={style}>
                {stat.month[0]}
              </MonthBox>
            </Popover>
          );
        })}
    </MonthWrapper>
  );
};

const columns = [
  {
    render: (employee) => {
      return <Avatar src={employee.picture ? employee.picture.link : 'https://invigor-dev.s3-us-west-2.amazonaws.com/user1.png'} />;
    },
    key: 'avatar',
  },
  {
    title: 'Employee Name',
    render: (employee) => {
      return <p>{employee.firstName} {employee.lastName}</p>;
    },
    key: 'name'
  },
  {
    title: 'Email address',
    render: (employee) => {
      return <p>{employee.email}</p>;
    },
    key: 'email',
  },
  {
    title: 'Review status',
    render: renderProgressLine,
    key: 'employees',
  },
  {
    render: () => {
      return <PopoutMenu />;
    },
    key: 'actions',
  }
];

export const Dashboard = ({ isMobile }) => {
  const employeesDashboardData = useSelector(state => state.containers['ba::employees'].employeesDashboardData);
  const user = useSelector(state => state.session.user);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isInviteSectionShown, setIsInviteSectionShown] = useState();

  const actionIconStyle = { fontSize: 30, color: 'white' };




  if (isMobile) {
    return (
      <LayoutProvider
        type='internal'
        backgroundImageStyle={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-bins.png')" }}
        pageTitle={'Business Administrator Dashboard'}
        noGutter
        actionButton={
          isMobile && (
            <div className='mobile-action-items'>
              <StyledActionButton>
                <DashboardOutlined
                  style={actionIconStyle}
                  onClick={() => { dispatch(Logic.general.router.actions.navigateToRoute({ to: `/`, replace: false })); }} />
              </StyledActionButton>
              <StyledActionButton onClick={() => setIsInviteSectionShown(!isInviteSectionShown)}>
                {!isInviteSectionShown ? <MailOutlined style={actionIconStyle} /> : <CloseOutlined style={actionIconStyle} />}
              </StyledActionButton>
            </div>
          )
        }
      >
        <OrganizationStyleWrapper>
          <div style={{ paddingTop: '60px' }}>
            {isInviteSectionShown ? (
              <div style={{ padding: '0 20px' }}>
                <OrganizationOptionsHeader>
                  Invite Employee
              </OrganizationOptionsHeader>
                <Divider />
                <div>
                  <p>If you would like to invite an employee to participate in training, please enter their name and email address. They will be sent an email inviting them to sign up. Fill out the form below to invite a new employee to your company. They will be sent an invitation email to sign up and complete training content.</p>
                </div>

                <div>
                  <Form
                    form={form}
                    onFinish={(values) => {
                      dispatch(Logic.general.users.actions.inviteUser({ ...values, organization: user.organization }));
                      form.resetFields();
                    }}
                    onFailure={(error) => { this.handleFormFailure(error); }}
                    className="invite-form"
                  >
                    <FormItem
                      name="fullName"
                      rules={[{ required: true, message: 'Please input full name!' }]}
                    >
                      <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Full Name" />
                    </FormItem>
                    <FormItem
                      name="email"
                      rules={[{ required: true, message: 'Please input email!' }]}
                    >
                      <Input size='large' prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                    </FormItem>
                    <ActionButton size='large' htmlType="submit" className="login-form-button" style={{}}>
                      Send Invitation
                  </ActionButton>
                  </Form>
                </div>
                <br /><br />
                <OrganizationOptionsHeader style={{ marginTop: '5vh' }}>
                  Year-To-Date Business Report
                </OrganizationOptionsHeader>
                <Divider />
                <RightColumn.DescriptionText>Select the button below to print a report for this business:</RightColumn.DescriptionText>
                <RightColumn.ActionButton onClick={() => {
                  dispatch(Logic.ba.employees.actions.generateOrganizationReport({ organization: user.organization }));
                }}>
                  Print Report
                </RightColumn.ActionButton>


              </div>
            ) : (
              <Row gutter={16}>
                {employeesDashboardData.length ? employeesDashboardData.map((employee, i) => {
                  return (
                    <MobileViewWrapper
                      key={employee.id}
                      className="progress"
                      isOdd={(i + 1) % 2}
                      onClick={() => dispatch(Logic.general.router.actions.navigateToRoute({ to: `/ba/employees/${employee.id}`, replace: false }))}
                    >
                      <div className="progress__first-row">
                        <Avatar
                          className="progress__avatar"
                          src={employee.picture ? employee.picture.link : 'https://invigor-dev.s3-us-west-2.amazonaws.com/user1.png'}
                        />
                        <div className="progress__info-section">
                          <p className="progress__full-name">{employee.firstName} {employee.lastName}</p>
                          <p className="progress__email">{employee.email}</p>
                        </div>
                        <PopoutMenu className="progress__popout" />
                      </div>
                      <div className="progress__second-row">
                        {renderProgressLine(employee)}
                      </div>
                    </MobileViewWrapper>
                  );
                }) : <h3 style={{ textAlign: 'center', width: '100%' }}>No data.</h3>}
              </Row>
            )}
          </div>
        </OrganizationStyleWrapper>
      </LayoutProvider>
    );
  }

  return (
    <LayoutProvider
      type='internal'
      backgroundImageStyle={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-bins.png')" }}
      pageTitle={'Business Administrator Dashboard'}
    >
      <OrganizationStyleWrapper>
        <div style={{ paddingTop: '60px' }}>
          <Row gutter={32}>
            <Col span={18}>
              <Table
                dataSource={employeesDashboardData}
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                columns={columns}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      dispatch(Logic.general.router.actions.navigateToRoute({ to: `/ba/employees/${record.id}`, replace: false }));
                    }
                  };
                }}
                className="custom-table"
              />
            </Col>
            <Col span={6}>
              <OrganizationOptionsHeader>
                Invite Employee
              </OrganizationOptionsHeader>
              <Divider />
              <div>
                <p>Fill out the form below to invite a new employee to your company. They will be sent an invitation email to sign up and complete training content.</p>
              </div>

              <div>
                <Form
                  form={form}
                  onFinish={(values) => {
                    dispatch(Logic.general.users.actions.inviteUser({ ...values, organization: user.organization }));
                    form.resetFields();
                  }}
                  onFailure={(error) => { this.handleFormFailure(error); }}
                  className="invite-form"
                  layout="vertical"
                >
                  <FormItem
                    name="fullName"
                    label="Full Name"
                    rules={[{ required: true, message: 'Please input the employees full name!' }]}
                  >
                    <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Full Name" />
                  </FormItem>
                  <FormItem
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: 'Please input the employees email!' }]}
                  >
                    <Input size='large' prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                  </FormItem>
                  <ActionButton size='large' htmlType="submit" className="login-form-button" style={{}}>
                    Send Invitation
                  </ActionButton>
                </Form>
              </div>
              <br /><br />
              <OrganizationOptionsHeader style={{ marginTop: '5vh' }}>
                Year-To-Date Company Report
              </OrganizationOptionsHeader>
              <Divider />
              <RightColumn.DescriptionText>If you would like to print a report this organization:</RightColumn.DescriptionText>
              <RightColumn.ActionButton onClick={() => {

                dispatch(Logic.ba.employees.actions.generateOrganizationReport({ organization: user.organization }));

              }}>
                Print Report
              </RightColumn.ActionButton>
            </Col>
          </Row>

        </div>
      </OrganizationStyleWrapper>
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.md,
});

export default withRouter(withSizes(mapSizesToProps)(Dashboard));
