const ROOT = 'SESSION';

export const SESSION_LOGOUT = 'SESSION_LOGOUT';

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGIN_SUCCESS = 'SESSION_LOGIN_SUCCESS';
export const SESSION_LOGIN_ERROR = 'SESSION_LOGIN_ERROR';

export const SESSION_REGISTER = 'SESSION_REGISTER';
export const SESSION_REGISTER_SUCCESS = 'SESSION_REGISTER_SUCCESS';
export const SESSION_REGISTER_ERROR = 'SESSION_REGISTER_ERROR';

export const SESSION_REFRESH = 'SESSION_REFRESH';
export const SESSION_REFRESH_SUCCESS = 'SESSION_REFRESH_SUCCESS';
export const SESSION_REFRESH_ERROR = 'SESSION_REFRESH_ERROR';

export const GET_ACTIVATE_STATUS = `GET_ACTIVATE_STATUS`;
export const GET_ACTIVATE_STATUS_SUCCESS = `GET_ACTIVATE_STATUS_SUCCESS`;
export const GET_ACTIVATE_STATUS_ERROR = `GET_ACTIVATE_STATUS_ERROR`;

export const VALIDATE_VENDOR = `VALIDATE_VENDOR`;
export const VALIDATE_VENDOR_SUCCESS = `VALIDATE_VENDOR_SUCCESS`;
export const VALIDATE_VENDOR_ERROR = `VALIDATE_VENDOR_ERROR`;

export const COMPLETE_USER_SETUP = 'COMPLETE_USER_SETUP';
export const COMPLETE_USER_SETUP_SUCCESS = 'COMPLETE_USER_SETUP_SUCCESS';
export const COMPLETE_USER_SETUP_ERROR = 'COMPLETE_USER_SETUP_ERROR';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const GENERATE_AUTH_URL = 'GENERATE_AUTH_URL';
export const GENERATE_AUTH_URL_SUCCESS = 'GENERATE_AUTH_URL_SUCCESS';
export const GENERATE_AUTH_URL_ERROR = 'GENERATE_AUTH_URL_ERROR';

export const LOGIN_GOOGLE_USER = 'LOGIN_GOOGLE_USER';
export const LOGIN_GOOGLE_USER_SUCCESS = 'LOGIN_GOOGLE_USER_SUCCESS';
export const LOGIN_GOOGLE_USER_ERROR = 'LOGIN_GOOGLE_USER_ERROR';

export const GET_GOOGLE_ACCESS_TOKEN = 'GET_GOOGLE_ACCESS_TOKEN';
export const GET_GOOGLE_ACCESS_TOKEN_SUCCESS = 'GET_GOOGLE_ACCESS_TOKEN_SUCCESS';
export const GET_GOOGLE_ACCESS_TOKEN_ERROR = 'GET_GOOGLE_ACCESS_TOKEN_ERROR';

export const SESSION_UPDATE_PASSWORD = 'SESSION_UPDATE_PASSWORD';
export const SESSION_UPDATE_PASSWORD_SUCCESS = 'SESSION_UPDATE_PASSWORD_SUCCESS';
export const SESSION_UPDATE_PASSWORD_ERROR = 'SESSION_UPDATE_PASSWORD_ERROR';

export function sessionRefresh (payload) {
  return {
    type: SESSION_REFRESH,
    payload: payload,
    authCall: true
  };
}

export function sessionLogout () {
  return {
    type: SESSION_LOGOUT
  };
}

export function confirmUserEmail (payload) {
  return {
    type: 'CONFIRM_USER_EMAIL',
    payload: payload
  };
}

export function getActivateStatus (payload) {
  return {
    type: GET_ACTIVATE_STATUS,
    payload: payload
  };
}

export function validateVendor (payload) {
  return {
    type: VALIDATE_VENDOR,
    payload: payload
  };
}

export function completeUserSetup (payload) {
  return {
    type: COMPLETE_USER_SETUP,
    payload,
    authCall: true
  };
}

export function generateAuthUrl (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: false,
      master: true,
      route: `/auth/${payload.service}/generateAuthUrl`,
      method: 'GET',
      params: {},
      query: {}
    },
    success: {
      actions: [GENERATE_AUTH_URL_SUCCESS]
    },
    error: {
      actions: [GENERATE_AUTH_URL_ERROR]
    }

  };
}

export function loginGoogleUser (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: false,
      master: false,
      route: `/auth/google/callback`,
      method: 'GET',
      params: {},
      query: payload.query,
      body: {}
    },
    success: {
      actions: [LOGIN_GOOGLE_USER_SUCCESS]
    },
    error: {
      actions: [GET_GOOGLE_ACCESS_TOKEN_ERROR]
    }

  };
}

export function sessionRegister (values) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: false,
      master: true,
      route: `/users`,
      method: 'POST',
      params: {},
      body: values
    },
    success: {
      actions: [SESSION_REGISTER_SUCCESS]
    },
    error: {
      actions: [SESSION_REGISTER_ERROR]
    }

  };
}

export function sessionLogin (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: false,
      master: true,
      route: `/auth`,
      method: 'POST',
      params: {},
      query: payload.query,
      body: payload,
      auth: {
        username: payload.username,
        password: payload.password
      }
    },
    success: {
      actions: [SESSION_LOGIN_SUCCESS]
    },
    error: {
      actions: [SESSION_LOGIN_ERROR]
    }

  };
}

export function updateUserPassword (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: false,
      master: true,
      route: `/users/${payload.id}/password`,
      method: 'PUT',
      params: {},
      query: {},
      body: {
        password: payload.newPassword
      },
      auth: {
        username: payload.username,
        password: payload.password
      }
    },
    success: {
      actions: [SESSION_UPDATE_PASSWORD_SUCCESS]
    },
    error: {
      actions: [SESSION_UPDATE_PASSWORD_ERROR]
    }

  };
}

export function forgotPassword (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: false,
      master: true,
      route: `/auth/forgot`,
      method: 'POST',
      params: {},
      query: payload.query,
      body: payload,
    },
    success: {
      actions: [FORGOT_PASSWORD_SUCCESS]
    },
    error: {
      actions: [FORGOT_PASSWORD_ERROR]
    }

  };
}

export function resetPassword (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: false,
      master: false,
      route: `/auth/reset`,
      method: 'POST',
      params: {},
      query: {
        access_token: payload.access_token
      },
      body: payload,
    },
    success: {
      actions: [RESET_PASSWORD_SUCCESS]
    },
    error: {
      actions: [RESET_PASSWORD_ERROR]
    }

  };
}

export const CHECK_INVITE_TOKEN = `${ROOT}/CHECK_INVITE_TOKEN`;
export const CHECK_INVITE_TOKEN_SUCCESS = `${ROOT}/CHECK_INVITE_TOKEN_SUCCESS`;
export const CHECK_INVITE_TOKEN_FAILURE = `${ROOT}/CHECK_INVITE_TOKEN_FAILURE`;

export function checkInviteToken (inviteToken) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: false,
      master: false,
      route: `/auth/inviteTokenVerify/${inviteToken}`,
      method: 'GET',
      params: {},
      query: {},
      body: {},
    },
    success: {
      actions: [CHECK_INVITE_TOKEN_SUCCESS]
    },
    error: {
      actions: [CHECK_INVITE_TOKEN_FAILURE]
    }

  };
}

export const SET_AFTER_LOGIN_REDIRECT = `${ROOT}/SET_AFTER_LOGIN_REDIRECT`;

export const setAfterLoginRedirect = (payload) => ({
  type: SET_AFTER_LOGIN_REDIRECT,
  payload
});
