import * as actions from './actions';
import qs from 'query-string';

const noReloadNecessaryPages = ['/', '/dashboard', '/compliance', '/compliance/issues', '/settings', '/policy/policies', '/action'];

export default function (state = {
  items: [],
  item: null,
  params: {},
  parameters: {},
  isLoading: false,
  isLoaded: false,
  isConfigured: false,
  routerHistory: []
}, action) {
  let stateCopy = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case actions.FETCH_ONE_SUCCESS:
      stateCopy.item = action.payload;
      return stateCopy;
    case actions.FETCH_MANY_SUCCESS:
      stateCopy.items = action.payload;
      return stateCopy;
    case actions.SET_PARAMETERS:
      stateCopy.parameters = action.payload;
      return stateCopy;
    case actions.CONFIGURE_ROUTE:
      stateCopy.parameters = action.payload.match;
      stateCopy.query = action.payload.queryValues;
      stateCopy.isLoading = true;
      stateCopy.isLoaded = false;
      return stateCopy;
    case actions.CONFIGURE_ROUTE_SUCCESS:
      stateCopy.isLoading = false;
      stateCopy.isConfigured = true;
      return stateCopy;
    case '@@router/LOCATION_CHANGE': {
      if (!stateCopy.routerHistory) { stateCopy.routerHistory = []; }
      let visitedRoute = stateCopy.routerHistory.find(x => x.pathname === action.payload.location.pathname);
      stateCopy.routerHistory.push(action.payload.location);
      if (action.payload.location.search.length > 0) {
        const queryValues = qs.parse(location.search);
        stateCopy.query = queryValues;
      }
      if (visitedRoute && noReloadNecessaryPages.indexOf(action.payload.location.pathname) > -1) {
        stateCopy.isLoading = false;
        stateCopy.isLoaded = true;
        stateCopy.isConfigured = true;
      } else {
        stateCopy.isLoading = false;
        stateCopy.isLoaded = false;
        stateCopy.isConfigured = false;
      }
      return stateCopy;
    }
    default:
      return state;
  }
}
