import { put, takeEvery, take, all } from 'redux-saga/effects';
import * as actions from './actions';
import * as sessionActions from '../session/actions';
import { push, goBack } from 'connected-react-router';
var jwt = require('jsonwebtoken');

function* navigateToRoute(action) {
  try {
    const {
      payload: {
        to
      },
    } = action;
    yield put(push(to));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

function* navigateBackRoute() {
  try {
    yield put(goBack());
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

/*function* navigateToRoute (action) {
  for (let result of processNavigateToRoute(action)) {
    console.log('result processNavigateToRoute:', result);
  }
  yield 'ok';
}*/

function* fetchRouteData (action) {
  try {
    const { dataActions, session } = action.payload;

    if (session.authenticated && session.user.id) {
      const {user} = session;
      jwt.verify(user.token, process.env.DASH_REST_JWT_SECRET, function*(err) {
        if (err && err.name === 'TokenExpiredError') {
          yield put({
            type: sessionActions.SESSION_LOGOUT,
            payload: null,
          });
          return;          
        }
      });  
    }
    const yields = dataActions.map((actionItem) => put({
        type: actionItem.type,
        payload: actionItem.payload,
        apiCall: actionItem.apiCall,
        success: actionItem.success,
        error: actionItem.error,
    }));

    yield all(yields);

    const successYields = dataActions.map((actionItem) => take(`${actionItem.type}_SUCCESS`));

    yield all(successYields);

    yield put({
      type: actions.CONFIGURE_ROUTE_SUCCESS,
      payload: null,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

function* sagas () {
  yield takeEvery(actions.NAVIGATE_TO_ROUTE, navigateToRoute);
  yield takeEvery(actions.NAVIGATE_BACK, navigateBackRoute);
  yield takeEvery(actions.FETCH_ROUTE_DATA, fetchRouteData);
}

export default sagas;
