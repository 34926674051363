import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// STRIPE
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import RoutesComponent from './base/base-routes';
// import GlobalSpinner from './app/components/globalSpinner';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './base/base-store';

import './app/styles/app.scss';

// const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
const stripePromise = loadStripe('pk_test_51H87lyEjVmzDy8qQeIQm7WcUvvVa3CuS6obtmkbebdvvMrwf6xZppSuHSPYLgedRSdkjSHckmgNaRcF6kViN0bxl00YYTNgaM9');

store().then(store => {
  ReactDOM.render(
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <DndProvider backend={HTML5Backend}>
          <ConnectedRouter history={history}>
            <RoutesComponent />
          </ConnectedRouter>
        </DndProvider>
      </Elements>
    </Provider>,
    document.getElementById('root')
  );
});
