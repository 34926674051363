import React, { Component } from 'react';
import styled from 'styled-components';
import { LayoutProvider } from '../../layouts';

const CenteredBlock = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default class NotFound extends Component {
  constructor (props) {
    super(props);

    this.state = {};
  }

  render () {
    return (
      <LayoutProvider type='external' >
        <CenteredBlock>
          <div><span>Ooops....</span></div>
          <div><span>The page you requested does not exist.</span></div>
        </CenteredBlock>
      </LayoutProvider>
    );
  }
}