import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Logic from '../logic';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import UnauthenticatedRoutes from './base-unauthenticated-routes';
import GlobalSpinner from '../app/components/globalSpinner';
import EmployeeRoutes from './employee-routes';
import BARoutes from './ba-routes';
import MARoutes from './ma-routes';

const { toggleAll, fetchApplicationSettings } = Logic.general.app.actions;
const { sessionRefresh } = Logic.general.session.actions;
const { fetchInitialData, fetchInitialDataBegin } = Logic.general.users.actions;

class Routes extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render() {
    let routeSet;
    const { session, initialDataLoaded } = this.props;
    if (session.checked && !session.authenticated) { routeSet = <UnauthenticatedRoutes session={session} initialDataLoaded={initialDataLoaded} />; }

    const isEmployee = session.checked && session.authenticated && session.user && (session.user.role === 'employee');
    const isMA = session.checked && session.authenticated && session.user && (session.user.role === 'ma');
    const isBA = session.checked && session.authenticated && session.user && (session.user.role === 'ba');

    if (isEmployee) { routeSet = <EmployeeRoutes session={session} initialDataLoaded={initialDataLoaded} fetchInitialData={this.props.fetchInitialData} />; }
    if (isMA) { routeSet = <MARoutes session={session} initialDataLoaded={initialDataLoaded} fetchInitialData={this.props.fetchInitialData} />; }
    if (isBA) { routeSet = <BARoutes session={session} initialDataLoaded={initialDataLoaded} fetchInitialData={this.props.fetchInitialData} />; }

    return (
      <div style={{ height: '100%' }}>
        <Debounce time="500" handler="onResize">
          <WindowResizeListener
            onResize={windowSize =>
              this.props.toggleAll(
                windowSize.windowWidth,
                windowSize.windowHeight,
              )
            }
          />
        </Debounce>
        <GlobalSpinner>
          {routeSet}
        </GlobalSpinner>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    session: state.session,
    initialDataLoaded: state.containers['general::users'].initialDataLoaded
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchInitialDataBegin,
    fetchInitialData,
    fetchApplicationSettings,
    toggleAll,
    sessionRefresh
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
