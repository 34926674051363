/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';
import * as actions from './actions';
import * as routerActions from '../../general/router/actions';

function * updateContentCompletionRoute (action) {
  try {
    yield notification['success']({
      message: `Successfully Marked As Complete`,
    });
    yield put({
      type: routerActions.NAVIGATE_TO_ROUTE,
      payload: { to: `/ba/employees/${action.payload.owner}`, replace: true }
    });
  } catch (err) {
    console.log(err);
  }
}

function * sagas () {
  yield takeEvery(actions.UPDATE_CONTENT_COMPLETION_SUCCESS, updateContentCompletionRoute);
}

export default sagas;
