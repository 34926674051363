import React from 'react';
import styled from 'styled-components';
import { Drawer } from 'antd';

const WfiaStyleWrapper = styled.div`
`;

export const WfiaDrawer = (props) => {
  return (
    <WfiaStyleWrapper>
      <Drawer
        drawerStyle={{
          background: '#388659 !important'
        }}
        style={{
          background: '#388659 !important'
        }}
        bodyStyle={{ padding: 0, background: '#388659' }}
        placement={props.placement || 'left'}
        onClose={props.onClose}
        visible={props.visible}
        width={typeof props.drawerWidth === "number" ? props.drawerWidth : 320}
        closable={false}
        className="wfia-drawer"
        destroyOnClose
      >
        {props.children}
      </Drawer>
    </WfiaStyleWrapper>
  );
};
