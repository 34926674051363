import general from './general';
import ma from './master-admin';
import ba from './business-admin';
import employee from './employee';

export default {
  general,
  ma,
  ba,
  employee
};
