import React, { Fragment, useState } from 'react';
import { LayoutProvider } from '../../layouts';
// import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ContentCard } from '../../components/contentCard';
import { CustomGrid } from '../../components/grid';
import Logic from '../../../logic';
import styled from 'styled-components';
import { Progress } from 'antd';
import {
  CheckCircleOutlined,
  CloseOutlined,
  SettingOutlined,
  DashboardOutlined
} from '@ant-design/icons';
import { stringBreakpoints, numberBreakpoints } from '../../../app/constants/breakpoints';
import withSizes from 'react-sizes';

const { updateContentCompletion } = Logic.ba.employees.actions;

const { roleAction, deleteUser, } = Logic.general.users.actions;
// import { USER_STATUSES } from '../../constants/user';

const OrganizationStyleWrapper = styled.div`
  .ant-divider {
    margin: 5px 0px;
  }
  .invite-form {
    margin-top: 20px;
  }
  .ant-progress-inner {
    border-radius: 0px;
  }
  .ant-progress-bg {
   border-radius: 0px; 
   height: 20px !important;
  }
  .completion {
    margin-bottom: 3vh;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    flex-direction: column-reverse;
  }
`;

const LeftColumn = styled.div`
  width: 75%;
  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    width: 100%;
  }
`;
const RightColumn = styled.div`
  min-width: 22%;
  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    width: 100%;
  }
`;
RightColumn.Header = styled.h3`
  color: #549770;
  font-size: 22px;
  border-bottom: 3px solid #549770;
  margin-bottom: 25px;
`;
RightColumn.DescriptionText = styled.p`
  color: #b8b8b8;
  margin-bottom: 5px;
`;
RightColumn.ActionButton = styled.button`
  padding: 5px 23px;
  color: white;
  background: #5bba6f;
  border: none;
  box-shadow: none;
  margin-bottom: 20px;
  cursor: pointer;
`;

const StyledProgressBar = styled(Progress)`
  .ant-progress-bg {
    background: #5bba6f;
  }
`;

const StyledActionButton = styled.div`
  padding: 12.5px;
  background: #d5733f;
  cursor: pointer;
`;

export const Dashboard = ({ isMobile }) => {
  let completedContents = [];
  let inProgressContents = [];
  // hooks should be on the top level only - they should be invoked every render.
  const [areOptionsShown, setAreOptionsShown] = useState(false);
  const completedCollections = useSelector(state => state.containers['employee::collections'].completedCollections);
  const collections = useSelector(state => state.containers['employee::collections'].collections);
  const employeesDashboardData = useSelector(state => state.containers['ba::employees'].employeesDashboardData);
  const employeeId = useSelector(state => state.containers['general::router'].parameters.params.employeeId);
  const dispatch = useDispatch();

  if (!employeesDashboardData.length) return null;

  const currentEmployee = employeesDashboardData.find(x => x.id === employeeId);

  completedCollections.map((contentCompletion) => {
    let inprogress = contentCompletion.relatedCollection.contents.filter(n => contentCompletion.contents.findIndex(x => n === x.id) === -1);
    inProgressContents = [...inProgressContents, ...inprogress.map((content) => { return { content, contentCompletion: contentCompletion.id }; })];
    contentCompletion.contents.map((content) => {
      if (completedContents.findIndex((x) => x.id === content.id) === -1) {
        completedContents.push(content);
      }
    });
  });

  if (!currentEmployee) { return null; }

  const progressOf = currentEmployee.stats.reduce((currentValue, stat) => {
    if (stat.of) {
      return stat.of + currentValue;
    }
    return currentValue;
  }, 0);

  const progressDone = currentEmployee.stats.reduce((currentValue, stat) => {
    if (stat.of) {
      return stat.done + currentValue;
    }
    return currentValue;
  }, 0);
  const progress = progressDone / progressOf;
  const actionIconStyle = { fontSize: 30, color: 'white' };

  return (
    <LayoutProvider
      type='internal'
      backgroundImageStyle={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-bins.png')" }}
      pageTitle={`${currentEmployee.firstName} ${currentEmployee.lastName}`}
      actionButton={
        isMobile && (
          <div className='mobile-action-items'>
            <StyledActionButton>
              <DashboardOutlined 
                style={actionIconStyle} 
                onClick={() => { dispatch(Logic.general.router.actions.navigateToRoute({ to: `/`, replace: false })); }}/>
            </StyledActionButton>
            <StyledActionButton onClick={() => setAreOptionsShown(!areOptionsShown)}>
              {!areOptionsShown ? <SettingOutlined style={actionIconStyle} /> : <CloseOutlined style={actionIconStyle} />}
            </StyledActionButton>
          </div>
        )
      }
    >
      <OrganizationStyleWrapper>
        <div style={{ paddingTop: '60px' }}>
          <MainWrapper>
            <LeftColumn>
              <div>
                {areOptionsShown && (
                  inProgressContents.length > 0 &&
                  <Fragment>
                    <div style={{ marginBottom: '1vh', fontFamily: 'Lato', fontSize: '16px' }}>Content in progress</div>
                    <CustomGrid>
                      {inProgressContents.map(inProgressContent => {
                        const contentCompletion = completedCollections.find(x => x.id === inProgressContent.contentCompletion);
                        const collection = collections.find(x => x.id === contentCompletion.relatedCollection.id);

                        if (!collection) {
                          return null;
                        }

                        const content = collection.contents.find(x => x.id === inProgressContent.content);
                        return (
                          <Fragment key={content.id}>
                            <ContentCard
                              coverImage={content.picture && content.picture.link}
                              description={content.description}
                              actionTest={
                                <div>
                                  <div style={{ textAlign: 'right', width: '100%' }}>
                                    <div style={{ color: '#5BBA6F', cursor: 'pointer' }} onClick={() =>
                                      dispatch(updateContentCompletion({ id: contentCompletion.id, contents: [...contentCompletion.contents, content.id] }))}>
                                      Mark As Resolved <span style={{ color: 'black' }}><CheckCircleOutlined /></span>
                                    </div>
                                  </div>
                                </div>
                              }
                              tags={content.tags}
                              title={content.title}
                              contentId={content.id}
                              collectionId={''}
                              bordered
                            />
                          </Fragment>
                        );
                      })}
                    </CustomGrid>
                  </Fragment>
                )}
              </div>
              {!areOptionsShown && (
                <div>
                  <div style={{ margin: '5vh 0vw', fontFamily: 'Lato', fontSize: '16px' }}>Content Completed</div>
                  <CustomGrid>
                    {completedContents.map(content => (
                      <ContentCard
                        coverImage={content.picture && content.picture.link}
                        description={content.description}
                        tags={content.tags}
                        title={content.title}
                        key={content.id}
                        contentId={content.id}
                        collectionId={''}
                        bordered
                      />
                    ))}
                  </CustomGrid>
                </div>)}
            </LeftColumn>
            <RightColumn>
              <div className={'completion'}>
                <p>Overall Completion</p>
                <StyledProgressBar percent={progress * 100} height={20} />
              </div>
              {
                !isMobile &&
                <div>
                  <RightColumn.Header>Employee Options</RightColumn.Header>
                  <RightColumn.DescriptionText>If you would like to remove this user:</RightColumn.DescriptionText>
                  <RightColumn.ActionButton
                    onClick={() =>
                      dispatch(deleteUser({ user: currentEmployee.id }))}
                  >Remove Employee
                  </RightColumn.ActionButton>

                  <RightColumn.DescriptionText>If you would like to promote this user:</RightColumn.DescriptionText>
                  <RightColumn.ActionButton
                    onClick={() =>
                      dispatch(roleAction({ role: 'ba', user: currentEmployee.id }))}
                  >Promote Employee
                  </RightColumn.ActionButton>
                </div>
              }
              {areOptionsShown && (<Fragment>
                <RightColumn.Header>Employee Options</RightColumn.Header>
                <RightColumn.DescriptionText>If you would like to remove this user:</RightColumn.DescriptionText>
                <RightColumn.ActionButton
                  onClick={() =>
                    dispatch(deleteUser({ user: currentEmployee.id }))}
                >Remove Employee
                </RightColumn.ActionButton>

                <RightColumn.DescriptionText>If you would like to promote this user:</RightColumn.DescriptionText>
                <RightColumn.ActionButton
                  onClick={() =>
                    dispatch(roleAction({ role: 'ba', user: currentEmployee.id }))}
                >Promote Employee
                </RightColumn.ActionButton>
              </Fragment>)}
            </RightColumn>
          </MainWrapper>
        </div>
      </OrganizationStyleWrapper>
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.sm,
});

export default withRouter(withSizes(mapSizesToProps)(Dashboard));
