import React, { useState } from 'react';
import { Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

export const PopoutMenu = ({ content, title, trigger = "click", iconSize = 20, ...props }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Popover
      content={content}
      title={title}
      trigger={trigger}
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
      {...props}
    >
      <MoreOutlined style={{ transform: 'rotate(90deg)', color: 'black', fontSize: iconSize }} />
    </Popover>
  );
};
