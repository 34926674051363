const ROOT = 'EMPLOYEE_COLLECTIONS';

export const FETCH_CONTENT = `${ROOT}/FETCH_CONTENT`;
export const FETCH_CONTENT_SUCCESS = `${ROOT}/FETCH_CONTENT_SUCCESS`;
export const FETCH_CONTENT_ERROR = `${ROOT}/FETCH_CONTENT_ERROR`;

export const GET_CURRENT_COLLECTION = `${ROOT}/GET_CURRENT_COLLECTION`;
export const GET_CURRENT_COLLECTION_SUCCESS = `${ROOT}/GET_CURRENT_COLLECTION_SUCCESS`;
export const GET_CURRENT_COLLECTION_ERROR = `${ROOT}/GET_CURRENT_COLLECTION_ERROR`;

export const SET_CONTENT_AS_COMPLETE = `${ROOT}/SET_CONTENT_AS_COMPLETE`;
export const SET_CONTENT_AS_COMPLETE_SUCCESS = `${ROOT}/SET_CONTENT_AS_COMPLETE_SUCCESS`;
export const SET_CONTENT_AS_COMPLETE_ERROR = `${ROOT}/SET_CONTENT_AS_COMPLETE_ERROR`;

export const SET_TUTORIAL_CONTENT_AS_COMPLETE = `${ROOT}/SET_TUTORIAL_CONTENT_AS_COMPLETE`;
export const SET_TUTORIAL_CONTENT_AS_COMPLETE_SUCCESS = `${ROOT}/SET_TUTORIAL_CONTENT_AS_COMPLETE_SUCCESS`;
export const SET_TUTORIAL_CONTENT_AS_COMPLETE_ERROR = `${ROOT}/SET_TUTORIAL_CONTENT_AS_COMPLETE_ERROR`;

export const GET_UPCOMING_CALENDAR = `${ROOT}/GET_UPCOMING_CALENDAR`;
export const GET_UPCOMING_CALENDAR_SUCCESS = `${ROOT}/GET_UPCOMING_CALENDAR_SUCCESS`;
export const GET_UPCOMING_CALENDAR_ERROR = `${ROOT}/GET_UPCOMING_CALENDAR_ERROR`;

export const GET_COMPLETED_COLLECTIONS = `${ROOT}/GET_COMPLETED_COLLECTIONS`;
export const GET_COMPLETED_COLLECTIONS_SUCCESS = `${ROOT}/GET_COMPLETED_COLLECTIONS_SUCCESS`;
export const GET_COMPLETED_COLLECTIONS_ERROR = `${ROOT}/GET_COMPLETED_COLLECTIONS_ERROR`;

export const GET_TUTORIAL_COLLECTION = `${ROOT}/GET_TUTORIAL_COLLECTION`;
export const GET_TUTORIAL_COLLECTION_SUCCESS = `${ROOT}/GET_TUTORIAL_COLLECTION_SUCCESS`;
export const GET_TUTORIAL_COLLECTION_ERROR = `${ROOT}/GET_TUTORIAL_COLLECTION_ERROR`;

export const GET_COLLECTIONS = `${ROOT}/GET_COLLECTIONS`;
export const GET_COLLECTIONS_SUCCESS = `${ROOT}/GET_COLLECTIONS_SUCCESS`;
export const GET_COLLECTIONS_ERROR = `${ROOT}/GET_COLLECTIONS_ERROR`;

export const HANDLE_CREATE_TUTORIAL_CONTENT_COMPLETION_RECORD_SUCCESS = `${ROOT}/HANDLE_CREATE_TUTORIAL_CONTENT_COMPLETION_RECORD_SUCCESS`;

export function fetchContent (contentId) {
  contentId = typeof contentId === 'object' ? contentId.query.content : contentId;
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/contents/${contentId}`,
      method: 'GET',
      params: {},
      query: {},
      body: {},
      auth: null
    },
    success: {
      actions: [FETCH_CONTENT_SUCCESS]
    },
    error: {
      actions: [FETCH_CONTENT_ERROR]
    }
  };
}

export function getCurrentCollection (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/users/currentCollection`,
      method: 'GET',
      params: {},
      query: {
      },
      body: payload,
      auth: null
    },
    success: {
      actions: [GET_CURRENT_COLLECTION_SUCCESS]
    },
    error: {
      actions: [GET_CURRENT_COLLECTION_ERROR]
    }
  };
}

export function setContentAsComplete (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/users/content/${payload.content}/setCompleted`,
      method: 'PUT',
      params: {},
      query: {
      },
      body: payload,
      auth: null
    },
    success: {
      actions: [SET_CONTENT_AS_COMPLETE_SUCCESS]
    },
    error: {
      actions: [SET_CONTENT_AS_COMPLETE_ERROR]
    }
  };
}

export function setTutorialContentAsComplete (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/users/content/${payload.content}/setTutorialCompleted`,
      method: 'PUT',
      params: {},
      query: {
      },
      body: payload,
      auth: null
    },
    success: {
      actions: [SET_TUTORIAL_CONTENT_AS_COMPLETE_SUCCESS]
    },
    error: {
      actions: [SET_TUTORIAL_CONTENT_AS_COMPLETE_ERROR]
    }
  };
}

export function getUpcomingCalendar (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/users/upcomingCalendar`,
      method: 'GET',
      params: {},
      query: {
      },
      body: payload,
      auth: null
    },
    success: {
      actions: [GET_UPCOMING_CALENDAR_SUCCESS]
    },
    error: {
      actions: [GET_UPCOMING_CALENDAR_ERROR]
    }
  };
}

export function getCompletedCollections (payload) {
  let employeeData = {};
  if (payload.params.employeeId) {
    employeeData["employee"] = payload.params.employeeId;
  }
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/users/completedCollections`,
      method: 'GET',
      params: {},
      query: employeeData,
      body: {},
      auth: null
    },
    success: {
      actions: [GET_COMPLETED_COLLECTIONS_SUCCESS]
    },
    error: {
      actions: [GET_COMPLETED_COLLECTIONS_ERROR]
    }
  };
}

export function getTutorialCollection (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/collections/tutorial`,
      method: 'GET',
      params: {},
      query: {
      },
      body: payload,
      auth: null
    },
    success: {
      actions: [GET_TUTORIAL_COLLECTION_SUCCESS]
    },
    error: {
      actions: [GET_TUTORIAL_COLLECTION_ERROR]
    }
  };
}

export function getCollections (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/collections`,
      method: 'GET',
      params: {},
      query: {
      },
      body: payload,
      auth: null
    },
    success: {
      actions: [GET_COLLECTIONS_SUCCESS]
    },
    error: {
      actions: [GET_COLLECTIONS_ERROR]
    }
  };
}

export const RESET_COLLECTIONS_REDUCER = `${ROOT}/RESET_COLLECTIONS_REDUCER`;

export const resetCollectionsReducer = () => ({
  type: RESET_COLLECTIONS_REDUCER
});


