/* eslint-disable no-console */
import { call, put, takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';
import api from './api';
import * as actions from './actions';
import * as BAEmployeeActions from '../../business-admin/employees/actions';
// import * as awsActions from '../aws/actions';
import * as routerActions from '../router/actions';
import { sessionService } from 'redux-react-session';

function * fetchRoute (action) {
  try {
    const response = yield call(api.fetch, Object.assign(action.payload.query, { access_token: action.authToken }));
    if (response) {
      yield put({
        type: actions.FETCH_SUCCESS,
        payload: response
      });
    } else {
      yield put({
        type: actions.FETCH_ERROR,
        payload: response
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function * fetchOneRoute (action) {
  try {
    const response = yield call(api.fetchOne, Object.assign({ uid: action.payload.uid }, { access_token: action.authToken }));
    if (response) {
      yield put({
        type: actions.FETCH_ONE_SUCCESS,
        payload: response
      });
    } else {
      yield put({
        type: actions.FETCH_ONE_ERROR,
        payload: response
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function * saveRoute (action) {
  try {
    const response = yield call(api.save, Object.assign(action.payload, { authToken: action.authToken }));
    if (response) {
      yield put({
        type: actions.SAVE_SUCCESS,
        payload: response
      });
      yield put({
        type: routerActions.NAVIGATE_TO_ROUTE,
        payload: { to: `/organization/assessments/${action.payload.assessment}/edit`, replace: true }
      });
    } else {
      yield put({
        type: actions.SAVE_ERROR,
        payload: response
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function * updateRoute (action) {
  try {
    const response = yield call(api.update, Object.assign(action.payload, { authToken: action.payload.authToken }));
    if (response) {
      yield put({
        type: actions.UPDATE_SUCCESS,
        payload: response
      });
      yield put({
        type: 'ORGANIZATIONS/FETCH_AFFILIATIONS',
        payload: { authToken: action.payload.authToken }
      });
    } else {
      yield put({
        type: actions.UPDATE_ERROR,
        payload: response
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function * deleteRoute (action) {
  try {
    const response = yield call(api.delete, Object.assign(action.payload, { access_token: action.authToken }));
    const affiliationResponse = yield call(api.deleteAffiliation, Object.assign(action.payload, { access_token: action.authToken }));
    console.log(response, affiliationResponse, actions.DELETE_SUCCESS);
    if (response && affiliationResponse) {
      yield put({
        type: actions.DELETE_SUCCESS,
        payload: action.payload
      });
    } else {
      yield put({
        type: actions.DELETE_ERROR,
        payload: response
      });
    }
  } catch (err) {
    console.log(err);
  }
}


function * resetPassword (action) {
  try {
    const response = yield call(api.resetPassword, Object.assign(action.payload, { access_token: action.authToken }));
    if (response) {
      yield put({
        type: actions.RESET_PASSWORD_SUCCESS,
        payload: response
      });
      yield put({
        type: routerActions.NAVIGATE_TO_ROUTE,
        payload: { to: action.payload.redirectUrl, replace: true }
      });
      notification['success']({
        message: 'Password Updated!',
        description: `Nice job`
      });
    } else {
      yield put({
        type: actions.RESET_PASSWORD_ERROR,
        payload: response
      });
    }
  } catch (err) {
    console.log(err);
  }
}

// function * fetchInitialDataRoute () {
//   try {
//     yield put(awsActions.fetchAWSMetrics());
//     yield take('AWS/FETCH_AWS_METRICS_SUCCESS');

//     yield put({
//       type: actions.FETCH_INITIAL_DATA_SUCCESS,
//       payload: null
//     });
//   } catch (err) {
//     console.log(err);
//   }
// }

function * updateSessionUserSuccessRoute (action) {
  try {
    const user = yield sessionService.loadUser();
    yield sessionService
      .saveUser({ ...action.payload, token: user.token });
    yield notification.success({ message: 'Updated successfully!', description: 'Value successfully updated.' });
  } catch (err) {
    console.log(err);
    yield notification.error({ message: 'Error happened!', description: err.message });
  }
}

function * updateSessionUserPasswordRoute (action) {
  try {
    const response = yield call(api.updateSessionUserPassword, action.payload);
    if (response) {
      yield notification['success']({
        message: `Successfully updated password.`,
        description: `Nice job`
      });
      yield put({
        type: actions.UPDATE_SESSION_USER_SUCCESS,
        payload: null
      });
    }
  } catch (err) {
    let error = JSON.parse(err.message);
    yield notification['success']({
      message: `${error.response.data.message}`,
      description: `warning`
    });
  }
}

function * checkInvitedStatusRoute (action) {
  try {
    const response = yield call(api.checkInvitedStatus, action.payload);
    if (response == 'active') {
      yield put({
        type: routerActions.NAVIGATE_TO_ROUTE,
        payload: { to: '/login', replace: true }
      });
      yield notification['success']({
        message: 'Account is already active. Please continue to log in.',
        description: ''
      });
    }
    yield put({
      type: actions.CHECK_INVITED_STATUS_SUCCESS,
      payload: null
    });
  } catch (err) {
    console.log(err);
  }
}

function * completeInviteSetupRoute (action) {
  try {
    const response = yield call(api.completeInviteSetup, action.payload);
    if (response) {
      yield put({
        type: routerActions.NAVIGATE_TO_ROUTE,
        payload: { to: '/login', replace: true }
      });
      yield notification['success']({
        message: 'Account in now activated. Please continue to log in.',
        description: ''
      });
    }
    yield put({
      type: actions.COMPLETE_INVITE_SETUP_SUCCESS,
      payload: null
    });
  } catch (err) {
    console.log(err);
  }
}

function * getOrganizationMembersRoute (action) {
  try {
    const response = yield call(api.getOrganizationMembers, action.payload);

    if (response) {
      yield put({
        type: actions.GET_ORGANIZATION_MEMBERS_SUCCESS,
        payload: response
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function * inviteSuccessRoute () {
  try {
    notification['success']({
      message: `Successfully Invited New Member!`,
    });
    yield put(BAEmployeeActions.fetchEmployeesDashboardData());
  } catch (err) {
    console.log(err);
  }
}

function * updateProfileImageRoute () {
  try {
    notification['success']({
      message: `Successfully Updated Profile Image!`,
    });
    yield put({
      type: 'SESSION_REFRESH',
      payload: null
    });
  } catch (err) {
    console.log(err);
  }
}

function * roleActionSuccessRoute () {
  try {
    yield notification['success']({
      message: `Successfully Updated The Users Role`,
    });
  } catch (err) {
    console.log(err);
  }
}

function * deleteUserSuccessRoute () {
  try {
    yield notification['success']({
      message: `Successfully Deleted The User`,
    });
    yield put({
      type: routerActions.NAVIGATE_TO_ROUTE,
      payload: { to: '/ba/dashboard', replace: true }
    });
  } catch (err) {
    console.log(err);
  }
}

function * inviteFailure ({ payload }) {
  try {
    yield notification.error({
      message: (payload.error && payload.error.includes('E11000')) ? 'Email is already in use.' : 'Some error happened. Contact support.',
    });
  } catch (err) {
    console.log(err);
  }
}

function * sagas () {
  yield takeEvery(actions.FETCH, fetchRoute);
  yield takeEvery(actions.FETCH_ONE, fetchOneRoute);
  yield takeEvery(actions.SAVE, saveRoute);
  yield takeEvery(actions.UPDATE, updateRoute);
  yield takeEvery(actions.DELETE, deleteRoute);
  yield takeEvery(actions.RESET_PASSWORD, resetPassword);
  // yield takeEvery(actions.FETCH_INITIAL_DATA, fetchInitialDataRoute);
  yield takeEvery(actions.UPDATE_SESSION_USER_SUCCESS, updateSessionUserSuccessRoute);
  yield takeEvery(actions.UPDATE_SESSION_USER_PASSWORD, updateSessionUserPasswordRoute);
  yield takeEvery(actions.CHECK_INVITED_STATUS, checkInvitedStatusRoute);
  yield takeEvery(actions.COMPLETE_INVITE_SETUP, completeInviteSetupRoute);
  yield takeEvery(actions.GET_ORGANIZATION_MEMBERS, getOrganizationMembersRoute);
  yield takeEvery('USERS/INVITE_SUCCESS', inviteSuccessRoute);
  yield takeEvery(actions.INVITE_ERROR, inviteFailure);
  yield takeEvery(actions.UPDATE_USER_PROFILE_IMAGE_SUCCESS, updateProfileImageRoute);
  yield takeEvery(actions.ROLE_ACTION_SUCCESS, roleActionSuccessRoute);
  yield takeEvery(actions.DELETE_SUCCESS, deleteUserSuccessRoute);
}

export default sagas;
