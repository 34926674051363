import { takeEvery, put } from "redux-saga/effects";
import * as actions from './actions';
import { navigateToRoute } from "../../general/router/actions";
import { notification } from 'antd';

function * createContentSucces(action) {
  yield put(navigateToRoute({ to: `${window.location.pathname}?content=${action.payload.id}`, replace: true }));
}

function * updateContentSucces() {
  yield notification['success']({
		message: `Successfully Updated Collection Content`,
	});
}

function * sagas () {
  yield takeEvery(actions.CREATE_CONTENT_SUCCESS, createContentSucces);
  yield takeEvery(actions.UPDATE_CONTENT_SUCCESS, updateContentSucces);
}

export default sagas;
