import React, { Component } from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Logic from '../../../logic';
import { stringBreakpoints } from '../../../app/constants/breakpoints';

const HomeStyleWrapper = styled.div`
  .home {
    background-image: url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-main.png') !important;
    background-size: cover;
    background-repeat: no-repeat;

    &__login-wrapper {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      height: 100%;
    }

    &__login-container {
      width: 45%;
      padding: 10vh 15vh;
      &__logo {
        padding-bottom: 10vh;
      }
    }

    &__left-side,
    &__right-side {
      box-sizing: border-box;
    }

    &__left-side {
      width: 30%;
      text-align: center;
      border-right: 1px solid grey;

      &__testimonial {
        background-color: #41BCCF;
        padding: 20px 0px;
      }
    }
    &__right-side {
      width: 70%;
    }

    .get-started {
      width: 50% !important;
      margin: auto;
      padding-top: 20px;
      
      &__slogan {
        line-height: 1.2;
        padding-bottom: 4px;
      }
      &__additional {
        padding-bottom: 25px;
        padding-top: 20px;
      }
    }

    @media only screen and (max-width: ${stringBreakpoints.md}) {
      &__login-container {
        width: 70%;
      }
    }

    @media only screen and (max-width: ${stringBreakpoints.sm}) {
      & {
        background-size: 130% 100%;
      }
      
      &__login-wrapper {
        display: block;
        padding-top: 7.5vh;
      }

      &__login-container {
        width: 90%;
        margin: auto;
        padding: 0;
        &__logo {
          padding-bottom: 5vh;
          width: 100%;
        }
      }
    }
  }
`;

class Home extends Component {
  constructor (props) {
    super(props);

    this.state = {
      
    };
  }

  render () {
    return (
      <HomeStyleWrapper>
        <div style={{ height: '100vh' }} className={'home'}>
          <div className="home__login-wrapper">
            <div className={'home__login-container'}>
              <div className={'home__login-container__logo'} style={{ width: '100%', textAlign: 'center', cursor: 'pointer' }}>
                <a href='https://www.wa-food-ind.org/' target={'blank'}><img style={{ width: '40%' }} src={'https://wfia-dev.s3-us-west-2.amazonaws.com/img/wfia.png'} /></a>
                <a href='https://lni.wa.gov/safety-health/grants-committees-partnerships/safety-health-investment-projects-grant-program/' target={'blank'}><img style={{ width: '40%', marginLeft: '5%' }} src={'https://wfia-dev.s3-us-west-2.amazonaws.com/img/ship.png'} /></a>
              </div>
              {this.props.children}
            </div>
          </div>
        </div>
      </HomeStyleWrapper>
    );
  }
}

const { externalComment } = Logic.general.app.actions;
const { navigateToRoute } = Logic.general.router.actions;

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    externalComment,
    navigateToRoute
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
