import React from 'react';
import { LayoutProvider } from '../../layouts';
// import { Row, Col, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Avatar } from 'antd';
import Logic from '../../../logic';
import styled from 'styled-components';
import withSizes from 'react-sizes';
import { numberBreakpoints } from '../../constants/breakpoints';
import { PopoutMenu } from '../../components/popoutMenu';

const columns = [
  {
    render: (organization) => {
      return <Avatar src={organization.picture ? organization.picture.link : 'https://cdn-sharing.adobecc.com/id/urn:aaid:sc:US:d2759bd3-7e50-4494-850d-dc3e38d8b398;version=0?component_id=a116febf-dc09-4d1b-a6d9-7f381e8f4fa5&api_key=CometServer1&access_token=1602650564_urn%3Aaaid%3Asc%3AUS%3Ad2759bd3-7e50-4494-850d-dc3e38d8b398%3Bpublic_2bf2a548c0147a543fbe70037512c9ead605107f'} />;
    },
    key: 'avatar',
  },
  {
    title: 'Business Name',
    render: (organization) => {
      return <p>{organization.company}</p>;
    },
    key: 'name'
  },
  {
    title: 'Email address',
    render: (organization) => {
      return <p>{organization.contactEmail}</p>;
    },
    key: 'email',
  },
  {
    title: 'Number of Employees',
    render: (organization) => {
      return <p>{organization.employees.length}</p>;
    },
    key: 'employees',
  },
  {
    render: () => {
      // cool '...' buttons should go here
      return <p>...</p>;
    }
  }
];

const MobileViewWrapper = styled.div`
  padding: 10px 10px 20px 10px;
  width: 100%;
  background: ${p => p.isOdd ? '#f2f2f2' : 'none'};

  .organization {
    &__first-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__second-row {
      padding-top: 20px;
    }

    &__info-section {
      width: 70%;
    }

    &__full-name {
      font-weight: bold;
    }

    &__popout {
      width: 10%;
    }
  }  
`;

const TableStyle = styled.div`
  .ant-table-cell {
    cursor: pointer;
  }
`;

export const Dashboard = ({ isMobile }) => {
  const organizations = useSelector(state => state.containers['general::organizations'].organizations);
  const dispatch = useDispatch();

  if (isMobile) {
    return (
      <LayoutProvider
        type='internal'
        backgroundImageStyle={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-business.png')" }}
        pageTitle={'Master Administrator Dashboard'}
        noGutter
      >
        <div style={{ padding: '10vh 0' }}>
          {
            organizations.map((organization, i) => {
              return (
                <MobileViewWrapper
                  key={organization.id}
                  className="organization"
                  isOdd={(i + 1) % 2}
                  onClick={() => dispatch(Logic.general.router.actions.navigateToRoute({ to: `/ma/organizations/${organization.id}`, replace: false }))}
                >
                  <div className="organization__first-row">
                    <Avatar
                      className="organization__avatar"
                      src={organization.picture ? organization.picture.link : 'https://cdn-sharing.adobecc.com/id/urn:aaid:sc:US:d2759bd3-7e50-4494-850d-dc3e38d8b398;version=0?component_id=a116febf-dc09-4d1b-a6d9-7f381e8f4fa5&api_key=CometServer1&access_token=1602650564_urn%3Aaaid%3Asc%3AUS%3Ad2759bd3-7e50-4494-850d-dc3e38d8b398%3Bpublic_2bf2a548c0147a543fbe70037512c9ead605107f'}
                    />
                    <div className="organization__info-section">
                      <p className="organization__full-name">{organization.company}</p>
                      <p className="organization__email">{organization.contactEmail || organization.owner.email}</p>
                    </div>
                    <PopoutMenu className="organization__popout" />
                  </div>
                  <div className="organization__second-row">
                    {organization.employees.length || 'No'} Employee(s)
                </div>
                </MobileViewWrapper>
              );
            })
          }
        </div>
      </LayoutProvider>
    );
  }

  return (
    <LayoutProvider
      type='internal'
      backgroundImageStyle={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-business.png')" }}
      pageTitle={'Master Admin Dashboard'}
    >
      <div style={{ padding: '10vh 10vw', textAlign: 'center' }}>
        <TableStyle>
          <Table
            dataSource={organizations}
            columns={columns}
            onRow={(record) => {
              return {
                onClick: () => {
                  dispatch(Logic.general.router.actions.navigateToRoute({ to: `/ma/organizations/${record.id}`, replace: false }));
                }
              };
            }}
            className="custom-table"
          />
        </TableStyle>
      </div>
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.sm,
});

export default withRouter(withSizes(mapSizesToProps)(Dashboard));
