/* eslint-disable no-console */
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import createGlobalReducer from './base-reducer';
import globalSagas from './base-sagas';
import { sessionService } from 'redux-react-session';
import { composeWithDevTools } from 'redux-devtools-extension';
import debounce from 'lodash/debounce';
// import { saveState, loadState } from './local-storage';
import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux';
import axios from 'axios';
// var jwt = require('jsonwebtoken');

Raven.config(process.env.SENTRY_KEY).install();

const storeConst = async () => {
  // let persistedState = await loadState();
  // if (persistedState && persistedState.containers) {
  //   persistedState.containers.stripe.paymentIntent = null;
  //   persistedState.containers.stripe.setupIntent = null;
  //   persistedState.containers.stripe.processingPaymentStep = null;
  //   persistedState.containers.orders.coupon = null;
  //   persistedState.containers.app.blockUi = false;
  //   persistedState.containers.app.blockMessage = '';
  //   persistedState.containers.modals.configurationVisible = false;
  //   persistedState.containers.modals.dialogVisible = false;
  //   persistedState.containers.router.isLoading = false;
  //   persistedState.containers.router.isLoaded = false;
  //   persistedState.containers.router.isConfigured = false;
  //   persistedState.containers.router.routerHistory = [];
  //   persistedState.containers.settings.applicationSettingsLoaded = false;
  // }

  const sagaMiddleware = createSagaMiddleware();

  const applyAuthToken = () => store => next => action => {
    // let { authenticated, user } = store.getState().session;
    // if (action.type == 'ROUTER/CONFIGURE_ROUTE' && authenticated) {
    //   jwt.verify(user.token, process.env.DASH_REST_JWT_SECRET, function(err, decoded) {
    //   if (err && err.name === 'TokenExpiredError') {
    //     debugger;
    //     action.type = 'SESSION/LOGOUT';
    //   }
    //   });  
    // }
    action.payload = action.payload || {};
    if (action.apiCall && action.apiCall.authenticated && !action.apiCall.token) {
      action.apiCall.token = store.getState().session.user.token;
    }
    if (action.apiCall && action.apiCall.master && !action.apiCall.token) {
      action.apiCall.token = process.env.WFIA_MASTER_TOKEN;
    }
    if (action.authCall) {
      action.authToken = store.getState().session.user.token;
    }
    return next(action);
  };

  const middlewares = [
    // Middleware for intercepting and dispatching navigation actions
    routerMiddleware(history),
    sagaMiddleware,
    createRavenMiddleware(Raven, {})
  ];

  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(createLogger());
  }

  const store = createStore(
      createGlobalReducer(history),
      // persistedState,
      composeWithDevTools(applyMiddleware(...middlewares), applyMiddleware(applyAuthToken()))
  );

  store.subscribe(debounce(() => {
    try {
      // saveState({
      //   containers: store.getState().containers
      // });
    } catch (e) {
      console.error(e);
    }
  }, 1000));

  const options = { driver: 'LOCALSTORAGE' };

  sessionService.initSessionService(store, options)
      .then(() => console.log('Redux React Session is ready and a session was refreshed from your storage'))
      .catch(() => console.log('Redux React Session is ready and there is no session in your storage'));

  sagaMiddleware.run(globalSagas);

// Axios Interceptors
  axios.interceptors.response.use((response) => {
    if (response.headers['refresh-token'] && !response.config.url.match('/auth/refresh')) {
      // store.dispatch({
      //   type: 'SESSION_REFRESH',
      //   payload: null,
      //   authCall: true
      // });
    }
    return response;
  }, function (error) {
    // Do something with response error
    return Promise.reject(error);
  });
  return store;
};




export const history = createBrowserHistory();

export default storeConst;
