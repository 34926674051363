import { takeEvery } from "redux-saga/effects";
import * as actions from './actions';
import { notification } from 'antd';

function * deleteCollectionSuccess() {
  yield notification['success']({
		message: `Successfully Deleted The Collection`,
	});
}

function * sagas () {
  yield takeEvery(actions.DELETE_COLLECTION_SUCCESS, deleteCollectionSuccess);
}

export default sagas;
