import React from 'react';
import { LayoutProvider } from '../../layouts';
// import { Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CustomGrid } from '../../components/grid';
import { ContentCard } from '../../components/contentCard';
import { DashboardOutlined } from '@ant-design/icons';
import withSizes from 'react-sizes';

import { stringBreakpoints, numberBreakpoints } from '../../../app/constants/breakpoints';
import styled from 'styled-components';
import Logic from '../../../logic';

const OrganizationStyleWrapper = styled.div`
  .ant-divider {
    margin: 5px 0px;
  }
  .invite-form {
    margin-top: 20px;
  }
  .current-month-content {
    padding: 10vh 20vw;
    &__title {
      font-family: Times New Roman;
    }
    &__subtitle {
      margin-bottom: 20px;
    }
  }
  .future-collection {
    margin: 5vh 0vw;
    &__title {
      font-family: Times New Roman;
      font-weight: 700;
    }
    &__subtitle {
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    .current-month-content {
      padding: 10vh 20px;
    }
  }
`;

const MainWrapper = styled.div`
  
`;
const LeftColumn = styled.div`
  width: 100%;
`;

const StyledActionButton = styled.div`
  padding: 12.5px;
  background: #d5733f;
  cursor: pointer;
`;

const actionIconStyle = { fontSize: 30, color: 'white' };

export const Dashboard = ({ isMobile }) => {
  const completedCollections = useSelector((state) => state.containers['employee::collections'].completedCollections);
  const { user } = useSelector(state => state.session);
  if (!completedCollections || completedCollections.length === 0) { return null; }
  let contents = completedCollections.map((collection) => collection.contents);
  contents = [].concat.apply([], contents);
  const dispatch = useDispatch();

  return (
    <LayoutProvider 
      type='internal' 
      noGutter
      backgroundImageStyle={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-bins.png')"}}
      pageTitle={'Completed Training Content'}
      actionButton={
        isMobile && (
          <div className='mobile-action-items'>
            <StyledActionButton>
              <DashboardOutlined 
                style={actionIconStyle} 
                onClick={() => { dispatch(Logic.general.router.actions.navigateToRoute({ to: `/`, replace: false })); }}/>
            </StyledActionButton>
          </div>
        )
      }
    >
      <OrganizationStyleWrapper>
        <div className={'current-month-content'}>
          <MainWrapper>
            <div className={'future-collection'}>
              <LeftColumn>
                <div>
                  <CustomGrid>
                    {contents.map(content => (
                      <ContentCard
                        coverImage={content.picture && content.picture.link}
                        description={content.description}
                        tags={['Complete']}
                        title={content.title}
                        key={content.id}
                        contentId={content.id}
                        collectionId={content.relatedCollection}
                        bordered
                        review={true}
                        role={user.role || 'employee'}
                      />
                    ))}
                  </CustomGrid>
                </div>
              </LeftColumn>
            </div>
        </MainWrapper>
        </div>
      </OrganizationStyleWrapper>
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.sm,
});

export default withRouter(withSizes(mapSizesToProps)(Dashboard));
