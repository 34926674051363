import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import withSizes from 'react-sizes';
import { ContentCard } from '../../components/contentCard';
import { LayoutProvider } from '../../layouts';
import { Row, Col } from 'antd';
import { ContentDragSorter } from '../../components/contentDragSorter';
import { numberBreakpoints, stringBreakpoints } from '../../constants/breakpoints';
import {
  CloseOutlined,
  SettingOutlined,
  DashboardOutlined
} from '@ant-design/icons';

import Logic from '../../../logic';

// const Row = styled.div`
//   display: flex;
//   justify-content: space-between;

//   margin-left: 15%;
//   margin-right: 35px;
//   margin-top: 60px;

//   @media only screen and (max-width: ${stringBreakpoints.sm}) {
//     margin-left: 20px;
//     margin-right: 20px;
//   }
// `;
// const LeftColumn = styled.div`
//   width: 75%;

//   @media only screen and (max-width: ${stringBreakpoints.md}) {
//     width: 100%;
//   }
// `;
const RightColumn = styled.div`

  @media only screen and (max-width: ${stringBreakpoints.md}) {
    width: 100%;
  }
`;
RightColumn.Header = styled.h3`
  color: #549770;
  font-size: 22px;
  border-bottom: 3px solid #549770;
  margin-bottom: 25px;
`;
RightColumn.DescriptionText = styled.p`
  color: #b8b8b8;
  margin-bottom: 5px;
`;
RightColumn.ActionButton = styled.button`
  padding: 5px 23px;
  color: white;
  background: #5bba6f;
  border: none;
  box-shadow: none;
  margin-bottom: 20px;
  cursor: pointer;
`;

const StyledActionButton = styled.div`
  padding: 12.5px;
  background: #d5733f;
  cursor: pointer;
`;


const { updateCollection } = Logic.general.collections.actions;
const { navigateToRoute } = Logic.general.router.actions;

const Collection = ({ isMobile }) => {
  const currentCollection = useSelector((state) => state.containers['general::collections'].currentCollection);
  // const collectionMonths = useSelector((state) => state.containers['general::users'].initialData).collectionMonths;
  const [areOptionsShown, setAreOptionsShown] = useState(false);
  const dispatch = useDispatch();

  const actionIconStyle = { fontSize: 30, color: 'white' };

  return (
    <LayoutProvider
      noGutter
      type='internal'
      backgroundImageStyle={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-business.png')" }}
      pageTitle={currentCollection ? currentCollection.title : 'Collection'}
      actionButton={
        isMobile && (
          <div className='mobile-action-items'>
            <StyledActionButton>
              <DashboardOutlined 
                style={actionIconStyle} 
                onClick={() => { dispatch(Logic.general.router.actions.navigateToRoute({ to: `/`, replace: false })); }}/>
            </StyledActionButton>
            <StyledActionButton onClick={() => setAreOptionsShown(!areOptionsShown)}>
              {!areOptionsShown ? <SettingOutlined style={actionIconStyle} /> : <CloseOutlined style={actionIconStyle} />}
            </StyledActionButton>
          </div>

        )
      }
    >
      <Row gutter={32} style={{marginTop: '4vh'}}>
        {currentCollection && (
          <React.Fragment>
            <Col xs={0} sm={0} md={4}>
            </Col>
            <Col xs={24} sm={18} md={14}>
              <Row gutter={[16, 16]}>
                {/* <h3 style={{ fontSize: '2em', fontWeight: 'normal' }}>{currentCollection.title}</h3> */}
                  {currentCollection.contents.map((content, cKey) => (
                    <Col xs={isMobile && !areOptionsShown ? 24 : 0} sm={12} md={8} key={cKey}>
                      <ContentCard
                        coverImage={content.picture && content.picture.link}
                        description={content.description}
                        tags={content.tags}
                        title={content.title}
                        key={content.id}
                        contentId={content.id}
                        collectionId={currentCollection.id}
                        bordered
                        role='ma'
                      />
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col xs={isMobile && areOptionsShown ? 24 : 0} sm={6} md={6}>
              <RightColumn.Header>Collection Details</RightColumn.Header>
              <RightColumn.DescriptionText>Select this button to add additional content to this collection:</RightColumn.DescriptionText>
              <RightColumn.ActionButton onClick={() => dispatch(navigateToRoute({ to: `${window.location.pathname}/content-edit` }))}>Add to collection</RightColumn.ActionButton>
{/* 
              <RightColumn.DescriptionText>Select this button to delete this entire collection:</RightColumn.DescriptionText>
              
              {
                currentCollection.collectionType !== 'tutorial' &&
                <RightColumn.ActionButton onClick={() => dispatch(deleteCollection(currentCollection.id))} >Delete collection</RightColumn.ActionButton>
              } */}
              
              {currentCollection.contents && (
                <React.Fragment>
                  <RightColumn.DescriptionText>Drag to arrange content within your collection in the order you want employees to review them</RightColumn.DescriptionText>
                  <ContentDragSorter
                    contents={currentCollection.contents} 
                    onChange={orderedContents => dispatch(updateCollection({ 
                      id: currentCollection.id,
                      body: { contents: orderedContents.map(content => content.id) } 
                    }))}
                  />
                </React.Fragment>
              )}
            </Col>
          </React.Fragment>
        )}
      </Row>
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.xs
});

export default withSizes(mapSizesToProps)(Collection);
