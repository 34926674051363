import axios from 'axios';

const BACKEND_URL = process.env.WFIA_API_URL;

export default class Api {
  static makeApiRequest ({apiCall}) {
    let { method, route, params, query = {}, token, auth, body, responseType, responseHandler } = apiCall;
    Object.entries(params || {}).forEach(([
        k,
        v,
    ]) => {
        route = route.replace(`:${k}`, encodeURIComponent(v));
      });

    return axios({
      method,
      url: `${BACKEND_URL}${route}`,
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        ...query,
        access_token: token || query.access_token || process.env.MASTER_KEY
      },
      data: body,
      responseType,
      auth 
    })
      .then(response => {
        if (responseHandler) {
          const responseData = responseHandler(response);
          return responseData;
        }
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }
}
