export const USER_ROLES = {
  EMPLOYEE: 'employee',
  BA: 'ba',
  MA: 'ma'
};

export const USER_STATUSES = {
  INVITED: 'invited',
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};
