/* eslint-disable */
import React, { useImperativeHandle, useRef, useCallback, useState, useEffect } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import update from 'immutability-helper';
import styled from 'styled-components';
import { MenuOutlined } from '@ant-design/icons';

const ItemTypes = {
  CARD: 'card',
};

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 270px;
  margin-bottom: 10px;
`;

const Label = styled.div`
  background: ${p => p.isDragging ? '#5bba6f' : '#b5b5b5'};
  color: white;
  width: 87%;
  padding: 5px 0;
  text-align: center;
`;

const MenuIcon = styled(MenuOutlined)`
  font-size: 25px;
  color: '#c7c7c7';
`;

const PureCard = React.forwardRef(
  ({ text, isDragging, connectDragSource, connectDropTarget }, ref) => {
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    const opacity = isDragging ? 0 : 1;
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }));
    return (
      <div ref={elementRef} style={{ opacity, cursor: 'move' }}>
        <CardContainer>
          <Label isDragging={isDragging}>{text}</Label>
          <MenuIcon />
        </CardContainer>
      </div>
    );
  },
);

const Card = DropTarget(
  ItemTypes.CARD,
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(PureCard),
);

// export const ContentDragSorter = ({ contents, onChange = () => {} }) => {
//   console.log("ContentDragSorter -> contents", contents);
//   const [cards, setCards] = useState([{ id: 1, title: 1 }, { id: 2, title: 2 }]);
//   console.log("ContentDragSorter -> cards", cards)
//   const moveCard = useCallback(
//     (dragIndex, hoverIndex) => {
//       const dragCard = cards[dragIndex];
//       const cards = update(cards, {
//         $splice: [
//           [dragIndex, 1],
//           [hoverIndex, 0, dragCard],
//         ],
//       });
//       console.log("ContentDragSorter -> cards", cards);
//       setCards(cards);
//       onChange(cards);
//     },
//     [cards],
//   );

//   // useEffect(() => {
//   //   setCards(contents);
//   // }, [contents]);

  
// };

export const ContentDragSorter = ({ contents, onChange, style }) => {
  {
    const [cards, setCards] = useState(contents)
    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex]
        const orderedCards = update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        });
        setCards(orderedCards);
        if (typeof onChange !== 'function') return;
        onChange(orderedCards);
      },
      [cards],
    )

    return (
      <ol style={style}>
        {cards.map((card, i) => (
          <li key={card.id}>
            <Card
              index={i}
              id={card.id}
              text={card.title}
              moveCard={moveCard}
            />
          </li>
        ))}
      </ol>
    );
  }
}
