import React from 'react';
import { LayoutProvider } from '../../layouts';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
// import Logic from '../../../logic';
import withSizes from 'react-sizes';
import styled from 'styled-components';
import Logic from '../../../logic';
import { EditorResponsiveWrapper } from '../../components/editor';
import { numberBreakpoints, stringBreakpoints } from '../../../app/constants/breakpoints';

const OrganizationStyleWrapper = styled.div`
  .ant-divider {
    margin: 5px 0px;
  }
  .invite-form {
    margin-top: 20px;
  }
  .tutorial-content {
    padding: 10vh 20vw;
    &__title {
      font-family: Times New Roman;
    }
    &__subtitle, &__article {
      margin-bottom: 20px;
    }
  }

  .action-bar {
    padding: 2vh 20vw;
    height: 8vh; 
    background-color: #F2F2F2;

    &__link {
      color: #5BBA6F;
      font-weight: 400;
      text-align: right;

      &--mobile {
        display: block;
        width: 56%;
        margin: 20px auto;
        padding: 10px 30px;
        color: white;
        background: #5bba6f;
      }
    }

    &__check-circle {
      margin-left: 1vw;
      color: black;
    }
  }

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    .tutorial-content {
      padding: 35px 20px 0 20px;
    }

    .action-bar {
      &__check-circle {
        color: white; 
        font-size: 20px;
      }

      &__completion-info {
        text-align: center;
        background: #5bba6f;
        color: white;
        padding: 20px;
      }
    }
  }
`;

const { setTutorialContentAsComplete } = Logic.employee.collections.actions;

export const TutorialContentView = ({ isMobile }) => {
  const contents = useSelector((state) => state.containers['employee::collections'].contents);
  const tutorialCollection = useSelector((state) => state.containers['employee::collections'].tutorialCollection);
  const query = useSelector((state) => state.containers['general::router'].query);

  const content = contents.find(x => x.id === query.content);
  const dispatch = useDispatch();

  if (!content) { return null; }
  if (!tutorialCollection) { return null; }

  const isTutorialContentCompleted = tutorialCollection.contentCompletion && tutorialCollection.contentCompletion.contents.indexOf(content.id) > -1;

  return (
    <LayoutProvider 
      type='internal' 
      noGutter
      backgroundImageStyle={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-bins.png')"}}
      pageTitle={content.relatedCollection.title}
    >
      <OrganizationStyleWrapper>
        {!isMobile && (
          <div className={'action-bar'}>
            <Row>
              <Col span={8} offset={16}>
                {
                  isTutorialContentCompleted ? (
                    <span className={'action-bar__link'}>Content Completed</span>
                  ) : (
                    <a 
                      className={'action-bar__link'}
                      onClick={() => {
                        dispatch(setTutorialContentAsComplete({ 
                          content: content.id,
                        }));
                      }}
                    >
                      Mark As Reviewed <CheckCircleOutlined className="action-bar__check-circle" />
                    </a>
                  )
                }
              </Col>
            </Row>
          </div>
        )}
        <div className={'tutorial-content'}>
          <EditorResponsiveWrapper className="tutorial-content__article">
            {(content ? content.description : '')}
          </EditorResponsiveWrapper>
        </div>
        {isMobile && (
          isTutorialContentCompleted ? (
            <h3 className={'action-bar__completion-info'}>Content Already Completed!</h3>
          ) : (
            <a
              className={'action-bar__link action-bar__link--mobile'}
              onClick={() => {
                dispatch(setTutorialContentAsComplete({ 
                  content: content.id,
                }));
              }}
            >
              Mark As Reviewed <CheckCircleOutlined className="action-bar__check-circle"/>
            </a>
          )
        )}
      </OrganizationStyleWrapper>
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.sm,
});

export default withRouter(withSizes(mapSizesToProps)(TutorialContentView));
