import * as actions from './actions';

const initState = {
  collapsed: false,
  view: actions.getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  blockUi: false,
  blockMessage: 'Please Wait',
  pages: [],
  userSettings: [],
  organizationSettings: [],
  scanSchedule: [],
  appStatus: 'trial',
  availableStates: [],
  isFetching: false
};

export default function appReducer (state = initState, action) {
  switch (action.type) {
    case actions.COLLPSE_CHANGE:
      return {
        ...state,
        collapsed: !state.collapsed
      };
    case actions.COLLPSE_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: !state.openDrawer
      };
    case actions.TOGGLE_COLLAPSED:
      if (typeof action.payload !== 'boolean') {
        return {
          ...state,
          collapsed: !state.collapsed
        };
      }

      return {
        ...state,
        collapsed: action.payload
      };

    case actions.TOGGLE_BLOCK_UI:
      return {
        ...state,
        blockUi: !state.blockUi,
        blockMessage: action.payload.message
      };
    case actions.FETCH_PAGES_SUCCESS:
      return {
        ...state,
        pages: action.payload
      };
    case actions.FETCH_SCAN_SCHEDULE_SUCCESS: 
      return {
        ...state,
        scanSchedule: action.payload
      };
    case actions.FETCH_AVAILABLE_STATES_SUCCESS:
      return {
        ...state,
        availableStates: action.payload
      };
    case actions.SET_FETCHING_STATUS:
      return {
        ...state,
        isFetching: action.payload.isFetching
      };

    default:
      return state;
  }
}
