import React, { Component, Fragment } from 'react';
import { Row, Col, Input, Icon, Button, Form } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withSizes from 'react-sizes';
import Logic from '../../../logic';
import { LayoutProvider } from '../../layouts';
import { numberBreakpoints } from '../../constants/breakpoints';

const FormItem = Form.Item;

class Home extends Component {
  constructor (props) {
    super(props);

    this.state = {
      step: 'user'
    };
  }

  handleSubmit (values) {
    values["role"] = 'ba';
    this.setState({
      step: 'business',
      tempUser: values
    });
  }

  handleSubmitBusiness (values) {
    let data = { business: values, ...this.state.tempUser, utcOffset: new Date().getTimezoneOffset() * -1 };
    this.props.sessionRegister(data);
  }

  handleFormFailure (errorInfo) {
    console.log('Failed:', errorInfo);
  }

  render () {
    const { isMobile } = this.props;

    return (
      <LayoutProvider type='external' >
        <div style={{paddingBottom: '20px'}}>
          <h3 className={'login__welcome'}>Create account</h3>
          <div>Fill out the form below to create your Business Account.</div>
        </div>
        <div>
          {
            this.state.step === 'user' && 
            <Form
              onFinish={(values) => { this.handleSubmit(values); }} 
              onFailure={(error) => { this.handleFormFailure(error); }} 
              className="login-form"
              layout="vertical"
            >
              {
                isMobile ? (
                  <Fragment>
                    <Row gutter={8}>
                      <Col span={24}>
                        <FormItem 
                          name="firstName"
                          label="First Name"
                          rules={[{ required: true, message: 'Please input your first name!' }]}
                        >
                          <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={8}>
                      <Col span={24}>
                        <FormItem 
                          name="lastName"
                          label="Last Name"
                          rules={[{ required: true, message: 'Please input your last name!' }]}
                        >
                          <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                        </FormItem>
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <Row gutter={8}>
                    <Col span={12}>
                      <FormItem 
                        name="firstName"
                        label="First Name"
                        rules={[{ required: true, message: 'Please input your first name!' }]}
                      >
                        <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem 
                        name="lastName"
                        label="Last Name"
                        rules={[{ required: true, message: 'Please input your last name!' }]}
                      >
                        <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                      </FormItem>
                    </Col>
                  </Row>
                )
              }
              
              <FormItem
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <Input size='large' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="email" />
              </FormItem>
              <FormItem
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please input your password!' }, { min: 6, message: 'Password must be minimum 6 characters.' }]}
              >
                <Input size='large' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" />
              </FormItem>
              <FormItem
                name="idNumber"
                label="ID Number (Optional)"
                rules={[{ required: false, message: 'Please input your email!' }]}
              >
                <Input size='large' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} />
              </FormItem>
              <Row>
                <Col span={12}>
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                  <Button size='large' htmlType="submit" className="login-form-button" style={{borderRadius: '0px', cursor: 'pointer',  zIndex: '100', backgroundColor: '#5BBA6F', color: 'white'}}>
                    Create Account
                  </Button>
                </Col>
              </Row>
            </Form>
          }
          {
            this.state.step === 'business' &&
              <Form
                onFinish={(values) => { this.handleSubmitBusiness(values); }}
                onFailure={(error) => { this.handleFormFailure(error); }}
                className="login-form"
                layout="vertical"
              >
                <Row gutter={8}>
                  <Col span={24}>
                    <FormItem 
                      name="company"
                      label="Company Name"
                      rules={[{ required: true, message: 'Please input the company name!' }]}
                    >
                      <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem 
                      name="address"
                      label="Address"
                      rules={[{ required: true, message: 'Please input your address!' }]}
                    >
                      <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                    </FormItem>
                  </Col>
                </Row>
                {
                  isMobile ? (
                    <Fragment>
                      <Row gutter={8}>
                        <Col span={24}>
                          <FormItem 
                            name="city"
                            label="City"
                            rules={[{ required: true, message: 'Please input the city!' }]}
                          >
                            <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={24}>
                          <FormItem 
                            name="zipCode"
                            label="Zip Code"
                            rules={[{ required: true, message: 'Please input the zip!' }]}
                          >
                            <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                          </FormItem>
                        </Col>
                      </Row>
                    </Fragment>
                  ) : (
                  <Row gutter={8}>
                    <Col span={12}>
                      <FormItem 
                        name="city"
                        label="City"
                        rules={[{ required: true, message: 'Please input the city!' }]}
                      >
                        <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem 
                        name="zipCode"
                        label="Zip Code"
                        rules={[{ required: true, message: 'Please input the zip!' }]}
                      >
                        <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                      </FormItem>
                    </Col>
                  </Row>
                  )
                }
                <FormItem
                  name="country"
                  label="Country"
                  rules={[{ required: true, message: 'Please input your country!' }]}
                >
                  <Input size='large' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                </FormItem>

                {/* safety */}

                <FormItem
                  name="lniNumber"
                  label="LNI Number (Optional)"
                  rules={[{ required: false, message: 'Please input your LNI Number!' }]}
                >
                  <Input size='large' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                </FormItem>
                <Row>
                  <Col span={12}>
                  </Col>
                  <Col span={12} style={{textAlign: 'right'}}>
                    <Button size='large' htmlType="submit" className="login-form-button" style={{borderRadius: '0px', cursor: 'pointer',  zIndex: '100', backgroundColor: '#5BBA6F', color: 'white'}}>
                      {isMobile ? 'Next' : 'Create Business Account'}
                    </Button>
                  </Col>
                </Row>
              </Form> 
          }
          
        </div>
      </LayoutProvider>
    );
  }
}

const { sessionRegister } = Logic.general.session.actions;
const { navigateToRoute } = Logic.general.router.actions;

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    sessionRegister,
    navigateToRoute
  }, dispatch);
}

function mapSizesToProps({ width }) {
  return ({
    isMobile: width < numberBreakpoints.sm,
  });
} 

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withSizes(mapSizesToProps)(Home)));
