export const VALID = 'valid';
export const EXPIRED = 'expired';
export const INVALID = 'invalid';
export const DUPLICATE_USAGE = 'duplicate-usage';

export default {
  [EXPIRED]: {
    notificationBody: {
      message: 'Your invitation has expired',
      description: 'New invitation was sent to your mail box. Please use it instead.'
    },
    notificationMode: 'error'
  },
  [INVALID]: {
    notificationBody: {
      message: 'Invalid invitation.',
      description: 'Please make sure that you have followed right invitation.'
    },
    notificationMode: 'error'
  },
  [DUPLICATE_USAGE]: {
    notificationBody: {
      message: 'Invitation was already used.',
      description: 'Please login with your existing account.'
    },
    notificationMode: 'error'
  }
};
