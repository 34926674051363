import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import RouteWrapperData from './base-routes-wrapper-data';
import * as pages from '../app/pages';
import Logic from '../logic';

const { sessionLogout } = Logic.general.session.actions;
const { fetchOrganization } = Logic.general.organizations.actions;
const { fetchEmployeesDashboardData } = Logic.ba.employees.actions;
const { 
  getCompletedCollections,
  getCollections,
  getCurrentCollection,
  getUpcomingCalendar,
  fetchContent,
  resetCollectionsReducer,
  getTutorialCollection
} = Logic.employee.collections.actions;

export default class BARoutes extends Component {
  constructor (props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount () {
    const { session } = this.props;
    const isAuthenticatedUser = session.checked && session.authenticated && (session.user && true);
    if (isAuthenticatedUser) {
      this.props.fetchInitialData();
    }
  }

  render () {
    const { session } = this.props;
    return (
      <Switch>
        <Route
          exact
          path='/dashboard'
          render={() => {
            return <Redirect to={'/ba/dashboard'} key={'redirect-ba-'}/>;
          }}
        />
        <RouteWrapperData
          exact
          path='/profile'
          component={pages.General.Profile}
          routeId='BA-PROFILE'
          key={'ba-profile-'}
          session={session}
          dataFetch
          dataActions={
            [
            ]
          }
        />
        <Route
          exact
          path='/'
          render={() => {
            return <Redirect to={'/ba/dashboard'} key={'redirect-ba-'}/>;
          }}
        />
        <RouteWrapperData
          exact
          path='/ba/dashboard'
          component={pages.BA.Dashboard}
          routeId='BA-DASHBOARD'
          key={'ba-dashboard-'}
          session={session}
          dataFetch
          dataActions={
            [
              // { action: fetchOrganization, data: { routeData: { orgId: session.user.organization } } }
              { action: fetchEmployeesDashboardData, data: { } }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/ba/company-progress/:orgId'
          component={pages.BA.CompanyProgress}
          routeId='BA-COMPANY-PROGRESS'
          key={'ba-company-progress-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchOrganization, data: { } },
              { action: fetchEmployeesDashboardData, data: { } },
              { action: getCollections, data: { } }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/ba/company-profile'
          component={pages.BA.CompanyProfile}
          routeId='BA-COMPANY-PROFILE'
          key={'ba-company-profile-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchOrganization, data: { routeData: { orgId: session.user.organization } } }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/ba/employees'
          component={pages.BA.Employees}
          routeId='BA-Employees'
          key={'ba-employees-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchEmployeesDashboardData, data: { } }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/ba/employees/:employeeId'
          component={pages.BA.Employee}
          routeId='BA-Employee'
          key={'ba-employee-' + Math.random()}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchEmployeesDashboardData, data: { } },
              { action: getCompletedCollections, data: { } },
              { action: getCollections, data: { } }
            ]
          }
        />
        {/* --- BA content tutorial logic goes below --- */}
        <RouteWrapperData
          exact
          path='/ba/training'
          component={pages.Employee.Dashboard}
          routeId='EMPLOYEE-DASHBOARD'
          key={'employee-dashboard-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: getCurrentCollection, data: { } },
              { action: getTutorialCollection, data: { } }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/ba/calendar'
          component={pages.Employee.Calendar}
          routeId='BA-CALENDAR'
          key={'ba-calendar-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: getUpcomingCalendar, data: { } },
              { action: getTutorialCollection, data: { } }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/ba/completed'
          component={pages.Employee.Completed}
          routeId='BA-COMPLETED'
          key={'ba-completed-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: getCompletedCollections, data: { } }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/ba/collections/:collection/content-view'
          component={pages.Employee.ContentView}
          routeId='BA-CONTENT-VIEW'
          key={'ba-content-view'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: getCurrentCollection, data: { } }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/ba/content/past-view'
          component={pages.Employee.PastContentView}
          routeId='BA-CONTENT-VIEW'
          key={'ba-content-view'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchContent, data: { } }
            ]
          }
          unmountActions={[
            resetCollectionsReducer
          ]}
        />
        <RouteWrapperData
          exact
          path='/ba/content/tutorial-view'
          component={pages.Employee.TutorialView}
          routeId='BA-TUTORIAL-VIEW'
          key={'ba-tutorial-view'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchContent, data: { } },
              { action: getTutorialCollection, data: { } }
            ]
          }
          unmountActions={[
            resetCollectionsReducer
          ]}
        />
        {/* --- and ends here --- */}
        <RouteWrapperData
          exact
          path='/logout'
          component={pages.General.Logout}
          routeId='LOG_OUT'
          session={session}
          key={'logout-auth'}
          dataFetch
          dataActions={
            [
              { action: sessionLogout, data: { } }
            ]
          }
        />
        <Route
          exact
          path='/login'
          render={() => {
            return <Redirect to={'/ba/dashboard'} key={'redirect-ba-'}/>;
          }}
        />
        <Route
          exact
          path='/register'
          render={() => {
            return <Redirect to={'/ba/dashboard'} key={'redirect-ba-'}/>;
          }}
        />
        <Route render={() => { return (<pages.General.NotFound />); }}/>
      </Switch>
    );
  }
}
