import * as actions from './actions';

const initialState = {
  collections: [],
  currentCollection: null,
  contents: [],
  upcomingCalendar: [],
  completedCollections: [],
  tutorialCollection: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_COLLECTIONS_SUCCESS:
    case actions.GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: action.payload
      };
    case actions.FETCH_CONTENT_SUCCESS:
      return {
        ...state,
        contents: [...state.contents.filter(content => content.id !== action.payload.id), action.payload]
      };
    case actions.CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: [action.payload, ...state.collections]
      };
    case actions.GET_CURRENT_COLLECTION_SUCCESS:
      return {
        ...state,
        currentCollection: action.payload
      };
    case actions.DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: state.collections.filter(collection => collection.id !== action.payload.id)
      };
    case actions.UPDATE_CONTENT_ORDER_SUCCESS:
      return {
        ...state,
        collections: state.collections.map(collection => collection.id === action.payload.id ? action.payload : collection),
        currentCollection: action.payload
      };
    case actions.GET_UPCOMING_CALENDAR_SUCCESS:
      return {
        ...state,
        upcomingCalendar: action.payload
      };
    case actions.GET_COMPLETED_COLLECTIONS_SUCCESS:
      return {
        ...state,
        completedCollections: action.payload
      };
    case actions.GET_TUTORIAL_COLLECTION_SUCCESS:
      return {
        ...state,
        tutorialCollection: action.payload
      };
    case actions.HANDLE_CREATE_TUTORIAL_CONTENT_COMPLETION_RECORD_SUCCESS:
      return {
        ...state,
        tutorialCollection: action.payload
      };
    case actions.RESET_COLLECTIONS_REDUCER:
      return initialState;
    default:
      return state;
  }
};
