import { default as IsLoading } from './isLoading';
import { default as Logout } from './logout';
import { default as NotFound } from './notFound';
import { default as Profile } from './profile';

export default {
	IsLoading,
  Logout,
  NotFound,
  Profile
};