import React from 'react';
import { LayoutProvider } from '../../layouts';
// import { Row } from 'antd';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CustomGrid } from '../../components/grid';
import { ContentCard } from '../../components/contentCard';
// import { Table, Avatar } from 'antd';
// import Logic from '../../../logic';
import { stringBreakpoints } from '../../../app/constants/breakpoints';
import styled from 'styled-components';

const OrganizationStyleWrapper = styled.div`
  .ant-divider {
    margin: 5px 0px;
  }
  .invite-form {
    margin-top: 20px;
  }
  .current-month-content {
    background-color: #F2F2F2;
    padding: 10vh 40vh;
    &__title {
      font-family: Times New Roman;
    }
    &__subtitle {
      margin-bottom: 20px;
    }
  }
  .tutorial-content {
    padding: 10vh 40vh;
    &__title {
      font-family: Times New Roman;
    }
    &__subtitle {
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    .current-month-content,
    .tutorial-content {
      padding-left: 2.5vh;
      padding-right: 2.5vh;
    }
  }
`;

const MainWrapper = styled.div`
  
`;
const LeftColumn = styled.div`
  width: 100%;
`;


export const Dashboard = () => {
  const currentCollection = useSelector((state) => state.containers['employee::collections'].currentCollection);
  const contents = useSelector((state) => state.containers['employee::collections'].contents);
  const tutorialCollection = useSelector((state) => state.containers['employee::collections'].tutorialCollection);
  const user = useSelector((state) => state.session.user);
  // const dispatch = useDispatch();
  return (
    <LayoutProvider 
      type='internal' 
      noGutter
      backgroundImageStyle={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-bins.png')"}}
      pageTitle={'Training Content'}
    >
      <OrganizationStyleWrapper>
        
        {
          tutorialCollection && tutorialCollection.contentCompletion && !tutorialCollection.contentCompletion.isDone ?
          <div className={'tutorial-content'}>
            <h1 className={'tutorial-content__title'}>Tutorial Documents</h1>
            <div className={'tutorial-content__subtitle'}>Please review and complete the following pieces of content that have been assigned for your review.</div>
            <MainWrapper>
            <LeftColumn>
              <div>
                {/* <h3 style={{ fontSize: '2em', fontWeight: 'normal' }}>{currentCollection.title}</h3> */}
                <CustomGrid>
                  {tutorialCollection.contents.map(content => (
                    <ContentCard
                      coverImage={content.picture && content.picture.link}
                      description={content.description}
                      tags={['Tutorial']}
                      title={content.title}
                      key={content.id}
                      contentId={content.id}
                      collectionId={tutorialCollection.id}
                      bordered
                      role={user.role}
                      tutorial={true}
                    />
                  ))}
                </CustomGrid>
              </div>
            </LeftColumn>
            </MainWrapper>
          </div>
          :
          <div className={'current-month-content'}>
            <h1 className={'current-month-content__title'}>This Month&apos;s Content</h1>
            <div className={'current-month-content__subtitle'}>Please review and complete the following pieces of content that have been assigned for your review.</div>
            <MainWrapper>
              {currentCollection && (
                <React.Fragment>
                  <LeftColumn>
                    <div>
                      {/* <h3 style={{ fontSize: '2em', fontWeight: 'normal' }}>{currentCollection.title}</h3> */}
                      <CustomGrid>
                        {contents.sort((a, b) => (a.id > b.id) ? 1 : -1).map(content => (
                          <ContentCard
                            coverImage={content.picture && content.picture.link}
                            description={content.description}
                            tags={currentCollection.relatedContentCompletion.contents.indexOf(content.id) > -1 ? ['Complete'] : content.tags}
                            title={content.title}
                            key={content.id}
                            contentId={content.id}
                            collectionId={currentCollection.collection.id}
                            bordered
                            role={user.role}
                          />
                        ))}
                      </CustomGrid>
                    </div>
                  </LeftColumn>
                </React.Fragment>
              )}
            </MainWrapper>
          </div>
        }
      </OrganizationStyleWrapper>
    </LayoutProvider>
  );
};

export default withRouter(Dashboard);
