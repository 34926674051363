const ROOT = 'USERS';

export const FETCH = `${ROOT}/FETCH`;
export const FETCH_SUCCESS = `${ROOT}/FETCH_SUCCESS`;
export const FETCH_ERROR = `${ROOT}/FETCH_ERROR`;

export const FETCH_ONE = `${ROOT}/FETCH_ONE`;
export const FETCH_ONE_SUCCESS = `${ROOT}/FETCH_ONE_SUCCESS`;
export const FETCH_ONE_ERROR = `${ROOT}/FETCH_ONE_ERROR`;

export const SAVE = `${ROOT}/SAVE`;
export const SAVE_SUCCESS = `${ROOT}/SAVE_SUCCESS`;
export const SAVE_ERROR = `${ROOT}/SAVE_ERROR`;

export const UPDATE = `${ROOT}/UPDATE`;
export const UPDATE_SUCCESS = `${ROOT}/UPDATE_SUCCESS`;
export const UPDATE_ERROR = `${ROOT}/UPDATE_ERROR`;

export const DELETE = `${ROOT}/DELETE`;
export const DELETE_SUCCESS = `${ROOT}/DELETE_SUCCESS`;
export const DELETE_ERROR = `${ROOT}/DELETE_ERROR`;

export const INVITE = `${ROOT}/INVITE`;
export const INVITE_SUCCESS = `${ROOT}/INVITE_SUCCESS`;
export const INVITE_ERROR = `${ROOT}/INVITE_ERROR`;

export const RESET_PASSWORD = `${ROOT}/RESET_PASSWORD`;
export const RESET_PASSWORD_SUCCESS = `${ROOT}/RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_ERROR = `${ROOT}/RESET_PASSWORD_ERROR`;

export const FETCH_INITIAL_DATA_BEGIN = `${ROOT}/FETCH_INITIAL_DATA_BEGIN`;
export const FETCH_INITIAL_DATA = `${ROOT}/FETCH_INITIAL_DATA`;
export const FETCH_INITIAL_DATA_SUCCESS = `${ROOT}/FETCH_INITIAL_DATA_SUCCESS`;
export const FETCH_INITIAL_DATA_ERROR = `${ROOT}/FETCH_INITIAL_DATA_ERROR`;

export const UPDATE_SESSION_USER = `${ROOT}/UPDATE_SESSION_USER`;
export const UPDATE_SESSION_USER_SUCCESS = `${ROOT}/UPDATE_SESSION_USER_SUCCESS`;
export const UPDATE_SESSION_USER_ERROR = `${ROOT}/UPDATE_SESSION_USER_ERROR`;

export const UPDATE_SESSION_USER_PASSWORD = `${ROOT}/UPDATE_SESSION_USER_PASSWORD`;
export const UPDATE_SESSION_USER_PASSWORD_SUCCESS = `${ROOT}/UPDATE_SESSION_USER_PASSWORD_SUCCESS`;
export const UPDATE_SESSION_USER_PASSWORD_ERROR = `${ROOT}/UPDATE_SESSION_USER_PASSWORD_ERROR`;

export const CHECK_INVITED_STATUS = `${ROOT}/CHECK_INVITED_STATUS`;
export const CHECK_INVITED_STATUS_SUCCESS = `${ROOT}/CHECK_INVITED_STATUS_SUCCESS`;
export const CHECK_INVITED_STATUS_ERROR = `${ROOT}/CHECK_INVITED_STATUS_ERROR`;

export const COMPLETE_INVITE_SETUP = `${ROOT}/COMPLETE_INVITE_SETUP`;
export const COMPLETE_INVITE_SETUP_SUCCESS = `${ROOT}/COMPLETE_INVITE_SETUP_SUCCESS`;
export const COMPLETE_INVITE_SETUP_ERROR = `${ROOT}/COMPLETE_INVITE_SETUP_ERROR`;

export const GET_ORGANIZATION_MEMBERS = `${ROOT}/GET_ORGANIZATION_MEMBERS`;
export const GET_ORGANIZATION_MEMBERS_SUCCESS = `${ROOT}/GET_ORGANIZATION_MEMBERS_SUCCESS`;
export const GET_ORGANIZATION_MEMBERS_ERROR = `${ROOT}/GET_ORGANIZATION_MEMBERS_ERROR`;

export const UPDATE_USER_PROFILE_IMAGE = `${ROOT}/UPDATE_USER_PROFILE_IMAGE`;
export const UPDATE_USER_PROFILE_IMAGE_SUCCESS = `${ROOT}/UPDATE_USER_PROFILE_IMAGE_SUCCESS`;
export const UPDATE_USER_PROFILE_IMAGE_ERROR = `${ROOT}/UPDATE_USER_PROFILE_IMAGE_ERROR`;

export const UPDATE_USER_ID_IMAGE = `${ROOT}/UPDATE_USER_ID_IMAGE`;
export const UPDATE_USER_ID_IMAGE_SUCCESS = `${ROOT}/UPDATE_USER_ID_IMAGE_SUCCESS`;
export const UPDATE_USER_ID_IMAGE_ERROR = `${ROOT}/UPDATE_USER_ID_IMAGE_ERROR`;

export const GET_USER_ID_IMAGE = `${ROOT}/GET_USER_ID_IMAGE`;
export const GET_USER_ID_IMAGE_SUCCESS = `${ROOT}/GET_USER_ID_IMAGE_SUCCESS`;
export const GET_USER_ID_IMAGE_ERROR = `${ROOT}/GET_USER_ID_IMAGE_ERROR`;

export const FINISH_USER_REGISTRATION = `${ROOT}/FINISH_USER_REGISTRATION`;
export const FINISH_USER_REGISTRATION_SUCCESS = `${ROOT}/FINISH_USER_REGISTRATION_SUCCESS`;
export const FINISH_USER_REGISTRATION_ERROR = `${ROOT}/FINISH_USER_REGISTRATION_ERROR`;

export const ROLE_ACTION = `${ROOT}/ROLE_ACTION`;
export const ROLE_ACTION_SUCCESS = `${ROOT}/ROLE_ACTION_SUCCESS`;
export const ROLE_ACTION_ERROR = `${ROOT}/ROLE_ACTION_ERROR`;

export function fetchOne (payload) {
  return {
    type: FETCH_ONE,
    payload,
    authCall: true
  };
}

export function save (payload) {
  return {
    type: SAVE,
    payload,
    authCall: true
  };
}

export function updateSessionUser (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/users/${payload.id}`,
      method: 'PUT',
      params: {},
      query: {},
      body: payload,
      auth: null
    },
    success: {
      actions: [UPDATE_SESSION_USER_SUCCESS]
    },
    error: {
      actions: [UPDATE_SESSION_USER_ERROR]
    }

  };
}

export function destroy (payload) {
  return {
    type: DELETE,
    payload,
    authCall: true
  };
}

export function inviteUser (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/users/admin/invite`,
      method: 'POST',
      params: {},
      query: {},
      body: payload,
      auth: null
    },
    success: {
      actions: [INVITE_SUCCESS]
    },
    error: {
      actions: [INVITE_ERROR]
    }
  };
}

export function fetchInitialData (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/users/actions/getInitialData`,
      method: 'GET',
      params: {},
      query: {},
      body: payload,
      auth: null
    },
    success: {
      actions: [FETCH_INITIAL_DATA_SUCCESS]
    },
    error: {
      actions: [FETCH_INITIAL_DATA_ERROR]
    }
  };
}

export function updateUserProfileImage (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/users/${payload.id}/actions/updateProfileImage`,
      method: 'POST',
      params: {},
      query: {},
      body: payload,
      auth: null
    },
    success: {
      actions: [UPDATE_USER_PROFILE_IMAGE_SUCCESS]
    },
    error: {
      actions: [UPDATE_USER_PROFILE_IMAGE_ERROR]
    }
  };
}

export function updateUserIdImage (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/users/${payload.id}/actions/updateIdImage`,
      method: 'POST',
      params: {},
      query: {},
      body: payload,
      auth: null
    },
    success: {
      actions: [UPDATE_USER_ID_IMAGE_SUCCESS]
    },
    error: {
      actions: [UPDATE_USER_ID_IMAGE_ERROR]
    }
  };
}

export function getUserIdImage () {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/users/actions/getIdImage`,
      method: 'GET',
      params: {},
      query: {},
      body: {},
      auth: null
    },
    success: {
      actions: [GET_USER_ID_IMAGE_SUCCESS]
    },
    error: {
      actions: [GET_USER_ID_IMAGE_ERROR]
    }
  };
}

export function finishUserRegistration (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: false,
      master: false,
      route: `/users/actions/finishRegistration`,
      method: 'PUT',
      params: {},
      query: {
        access_token: payload.invite_token
      },
      body: payload,
      auth: null
    },
    success: {
      actions: [FINISH_USER_REGISTRATION_SUCCESS]
    },
    error: {
      actions: [FINISH_USER_REGISTRATION_ERROR]
    }
  };
}

export function roleAction (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/users/${payload.user}/roleAction`,
      method: 'PUT',
      params: {},
      query: null,
      body: {
        role: payload.role
      },
      auth: null
    },
    success: {
      actions: [ROLE_ACTION_SUCCESS]
    },
    error: {
      actions: [ROLE_ACTION_ERROR]
    }
  };
}

export function deleteUser (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: false,
      route: `/users/${payload.user}`,
      method: 'DELETE',
      params: {},
      query: null,
      body: {},
      auth: null
    },
    success: {
      actions: [DELETE_SUCCESS]
    },
    error: {
      actions: [DELETE_ERROR]
    }
  };
}

export function resetPassword (payload) {
  return {
    type: RESET_PASSWORD,
    payload,
    authCall: true
  };
}

export function fetchInitialDataBegin (payload) {
  return {
    type: FETCH_INITIAL_DATA_BEGIN,
    payload,
    authCall: true
  };
}


export function updateSessionUserPassword (payload) {
  return {
    type: UPDATE_SESSION_USER_PASSWORD,
    payload,
    authCall: true
  };
}

export function checkInvitedStatus (payload) {
  return {
    type: CHECK_INVITED_STATUS,
    payload
  };
}

export function completeInviteSetup (payload) {
  return {
    type: COMPLETE_INVITE_SETUP,
    payload
  };
}

export function getOrganizationMembers (payload) {
  return {
    type: GET_ORGANIZATION_MEMBERS,
    payload
  };
}
