import React, { useState } from 'react';
import { LayoutProvider } from '../../layouts';
import { Row, Col, Button, Form, Input, Icon } from 'antd';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { Table, Avatar } from 'antd';
import Logic from '../../../logic';
import styled from 'styled-components';
import { EyeOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const LoginStyleWrapper = styled.div`
  .login {
    &__welcome {
      font-family: Times New Roman;
      font-size: 30px;
      color: green;
    }
  }
`;

const StyledPasswordInputWrapper = styled.div`
  .ant-input-group-addon {
    background-color: ${props => props.type === 'password' ? '#fafafa' : '#5BBA6F'};
    color: ${props => props.type === 'password' ? 'black' : 'white'};
  }
`;

export const Register = () => {
  const dispatch = useDispatch();
  // const [form] = Form.useForm();

  const handleLogin = (values) => {
    dispatch(Logic.general.session.actions.sessionLogin(values));
  };

  const handleToggleViewPassword = () => {
    let newValue = pwInputType === 'password' ? 'text' : 'password';
    setPwInputType(newValue);
  };

  const [pwInputType, setPwInputType] = useState('password');

  return (
    <LayoutProvider type='external' >
      <LoginStyleWrapper>
      <div>
        <div className={'login'}>
          <div style={{paddingBottom: '20px'}}>
            <h3 className={'login__welcome'}>Welcome</h3>
            <div>Please Log In</div>
          </div>
          <Form onFinish={(values) => { handleLogin(values); }} onFailure={(error) => { this.handleFormFailure(error); }} className="login-form">
            <FormItem 
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email Address" />
            </FormItem>
            <FormItem
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <StyledPasswordInputWrapper type={pwInputType}>
                <Input size='large' addonAfter={<EyeOutlined onClick={() => { handleToggleViewPassword(); }}/>} type={pwInputType} placeholder="Password" />
              </StyledPasswordInputWrapper>
            </FormItem>
            <Row>
              <Col span={10}>
                <div><em onClick={() => { dispatch(Logic.general.router.actions.navigateToRoute({to: '/forgot', replace: false})); }}>Forgot Password? <a>Reset It Here</a></em></div>
              </Col>
              <Col span={14} style={{textAlign: 'right'}}>
                <Button size='large' htmlType="submit" className="login-form-button" style={{borderRadius: '0px', cursor: 'pointer',  zIndex: '100', backgroundColor: '#5BBA6F', color: 'white'}}>
                  Sign In
                </Button>
              </Col>
            </Row>
            <div style={{marginTop: '10vh'}}>
              <Button style={{ backgroundColor: 'grey', color: 'white' }} block onClick={() => { dispatch(Logic.general.router.actions.navigateToRoute({to: '/account/create', replace: false})); }}>Create A Business Account</Button>
            </div>
          </Form>
        </div>
      </div>
      </LoginStyleWrapper>
    </LayoutProvider>
  );
};

export default withRouter(Register);
