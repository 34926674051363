/* eslint-disable react/jsx-no-target-blank */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import withSizes from 'react-sizes';
import { Link } from 'react-router-dom';

// CONSTANTS
import { USER_ROLES } from '../../../constants/user';
import { numberBreakpoints } from '../../../constants/breakpoints';

// COMPONENTS
import { Layout, Popover, Avatar, Button } from 'antd';
import {
  MenuOutlined,
  HomeFilled,
  AppstoreOutlined,
  // MailOutlined,
  CalendarFilled,
  CheckOutlined,
  DashboardOutlined
} from '@ant-design/icons';
import { WfiaDrawer } from '../../../components/wfiaDrawer';

// STYLES
import TopbarWrapper from '../../../styles/topbar.style';
import TopbarDropdownWrapper from './topbarDropdown.style';

import Logic from '../../../../logic';

const { Header } = Layout;
const { toggleCollapsed, toggleFilterDrawer } = Logic.general.app.actions;
const { navigateToRoute } = Logic.general.router.actions;
const { sessionLogout } = Logic.general.session.actions;

const BurgerMenu = styled(MenuOutlined)`
  cursor: pointer;
  font-size: 2em;
  margin-right: 25px;
`;

const LinksList = styled.ul`
  padding: 0;
  padding-top: 24px;

  list-style-type: none;
  display: 'block';
`;

LinksList.Item = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${p => p.isActive ? '#5bbb6f' : 'transparent'};
  cursor: pointer;
  padding: 14px 28px;
  margin-bottom: 5px; 
`;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vpcModalVisible: false
    };
  }

  toggleModal(modalType) {
    this.setState({ [modalType]: !this.state[modalType] });
  }

  drawerMenuLinks = {
    [USER_ROLES.BA]: [
      {
        Icon: HomeFilled,
        title: 'Dashboard',
        route: '/ba/dashboard'
      },
      {
        Icon: CalendarFilled,
        title: 'Business Profile',
        route: '/ba/company-profile'
      },
      {
        Icon: CheckOutlined,
        title: 'Manager Training Content',
        route: '/ba/training'
      },
      {
        Icon: CalendarFilled,
        title: 'Monthly Training Calendar',
        route: '/ba/calendar'
      },
      {
        Icon: CheckOutlined,
        title: 'Completed Training Content',
        route: '/ba/completed'
      }
    ],
    [USER_ROLES.EMPLOYEE]: [
      {
        Icon: HomeFilled,
        title: 'Training Content',
        route: '/employee/dashboard'
      },
      {
        Icon: CalendarFilled,
        title: 'Monthly Training Calendar',
        route: '/employee/calendar'
      },
      {
        Icon: CheckOutlined,
        title: 'Completed Training Content',
        route: '/employee/completed'
      }
    ],
    [USER_ROLES.MA]: [
      {
        Icon: HomeFilled,
        title: 'Dashboard',
        route: '/ma/dashboard'
      },
      {
        Icon: AppstoreOutlined,
        title: 'Training Content Collections',
        route: '/ma/collections'
      }
    ]
  }

  render() {
    const { session, toggleCollapsed, navigateToRoute, isMobile } = this.props;

    const styling = {
      background: 'white',
      position: 'fixed',
      width: '100%',
      height: 70,
      padding: '0 31px 0 30px !important'
    };

    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <Link className="isoDropdownLink" to={'/profile'}>
          My Profile
        </Link>
        <a onClick={() => this.props.sessionLogout()} className="isoDropdownLink">
          Logout
        </a>
      </TopbarDropdownWrapper>
    );

    const iconStyles = { padding: 8, borderRadius: '50%', background: 'white', border: '1px solid white', marginRight: 12 };
    const shouldShowMenu = session.user && session.user.role;

    const wfiaLogo = isMobile ? 'https://wfia-dev.s3-us-west-2.amazonaws.com/img/wfia-mobile.png' : 'https://wfia-dev.s3-us-west-2.amazonaws.com/img/wfia.png';
    const shipLogo = isMobile ? 'https://wfia-dev.s3-us-west-2.amazonaws.com/img/ship-mobile.png' : 'https://wfia-dev.s3-us-west-2.amazonaws.com/img/ship.png';

    const logosContent = (
      <Fragment>
        <a href='https://www.wa-food-ind.org/' target={'blank'}>
          <img
            className="isomorphicTopbar__wfia-logo"
            src={wfiaLogo}
            style={{ height: '90%', marginRight: '25px' }}
          />
        </a>
        <a href='https://lni.wa.gov/safety-health/grants-committees-partnerships/safety-health-investment-projects-grant-program/' target={'blank'}>
          <img
            className="isomorphicTopbar__ship-logo" 
            src={shipLogo}
            style={{ height: '90%' }}
          />
        </a>
      </Fragment>
    );

    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className="isomorphicTopbar"
        >
          <div className="isomorphicTopbar__left-wrapper" style={{ padding: 10, height: '100%', width: '100%' }}>
            {shouldShowMenu && (
              <BurgerMenu onClick={() => toggleCollapsed(true)} />
            )}
            {isMobile ? <div className="isomorphicTopbar__logos">{logosContent}</div> : logosContent}
          </div>

          <ul className="isoRight" style={{ padding: 10, height: '100%' }}>
            {
              !isMobile &&
              <span className={'dashboard-button'} style={{marginRight: '3vw'}}>
                <Button icon={<DashboardOutlined/>} onClick={() => { this.props.navigateToRoute({ to: '/', replace: false }); }}>Dashboard</Button>
              </span>  
            }
            
            <Popover
              content={content}
              trigger="click"
              arrowPointAtCenter={true}
              placement="bottomLeft"
            >
              <Avatar size='large' style={{ cursor: 'pointer' }} alt="user" src={session.user.picture ? session.user.picture.link : 'https://invigor-dev.s3-us-west-2.amazonaws.com/user1.png'} />
            </Popover>
          </ul>
        </Header>
        <WfiaDrawer visible={this.props.collapsed} onClose={() => toggleCollapsed(false)}>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
            <LinksList>
              {shouldShowMenu && this.drawerMenuLinks[session.user.role].map(({ Icon, title, route }) => {
                const isActive = window.location.pathname === route;
                return (
                  <LinksList.Item
                    key={title}
                    onClick={!isActive ? () => { toggleCollapsed(); setTimeout(() => navigateToRoute({ to: route, replace: false }), 450); } : toggleCollapsed}
                    isActive={isActive}
                  >
                    <Icon style={iconStyles} />
                    <p style={{ color: 'white' }}>{title}</p>
                  </LinksList.Item>
                );
              })}
            </LinksList>
            <div>
              
            </div>
          </div>
        </WfiaDrawer>
      </TopbarWrapper>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.xs,
});

export default connect(
  state => ({
    ...state.App,
    collapsed: state.containers['general::app'].collapsed,
    filterDrawerOpen: state.containers['general::app'].filterDrawerOpen,
    session: state.session,
  }),
  { toggleCollapsed, toggleFilterDrawer, navigateToRoute, sessionLogout }
)(withSizes(mapSizesToProps)(Topbar));
