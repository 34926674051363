import { call, put, takeEvery, take, takeLatest, delay } from 'redux-saga/effects';
import sessionAPI from './api';
import * as routerActions from '../router/actions';
// import * as formActions from '../../patient/forms/actions';
import * as actions from './actions';
import INVITE_TOKEN_STATUSES_TRANSLATIONS from '../../../app/constants/invite-token-statuses';
import { notification } from 'antd';
import { sessionService } from 'redux-react-session';

function * sessionRefreshSaga () {
  try {
    const response = yield call(sessionAPI.refreshAuthToken, {});
    const { user, token } = response;

    sessionService
      .saveSession(token);

    sessionService
      .saveUser({...user, token});

    yield put({
      type: 'SESSION_REFRESH_SUCCESS',
      payload: {}
    });

  } catch (e) {
    yield put({
      type: 'SESSION_REFRESH_ERROR',
      payload: e
    });
  }
}

function * sessionLogoutSaga () {
  try {
    const check = yield call(sessionAPI.logout);
    if (check) {
      yield take('@@redux-react-session/GET_USER_SESSION_ERROR');
      yield delay(1000);
      yield put(routerActions.navigateToRoute({ to: '/', replace: false }));
    } else {
      throw new Error('Error Occured');
    }
  } catch (e) {
    yield put({
      type: 'SESSION_LOGOUT_ERROR',
      payload: e
    });
  }
}

function * confirmUserEmailSaga (action) {
  try {
    const check = yield call(sessionAPI.confirmUserEmail, action.payload);
    if (check) {
      yield put({
        type: 'CONFIRM_USER_EMAIL_SUCCESS'
      });
    } else {
      throw new Error('Error Occured');
    }
  } catch (e) {
    yield put({
      type: 'SESSION_LOGOUT_ERROR',
      payload: e
    });
  }
}

function * getActivateStatus (action) {
  try {
    const response = yield call(sessionAPI.getActivateStatus, action.payload.signupCode, action.payload.sid);
    if (response.message === 'User already active') {
      notification['success']({
        message: 'Your account is already active.',
        description: 'Please login.'
      });
      yield put(routerActions.navigateToRoute({ to: '/login', replace: false }));
    }
    yield put({
      type: 'SESSION_GET_ACTIVATE_STATUS_SUCCESS',
      payload: response
    });
  } catch (e) {
    yield put({
      type: 'SESSION_GET_ACTIVATE_STATUS_ERROR',
      payload: e
    });
  }
}

function * validateVendor (action) {
  try {
    const response = yield call(sessionAPI.validate, action.payload);
    if (response.user.status == 'active') {
      notification['success']({
        message: 'Your account is active.',
        description: 'Please login.'
      });
      yield put(routerActions.navigateToRoute({ to: '/login', replace: false }));
    }
    yield put({
      type: 'VALIDATE_VENDOR_SUCCESS',
      payload: response
    });
  } catch (e) {
    yield put({
      type: 'VALIDATE_VENDOR_ERROR',
      payload: e
    });
  }
}

function * forgotPasswordSuccessRoute () {
  try {
    yield put(routerActions.navigateToRoute({ to: '/login', replace: false }));
    notification['success']({
      message: `Password Reset Successfully Sent`,
      description: `Please check your email for reset instructions.`
    });
  } catch (e) {
    console.log(e);
  }
}

function * resetPasswordRoute () {
  try {
    yield put(routerActions.navigateToRoute({ to: '/login', replace: false }));
    notification['success']({
      message: `Password Reset Successful`,
      description: `You can now sign in with your new password.`
    });
  } catch (e) {
    notification['error']({
      message: `Password Reset Unsuccessful`,
      description: `Looks like your reset token has expired. Please request a new reset token and try again.`
    });
  }
}

function * handleGenerateAuthUrlSuccessRoute (action) {
  try {
    window.location.replace(action.payload.url); 
    yield put({
      type: 'SESSION/REDIRECT',
      payload: action.payload
    });   
  } catch (e) {
    notification['error']({
      message: `Password Reset Unsuccessful`,
      description: `Looks like your reset token has expired. Please request a new reset token and try again.`
    });
  }
}

function * handleLoginGoogleUserSuccessRoute (action) {
  try {
    if (action.payload.user.hasOwnProperty('token')) {
      const { user, token } = action.payload.user;

      sessionService
        .saveSession(token);

      sessionService
        .saveUser({...user, token});
    } 
    yield put({
      type: 'SESSION_LOGIN_SUCCESS',
      payload: action.payload
    });  
  } catch (e) {
    notification['error']({
      message: `Password Reset Unsuccessful`,
      description: `Looks like your reset token has expired. Please request a new reset token and try again.`
    });
  }
}

function * handleUserLoginSuccessRoute (action) {
  try {
    const { user, token } = action.payload;
    if (action.payload.hasOwnProperty('token')) {
      sessionService
        .saveSession(token);

      sessionService
        .saveUser({...user, token});
    }
    yield take('@@redux-react-session/GET_USER_SESSION_SUCCESS');
    yield put(routerActions.navigateToRoute({ to: '/', replace: false }));
  } catch (e) {
    notification['error']({
      message: `Register Unsuccessful`,
      description: `Looks like something went wrong.`
    });
  }
}

function * handleSessionRegisterSuccessRoute (action) {
  try {
    yield notification['success']({
      message: `Successfully Created Business Account!`,
      description: `You are now logged in.`
    });
    const { user, token } = action.payload;
    if (action.payload.hasOwnProperty('token')) {
      sessionService
        .saveSession(token);

      sessionService
        .saveUser({...user, token});
    }
    yield take('@@redux-react-session/GET_USER_SESSION_SUCCESS');
    yield put(routerActions.navigateToRoute({ to: '/', replace: false }));
  } catch (e) {
    notification['error']({
      message: `Register Unsuccessful`,
      description: `Looks like something went wrong.`
    });
  }
}

function* handeInviteTokenCheck({ payload }) {
  const relatedTranslation = INVITE_TOKEN_STATUSES_TRANSLATIONS[payload.status];

  if (!relatedTranslation) {
    return;
  }
  
  yield notification[relatedTranslation.notificationMode](relatedTranslation.notificationBody);
}

function* handleUserLoginErrorRoute() {
  yield notification['error']({
    message: `Login Unsuccessful`,
    description: `Please try again.`
  });
}

function* handleSessionUpdatePasswordSuccessRoute() {
  yield notification['success']({
    message: `Successfully Updated Password!`,
    description: `Please use the new password from now on.`
  });
}

function* handleSessionUpdatePasswordErrorRoute() {
  yield notification['error']({
    message: `Not able to update password`,
    description: `Please verify your current password`
  });
}

function * sessionSagas () {
  yield takeLatest(actions.SESSION_REFRESH, sessionRefreshSaga);
  yield takeLatest('SESSION_LOGOUT', sessionLogoutSaga);
  yield takeEvery('CONFIRM_USER_EMAIL', confirmUserEmailSaga);
  yield takeEvery('GET_ACTIVATE_STATUS', getActivateStatus);
  yield takeEvery('VALIDATE_VENDOR', validateVendor);
  yield takeEvery(actions.FORGOT_PASSWORD_SUCCESS, forgotPasswordSuccessRoute);
  yield takeEvery(actions.RESET_PASSWORD_SUCCESS, resetPasswordRoute);
  yield takeEvery(actions.GENERATE_AUTH_URL_SUCCESS, handleGenerateAuthUrlSuccessRoute);
  yield takeEvery(actions.GET_GOOGLE_ACCESS_TOKEN, handleGenerateAuthUrlSuccessRoute);
  yield takeEvery(actions.LOGIN_GOOGLE_USER_SUCCESS, handleLoginGoogleUserSuccessRoute);
  yield takeEvery('SESSION_REGISTER_SUCCESS', handleSessionRegisterSuccessRoute);
  yield takeEvery('SESSION_LOGIN_SUCCESS', handleUserLoginSuccessRoute);
  yield takeEvery('SESSION_LOGIN_ERROR', handleUserLoginErrorRoute);
  yield takeEvery(actions.CHECK_INVITE_TOKEN_SUCCESS, handeInviteTokenCheck);
  yield takeEvery(actions.SESSION_REGISTER_ERROR, handeInviteTokenCheck);
  yield takeEvery(`USERS/FINISH_USER_REGISTRATION_SUCCESS`, handleUserLoginSuccessRoute);
  yield takeEvery(actions.SESSION_UPDATE_PASSWORD_SUCCESS, handleSessionUpdatePasswordSuccessRoute);
  yield takeEvery(actions.SESSION_UPDATE_PASSWORD_ERROR, handleSessionUpdatePasswordErrorRoute);
}

export default sessionSagas;
