import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';

const GlobalSpinner = ({ children, isFetching }) => (
  <Spin style={{ zIndex: Number.MAX_SAFE_INTEGER }} spinning={isFetching}>
    {children}
  </Spin>
);

const mapStateToProps = (state) => ({
  isFetching: state.containers['general::app'].isFetching,
});

export default connect(mapStateToProps)(GlobalSpinner);
