import React, { useRef } from 'react';
import { LayoutProvider } from '../../layouts';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col, Input, Icon, Button, Form, Avatar, notification } from 'antd';
import Logic from '../../../logic';
import withSizes from 'react-sizes';
import { stringBreakpoints, numberBreakpoints } from '../../../app/constants/breakpoints';
import { ACCEPT_IMAGES_ONLY_RULE, MAX_IMAGE_UPLOAD_SIZE_MB } from '../../constants/files';
import { PhoneInput } from '../../components/phoneInput';

const { updateOrganization } = Logic.general.organizations.actions;

const FormItem = Form.Item;
const OrganizationStyleWrapper = styled.div`
  .ant-divider {
    margin: 5px 0px;
  }
  .organization-edit {
    padding: 10vh 10vw;

    display: flex;
    justify-content: space-between;

    &__left-col {
      width: 65%;
    }
    &__right-col {
      width: 33%;
    }
  }
  .organization {
    &__image {
      width: 100%
    }
    &__action-column {
      text-align: center;
      &__edit-button {
        margin-top: 10px;
      }
    }
  }
  h1 {
    font-family: Times New Roman;
    color: #388659;
    font-size: 40px;
  }

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
  .organization-edit {
    padding: 5vh 20px;

    display: block;

    &__left-col,
    &__right-col {
      width: 100%;
    }

    &__right-col {
      margin-top: 35px;
    }
  }
  }
`;

const RightColumn = styled.div`
  min-width: 22%;
`;

RightColumn.Header = styled.h3`
  color: #549770;
  font-size: 22px;
  border-bottom: 3px solid #549770;
  margin-bottom: 25px;
`;
RightColumn.DescriptionText = styled.p`
  color: #b8b8b8;
  margin-bottom: 5px;
`;
RightColumn.ActionButton = styled.button`
  padding: 5px 23px;
  color: white;
  background: #5bba6f;
  border: none;
  box-shadow: none;
  margin-bottom: 20px;
  cursor: pointer;
`;

const ContentEdit = styled(Form)`
  padding: 31px 4% 0 0;
  width: 78%;
  margin-left: auto;
`;

ContentEdit.Container = styled.div`
  display: flex;
  justify-content: ${props => props.justify ? props.justify : 'space-between'};
  flex-direction: ${props => props.direction ? props.direction : 'row'};
  align-items: ${props => props.align ? props.align : 'initial'};
  flex-wrap: wrap;
`;
ContentEdit.Column = styled.div`
  width: ${props => props.width ? props.width : 'auto'};
`;
ContentEdit.Label = styled.div`
`;
ContentEdit.Title = styled.div`
`;
ContentEdit.CoverPhoto = styled.img`
  height: 200px;
  width: 100%;
  margin-bottom: 25px;
`;
ContentEdit.FileTypeLimitations = styled.div`
  max-width: 27.5%;
  text-align: left;
  font-family: 'Lato';
  font-size: 10px;
`;
ContentEdit.FileTypeLimitations.Header = styled.h4`
  font-size: 1em;
  margin-bottom: 5px;
`;
ContentEdit.FileTypeLimitations.Description = styled.p`
  font-size: 0.8em;
`;
ContentEdit.BottomActionBar = styled.div`
  margin-top: 20px;

`;

ContentEdit.Inputs = {};
ContentEdit.Inputs.Title = styled(Input)`
`;
ContentEdit.Inputs.Attachments = styled(Input)`
`;

ContentEdit.Buttons = {};
ContentEdit.Buttons.Green = styled.button`
  padding: 6px 18px;
  color: white;
  border: none;
  background: #5bba6f;
  cursor: pointer;
`;
ContentEdit.Buttons.Orange = styled(Button)`
  padding: 6px 18px;
  color: white;
  border: none;
  cursor: pointer;
  background: #d5733f;
`;
ContentEdit.Buttons.Grey = styled(Button)`
`;

// HELPERS
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});



export const Profile = ({ isMobile }) => {
  // const dispatch = useDispatch();
  const user = useSelector(state => state.session).user;
  const organizations = useSelector(state => state.containers['general::organizations']);
  const organization = organizations.organizations.length > 0 ? organizations.organizations.find(x => x.id === user.organization) : null;

  const profileImageRef = useRef(null);

  const dispatch = useDispatch();

  if (!organization) { return null; }


  const handleSubmitOrganization =  (values) => {
    let data = { ...values };
    dispatch(updateOrganization({ ...data, id: organization.id }));
  };

  const handleFormFailure =  (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <LayoutProvider 
      type='internal' 
      backgroundImageStyle={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-business.png')"}}
      noGutter={isMobile}
      pageTitle={`${organization.company}`}
    >
      <OrganizationStyleWrapper>
        <div className="organization-edit">  
          <div className="organization-edit__left-col" span={16}>
            <div style={{marginBottom: '5vh'}}>
              <h1>Business Profile</h1>
              <span>Edit Your Business Details and Upload an Image</span>
            </div>
            <Form layout="vertical" initialValues={organization} onFinish={(values) => { handleSubmitOrganization(values); }} onFailure={(error) => { handleFormFailure(error); }} className="login-form">
              <Row gutter={8}>
                <Col span={24}>
                  <FormItem 
                    name="company"
                    label="Business"
                    rules={[{ required: true, message: 'Please input the company name!' }]}
                  > 
                    <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Business Name" />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem 
                    name="address"
                    label="Address"
                    rules={[{ required: true, message: 'Please input your address!' }]}
                  >
                    <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Address"  />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <FormItem 
                    name="city"
                    label="City"
                    rules={[{ required: true, message: 'Please input your city!' }]}
                  >
                    <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="City"/>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem 
                    name="zipCode"
                    label="Zip Code"
                    rules={[{ required: true, message: 'Please input your zip code!' }]}
                  >
                    <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Zip Code"/>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <FormItem 
                    name="country"
                    label="Country"
                    rules={[{ required: true, message: 'Please input your country!' }]}
                  >
                    <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Country"/>
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={24}>
                  <FormItem
                    name="contactName"
                    label="Business Safety Contact Name"
                    rules={[{ required: true, message: 'Please input your safety contact name!' }]}
                  >
                    <Input size='large' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Contact Name" />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <FormItem
                    name="contactEmail"
                    label="Business Safety Contact Email"
                    rules={[{ required: true, message: 'Please input your safety contact email!' }]}
                  >
                    <Input size='large' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="email" placeholder="Contact Email" />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <FormItem
                    name="contactPhone"
                    label="Business Safety Contact Phone"
                    rules={[{ required: true, message: 'Please input your safety contact phone!' }]}
                  >
                    <PhoneInput size='large' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Contact Phone" />
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <Button size='large' htmlType="submit" className="login-form-button" style={{borderRadius: '0px', cursor: 'pointer',  zIndex: '100', backgroundColor: '#5BBA6F', color: 'white'}}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form> 
          </div>
          <div className="organization-edit__right-col" span={8}>
            <RightColumn.Header>Profile Options</RightColumn.Header>
            
            <RightColumn.Header>Profile Picture</RightColumn.Header>
            <RightColumn.DescriptionText>Select the button below to add or change your business profile picture:</RightColumn.DescriptionText>
            <div style={{textAlign: 'center', width: '100%', marginBottom: '5vh'}}>
              <Avatar size={72} src={organization.picture ? organization.picture.link : 'https://invigor-dev.s3-us-west-2.amazonaws.com/user1.png'} />
            </div>
            
            <ContentEdit.Container style={{ marginBottom: 35 }} justify="space-around" >
              <ContentEdit.Buttons.Green type="button" onClick={() => profileImageRef.current.click()}>Upload a new photo</ContentEdit.Buttons.Green>
              <input
                type="file"
                style={{ display: "none" }}
                accept={ACCEPT_IMAGES_ONLY_RULE}
                ref={profileImageRef}
                onChange={async e => {
                  const file = e.target.files[0];

                  if (file.size > MAX_IMAGE_UPLOAD_SIZE_MB * 1024 * 1024) {
                    return notification.warning({
                      message: 'File size limit exceeded!',
                      description: `Select file up to ${MAX_IMAGE_UPLOAD_SIZE_MB}MB.`
                    });
                  }

                  const convertedFile = await toBase64(file);
                  const structuredFile = ({
                    data: convertedFile,
                    fileName: file.name
                  });

                  dispatch(updateOrganization({ picture: structuredFile, id: organization.id }));
                }}
              />
            </ContentEdit.Container>
          </div>
        </div>
      </OrganizationStyleWrapper>
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.sm,
});

export default withRouter(withSizes(mapSizesToProps)(Profile));
