import axios from 'axios';
const BACKEND_URL = process.env.WFIA_API_URL;

export default class AssessmentAPI {
  static register (mail, firstname, lastname, pass, passConfirm) {
    return axios({
      method: 'POST',
      url: `${BACKEND_URL}/users`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.MASTER_TOKEN}`
      },
      data: {
        email: mail,
        password: pass,
        password_confirmation: passConfirm,
        first_name: firstname,
        last_name: lastname
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }
}
