import React from 'react';
import { LayoutProvider } from '../../layouts';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Divider } from 'antd';
// import { Table, Avatar } from 'antd';
import Logic from '../../../logic';
import styled from 'styled-components';
// import Logic from '../../../logic';
import { EditorResponsiveWrapper } from '../../components/editor';
import { stringBreakpoints } from '../../../app/constants/breakpoints';
import { EyeOutlined } from '@ant-design/icons';

const OrganizationStyleWrapper = styled.div`
  .ant-divider {
    margin: 5px 0px;
  }
  .invite-form {
    margin-top: 20px;
  }
  .current-month-content {
    padding: 10vh 20vw;
    &__title {
      font-family: Times New Roman;
    }
    &__subtitle, &__article {
      margin-bottom: 20px;
    }
  }
  .action-bar {
    padding: 2vh 20vw;
    height: 8vh; 
    background-color: #F2F2F2;
    &__link {
      color: #5BBA6F;
      font-weight: 400;
      text-align: right;
    }
  }

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    .current-month-content {
      padding: 35px 20px 0 20px;
    }
  }
`;

const FileView = ({ file }) => {
  return (
    <FileView.Wrapper>
      <FileView.FileName href={file.link} target="_blank">{file.name} <FileView.CrossIcon /></FileView.FileName>
      
    </FileView.Wrapper>
  );
};

FileView.Wrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;
FileView.FileName = styled.a`
  margin-right: 4px;
  color: black;
`;
FileView.CrossIcon = styled(EyeOutlined)`
  color: #d5733f;
  font-size: 1.3em;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

export const PastContentView = () => {
  const content = useSelector((state) => state.containers['ma::contents'].content);
  const dispatch = useDispatch();

  if (!content) { return null; }

  return (
    <LayoutProvider 
      type='internal' 
      noGutter
      backgroundImageStyle={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-bins.png')"}}
      pageTitle={content.relatedCollection.title}
    >
      <OrganizationStyleWrapper>

        {/* <div className={'action-bar'}>
         
        </div> */}
        <div className={'current-month-content'}>
          <div>
            <a 
              onClick={ () => { dispatch(Logic.general.router.actions.navigateToRoute({ to: `/ma/collections/${content.relatedCollection.id}/content-edit?content=${content.id}`, replace: false })); } }>Content Editor</a>
          </div>
          <div style={{margin: '20px 0px', fontSize: '20px'}}>
            { content && content.title }
          </div>
          <EditorResponsiveWrapper className="current-month-content__article">
            
            {(content ? content.description : '')}
          </EditorResponsiveWrapper>
          <div style={{marginTop: '5vh'}}>
            <div>
              <div>Attachments</div>
              <Divider/>
              {content['attachments'].map(attachment => (
                <FileView
                  file={attachment}
                  key={attachment.id}
                />)
              )}
            </div>
          </div>
        </div>
        
      </OrganizationStyleWrapper>
    </LayoutProvider>
  );
};

export default withRouter(PastContentView);
