import * as actions from './actions';

const initialState = {
  content: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_CONTENT_SUCCESS:
    case actions.FETCH_CONTENT_SUCCESS:
    case actions.UPDATE_CONTENT_SUCCESS:
      return {
        ...state,
        content: action.payload
      };

    case actions.RESET_CONTENTS_REDUCER:
      return initialState;

    default:
      return state;
  }
};
