import React from 'react';
import { LayoutProvider } from '../../layouts';
import { Row, Col, Button, Form, Input, Icon } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { Table, Avatar } from 'antd';
import Logic from '../../../logic';
import styled from 'styled-components';
import jwt from 'jsonwebtoken';

// import { MailOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const RegisterStyleWrapper = styled.div`
  .ant-divider {
    margin: 5px 0px;
  }
  .invite-form {
    margin-top: 20px;
  }
`;

export const Register = () => {
  const inviteToken = useSelector(state => state.containers['general::router'].query.invite_token);
  const payload = jwt.decode(inviteToken);
  console.log(payload, "TEST");

  const dispatch = useDispatch();
  // const [form] = Form.useForm();

  const handleFinishRegistration = (values) => {
    values["invite_token"] = inviteToken;
    dispatch(Logic.general.users.actions.finishUserRegistration(values));
  };

  return (
    <LayoutProvider type='external' >
      <RegisterStyleWrapper>
      <div style={{ padding: '40px'}}>
        <div className={'login'}>
          <div style={{paddingBottom: '20px'}}>
            <h3 className={'login__welcome'}>Welcome To The WFIA Training Portal</h3>
            <div>You have been invited to set up an account and begin training.</div>
          </div>
          <Form initialValues={{email: payload.email}} onFinish={(values) => { handleFinishRegistration(values); }} onFailure={(error) => { this.handleFormFailure(error); }} className="login-form">
            <FormItem 
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input disabled size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)', width: '100%' }} />} type='text' placeholder="Email" />
            </FormItem>
            <Row gutter={16}>
              <Col span={12}>
                 <FormItem 
                  name="firstName"
                  rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                  <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)', width: '100%' }} />} type='text' placeholder="First Name" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem 
                  name="lastName"
                  rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                  <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)', width: '100%' }} />} type='text' placeholder="Last Name" />
                </FormItem>
              </Col>
            </Row>
            <FormItem 
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)', width: '100%' }} />} type='password' placeholder="Password" />
            </FormItem>
            <FormItem 
              name="passwordConfirm"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)', width: '100%' }} />} type='password' placeholder="Confirm Password" />
            </FormItem>
            <Row>
              <Col span={24} style={{textAlign: 'right'}}>
                <Button size='large' htmlType="submit" className="login-form-button" style={{borderRadius: '0px', cursor: 'pointer',  zIndex: '100', backgroundColor: '#5BBA6F', color: 'white'}}>
                  Finish Signup
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      </RegisterStyleWrapper>
    </LayoutProvider>
  );
};

export default withRouter(Register);
