import React from 'react';
import { LayoutProvider } from '../../layouts';
import { Row, Col, Divider } from 'antd';
import { CheckCircleOutlined, DashboardOutlined, EyeOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { Table, Avatar } from 'antd';
// import Logic from '../../../logic';
import styled from 'styled-components';
import withSizes from 'react-sizes';
import Logic from '../../../logic';
import { EditorResponsiveWrapper } from '../../components/editor';
import { numberBreakpoints, stringBreakpoints } from '../../../app/constants/breakpoints';

const OrganizationStyleWrapper = styled.div`
  .ant-divider {
    margin: 5px 0px;
  }
  .invite-form {
    margin-top: 20px;
  }
  .current-month-content {
    padding: 10vh 20vw;
    &__title {
      font-family: Times New Roman;
    }
    &__subtitle, &__article {
      margin-bottom: 20px;
    }
  }
  .action-bar {
    padding: 2vh 20vw;
    height: 8vh; 
    background-color: #F2F2F2;

    &__link {
      color: #5BBA6F;
      font-weight: 400;
      text-align: right;

      &--mobile {
        display: block;
        width: 56%;
        margin: 20px auto;
        padding: 10px 30px;
        color: white;
        background: #5bba6f;
      }
    }

    &__check-circle {
      margin-left: 1vw;
      color: black;
    }
  }

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    .current-month-content {
      padding: 35px 20px 0 20px;
    }

    .action-bar {
      &__check-circle {
        color: white; 
        font-size: 20px;
      }

      &__completion-info {
        text-align: center;
        background: #5bba6f;
        color: white;
        padding: 20px;
      }
    }
  }
`;

const FileView = ({ file }) => {
  return (
    <FileView.Wrapper>
      <FileView.FileName href={file.link} target="_blank">{file.name} <FileView.CrossIcon /></FileView.FileName>
      
    </FileView.Wrapper>
  );
};

FileView.Wrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;
FileView.FileName = styled.a`
  margin-right: 4px;
  color: black;
`;
FileView.CrossIcon = styled(EyeOutlined)`
  color: #d5733f;
  font-size: 1.3em;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

const StyledActionButton = styled.div`
  padding: 12.5px;
  background: #d5733f;
  cursor: pointer;
`;

const actionIconStyle = { fontSize: 30, color: 'white' };

const { setContentAsComplete } = Logic.employee.collections.actions;


export const Dashboard = ({ isMobile }) => {
  const currentCollection = useSelector((state) => state.containers['employee::collections'].currentCollection);
  const collectionData = useSelector((state) => state.containers['employee::collections']);
  const query = useSelector((state) => state.containers['general::router'].query);

  if (!currentCollection) { return null; }

  const { contents } = collectionData;

  const content = contents.find(x => x.id === query.content);

  if (!content) { return null; }

  const isContentCompleted = currentCollection.relatedContentCompletion && currentCollection.relatedContentCompletion.contents.indexOf(content.id) > -1;

  const dispatch = useDispatch();
  return (
    <LayoutProvider 
      type='internal' 
      noGutter
      backgroundImageStyle={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-bins.png')"}}
      pageTitle={content.title}
      actionButton={
        isMobile && (
          <div className='mobile-action-items'>
            <StyledActionButton>
              <DashboardOutlined 
                style={actionIconStyle} 
                onClick={() => { dispatch(Logic.general.router.actions.navigateToRoute({ to: `/`, replace: false })); }}/>
            </StyledActionButton>
          </div>
        )
      }
    >
      <OrganizationStyleWrapper>
        {
          !isMobile && (
          <div className={'action-bar'}>
            <Row>
              <Col span={8} offset={16}>
                {
                  isContentCompleted ? (
                    <span className={'action-bar__link'}>Content Completed</span>
                  ) : (
                    <a 
                      className={'action-bar__link'}
                      onClick={() => {
                        dispatch(setContentAsComplete({ 
                          content: content.id,
                        }));
                      }}
                    >
                      Mark As Reviewed <CheckCircleOutlined className="action-bar__check-circle" />
                    </a>
                  )
                }
              </Col>
            </Row>
          </div>
          )
        }
        <div className={'current-month-content'}>
          <EditorResponsiveWrapper className="current-month-content__article">
            {(content ? content.description : '')}
          </EditorResponsiveWrapper>
          <div style={{marginTop: '5vh'}}>
            <div>
              <div>Attachments</div>
              <Divider/>
              {content['attachments'].map(attachment => (
                <FileView
                  file={attachment}
                  key={attachment.id}
                />)
              )}
            </div>
          </div>
        </div>
        {isMobile && (
          isContentCompleted ? (
            <h3 className={'action-bar__completion-info'}>Content Already Completed!</h3>
          ) : (
            <a
              className={'action-bar__link action-bar__link--mobile'}
              onClick={() => {
                dispatch(setContentAsComplete({ 
                  content: content.id,
                }));
              }}
            >
              Mark As Reviewed <CheckCircleOutlined className="action-bar__check-circle"/>
            </a>
          )
        )}
      </OrganizationStyleWrapper>
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.sm,
});

export default withRouter(withSizes(mapSizesToProps)(Dashboard));
