import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import RouteWrapperData from './base-routes-wrapper-data';
import * as pages from '../app/pages';
import Logic from '../logic';

const { sessionLogout } = Logic.general.session.actions;
const { getCurrentCollection, getUpcomingCalendar, getCompletedCollections, fetchContent, resetCollectionsReducer, getTutorialCollection } = Logic.employee.collections.actions;


export default class MARoutes extends Component {
  constructor (props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount () {
    const { session } = this.props;
    const isAuthenticatedUser = session.checked && session.authenticated && (session.user && true);
    if (isAuthenticatedUser) {
      this.props.fetchInitialData();
    }
  }

  render () {
    const { session } = this.props;
    return (
      <Switch>
        <Route
          exact
          path='/dashboard'
          render={() => {
            return <Redirect to={'/employee/dashboard'} key={'redirect-employee-'}/>;
          }}
        />
        <Route
          exact
          path='/'
          render={() => {
            return <Redirect to={'/employee/dashboard'} key={'redirect-employee-'}/>;
          }}
        />
        <RouteWrapperData
          exact
          path='/employee/dashboard'
          component={pages.Employee.Dashboard}
          routeId='EMPLOYEE-DASHBOARD'
          key={'employee-dashboard-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: getCurrentCollection, data: { } },
              { action: getTutorialCollection, data: { } }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/profile'
          component={pages.General.Profile}
          routeId='EMPLOYEE-PROFILE'
          key={'employee-profile-'}
          session={session}
          dataFetch
          dataActions={
            [
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/employee/calendar'
          component={pages.Employee.Calendar}
          routeId='EMPLOYEE-CALENDAR'
          key={'employee-calendar-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: getUpcomingCalendar, data: { } },
              { action: getTutorialCollection, data: { } }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/employee/completed'
          component={pages.Employee.Completed}
          routeId='EMPLOYEE-COMPLETED'
          key={'employee-completed-'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: getCompletedCollections, data: { } }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/employee/collections/:collection/content-view'
          component={pages.Employee.ContentView}
          routeId='EMPLOYEE-CONTENT-VIEW'
          key={'employee-content-view'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: getCurrentCollection, data: { } }
            ]
          }
        />
        <RouteWrapperData
          exact
          path='/employee/content/past-view'
          component={pages.Employee.PastContentView}
          routeId='EMPLOYEE-CONTENT-VIEW'
          key={'employee-content-view'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchContent, data: { } }
            ]
          }
          unmountActions={[
            resetCollectionsReducer
          ]}
        />
        <RouteWrapperData
          exact
          path='/employee/content/tutorial-view'
          component={pages.Employee.TutorialView}
          routeId='EMPLOYEE-TUTORIAL-VIEW'
          key={'employee-tutorial-view'}
          session={session}
          dataFetch
          dataActions={
            [
              { action: fetchContent, data: { } },
              { action: getTutorialCollection, data: { } }
            ]
          }
          unmountActions={[
            resetCollectionsReducer
          ]}
        />
        <RouteWrapperData
          exact
          path='/logout'
          component={pages.General.Logout}
          routeId='LOG_OUT'
          session={session}
          key={'logout-auth'}
          dataFetch
          dataActions={
            [
              { action: sessionLogout, data: { } }
            ]
          }
        />
        <Route
          exact
          path='/login'
          render={() => {
            return <Redirect to={'/employee/dashboard'} key={'redirect-employee-'}/>;
          }}
        />
        <Route
          exact
          path='/register'
          render={() => {
            return <Redirect to={'/employee/dashboard'} key={'redirect-employee-'}/>;
          }}
        />
        <Route render={() => { return (<pages.General.NotFound />); }}/>
      </Switch>
    );
  }
}
