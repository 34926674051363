import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Avatar from 'antd/lib/avatar/avatar';

const OrganizationStyleWrapper = styled.div`
  max-width: 1280px;
  margin: auto;

  .organization {
    &__company-name {
      padding-top: 10px;
      text-align: center;
    }
    &__contact-info {
      padding-top: 10px;
      padding-bottom: 15px;
    }

    &__contact-raw {
      display: flex;
      justify-content: center;
    }
    &__contact-label {
      margin-right: 5px;
      font-weight: bold;
    }
    &__contact-value {
      font-style: italic;
    }

    &__report {
      width: 90%;
      margin: auto;
    }
    &__collection-name {
      padding: 2.5px 0;
    }
    &__report-raw {
      margin-bottom: 40px;
    }
    &__report-raw-wrapper {
      display: flex;
      align-items: center;
    }
    &__month-list {
      padding-left: 55px;
    }
    &__month-raw {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid lightgrey;
    }
    &__tag {
      display: inline-block;
      padding: 5px 10px;
      border: 1px solid transparent;
      border-radius: 10%;
      color: white;

      &--resolved {
        background: #5bbb6f;
      }
      &--in-progress {
        background: #f5f063;
      }
    }
  }
`;

export const Dashboard = () => {
  const employeesDashboardData = useSelector(state => state.containers['ba::employees'].employeesDashboardData);
  const collections = useSelector(state => state.containers['employee::collections'].collections);
  const organization = useSelector(state => state.containers['general::organizations'].organizations[0]);

  if (!organization) { return null; }

  return (
    <OrganizationStyleWrapper>
      <h1 className="organization__company-name">{organization.company}</h1>
      <div className="organization__contact-info">
        <div className="organization__contact-raw">
          <p className="organization__contact-label organization__contact-label--name">Contact Name:</p>
          <p className="organization__contact-value organization__contact-value--name">{organization.contactName}</p>
        </div>
        <div className="organization__contact-raw">
          <p className="organization__contact-label organization__contact-label--email">Contact Email:</p>
          <p className="organization__contact-value organization__contact-value--email">{organization.contactEmail}</p>
        </div>
        <div className="organization__contact-raw">
          <p className="organization__contact-label organization__contact-label--phone">Contact Phone:</p>
          <p className="organization__contact-value organization__contact-value--phone">{organization.contactPhone}</p>
        </div>
      </div>


      <ul className="organization__report">
        {
          !employeesDashboardData || employeesDashboardData.length == 0 && 
          <div className="organization__report-raw-wrapper">
            <h2>No Company Data Available</h2>
          </div>
        }
        {
          employeesDashboardData && employeesDashboardData.map((employee) => {
            if (employee.stats.length === 0) { return null; }

            return (
              <li key={employee.id} className="organization__report-raw">
                <div className="organization__report-raw-wrapper">
                  <Avatar
                    size="large"
                    src={employee.picture ? employee.picture.link : 'https://invigor-dev.s3-us-west-2.amazonaws.com/user1.png'}
                    style={{ marginRight: 15 }}
                  />
                  <h2>{employee.firstName} {employee.lastName}</h2>
                </div>
                <ul className="organization__month-list">
                  {employee.stats.map((stat) => {
                    if (!stat.wasActive) {
                      return (
                        <li key={stat.month} className="organization__month-raw">
                          <h4>{stat.month}</h4>
                          <div style={{ fontStyle: 'italic' }}>
                            The user was not active during this period
                              </div>
                        </li>
                      );
                    }

                    const collection = collections.find(x => x.id === stat.collectionId);
                    if (!collection) { return null; }

                    return (
                      <li key={stat.month} className="organization__month-raw">
                        <h4>{stat.month}</h4>
                        <ul style={{ padding: 0, fontStyle: 'italic', listStyleType: 'disc' }}>
                          {
                            stat.ofContents.map((ofContent, oKey) => {
                              const content = collection.contents.find(x => x.id === ofContent);
                              const isDone = stat.doneContents.indexOf(ofContent) > -1;

                              if (isDone) {
                                return (
                                  <li className="organization__collection-name"><strong>{content.title}</strong> - <p className="organization__tag organization__tag--resolved">Resolved</p></li>
                                );
                              }

                              return (
                                <li className="organization__collection-name" key={oKey}><strong>{content.title}</strong> - <p className="organization__tag organization__tag--in-progress">In Progress</p></li>
                              );
                            })
                          }
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })
        }
      </ul>
    </OrganizationStyleWrapper>
  );
};

export default withRouter(Dashboard);
