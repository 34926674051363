import React from 'react';
import { Icon, Input } from 'antd';

export class PhoneInput extends React.Component {
  applyBraces = (v) => `(${v})`;

  static PHONE_MAX_CHARACTERS = 10;
  static removeDecoration = (value) => typeof value === 'string' ? value.replace(/\D+/g, '') : '';
  
  getDecoratedPhoneValue = (value) => {
    // (xxx)-xxx-xxxx to xxxxxxxxxx
    const originalValue = PhoneInput.removeDecoration(value);
    let decoratedValue = '';

    if (!originalValue) return decoratedValue;
    
    if (originalValue.length >= 1 && originalValue.length <= 3) {
      decoratedValue = `${originalValue.slice(0, 3)}`;
    }

    if (originalValue.length >= 4 && originalValue.length <= 6) {
      decoratedValue = `(${originalValue.slice(0, 3)})-${originalValue.slice(3, 6)}`;
    }

    if (originalValue.length >= 7) {
      decoratedValue = `(${originalValue.slice(0, 3)})-${originalValue.slice(3, 6)}-${originalValue.slice(6, PhoneInput.PHONE_MAX_CHARACTERS)}`;
    }

    return decoratedValue;
  }

  handleChange = (e) => {
    const { onChange } = this.props;
    onChange(this.getDecoratedPhoneValue(e.target.value));
  }
  
  render() {
    const { value, render } = this.props;

    // re-use the component logic with custom component 
    if (render) {
      return render(this.props);
    }

    return (
      <Input
        size='large' 
        type='text' 
        prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="Phone: xxxxxxxxxx"
        onChange={this.handleChange}
        value= {value}  
      />
    );
  }
}