const ROOT = 'APP';

export const FETCH_SINGLE_COLLECTION = `${ROOT}/FETCH_SINGLE_COLLECTION`;
export const FETCH_SINGLE_COLLECTION_SUCCESS = `${ROOT}/FETCH_SINGLE_COLLECTION_SUCCESS`;
export const FETCH_SINGLE_COLLECTION_ERROR = `${ROOT}/FETCH_SINGLE_COLLECTION_ERROR`;

export function fetchSingleCollection (payload) {
  let collectionId = typeof payload.params === 'object' ? payload.params.collection : payload;

  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/collections/${collectionId}`,
      method: 'GET',
      params: {},
      query: {},
      body: {},
      auth: null
    },
    success: {
      actions: [FETCH_SINGLE_COLLECTION_SUCCESS]
    },
    error: {
      actions: [FETCH_SINGLE_COLLECTION_ERROR]
    }
  };
}

export const FETCH_COLLECTIONS = `${ROOT}/FETCH_COLLECTIONS`;
export const FETCH_COLLECTIONS_SUCCESS = `${ROOT}/FETCH_COLLECTIONS_SUCCESS`;
export const FETCH_COLLECTIONS_ERROR = `${ROOT}/FETCH_COLLECTIONS_ERROR`;

export function fetchCollections () {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/collections`,
      method: 'GET',
      params: {},
      query: {},
      body: {},
      auth: null
    },
    success: {
      actions: [FETCH_COLLECTIONS_SUCCESS]
    },
    error: {
      actions: [FETCH_COLLECTIONS_ERROR]
    }
  };
}

export const CREATE_COLLECTION = `${ROOT}/CREATE_COLLECTION`;
export const CREATE_COLLECTION_SUCCESS = `${ROOT}/CREATE_COLLECTION_SUCCESS`;
export const CREATE_COLLECTION_ERROR = `${ROOT}/CREATE_COLLECTION_ERROR`;

export function createCollection (body) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/collections`,
      method: 'POST',
      params: {},
      query: {},
      body,
      auth: null
    },
    success: {
      actions: [CREATE_COLLECTION_SUCCESS]
    },
    error: {
      actions: [CREATE_COLLECTION_ERROR]
    }
  };
}

export const DELETE_COLLECTION = `${ROOT}/DELETE_COLLECTION`;
export const DELETE_COLLECTION_SUCCESS = `${ROOT}/DELETE_COLLECTION_SUCCESS`;
export const DELETE_COLLECTION_ERROR = `${ROOT}/DELETE_COLLECTION_ERROR`;

export function deleteCollection (collectionId) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/collections/${collectionId}`,
      method: 'DELETE',
      params: {},
      query: {},
      body: {},
      auth: null
    },
    success: {
      actions: [DELETE_COLLECTION_SUCCESS]
    },
    error: {
      actions: [DELETE_COLLECTION_ERROR]
    }
  };
}

export const UPDATE_CONTENT_ORDER = `${ROOT}/UPDATE_CONTENT_ORDER`;
export const UPDATE_CONTENT_ORDER_SUCCESS = `${ROOT}/UPDATE_CONTENT_ORDER_SUCCESS`;
export const UPDATE_CONTENT_ORDER_ERROR = `${ROOT}/UPDATE_CONTENT_ORDER_ERROR`;

export function updateCollection ({ id, body }) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/collections/${id}`,
      method: 'PUT',
      params: {},
      query: {},
      body,
      auth: null
    },
    success: {
      actions: [UPDATE_CONTENT_ORDER_SUCCESS]
    },
    error: {
      actions: [UPDATE_CONTENT_ORDER_ERROR]
    }
  };
}

export const RESET_COLLECTIONS_REDUCER = `${ROOT}/RESET_COLLECTIONS_REDUCER`; 
export const resetCollectionsReducer = () => ({
  type: RESET_COLLECTIONS_REDUCER
});
