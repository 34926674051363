import { default as Dashboard } from './dashboard';
import { default as ContentView } from './content-view';
import { default as PastContentView } from './past-content-view';
import { default as Calendar } from './calendar';
import { default as Completed } from './completed';
import { default as TutorialView } from './tutorial-view';
export default {
	Dashboard,
	ContentView,
	Calendar,
	Completed,
	PastContentView,
	TutorialView
};