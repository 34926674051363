import axios from 'axios';
import { sessionService } from 'redux-react-session';
const BACKEND_URL = process.env.WFIA_API_URL;

export default class SessionAPI {
  static async logout () {
    sessionService.deleteSession();
    sessionService.deleteUser();
    return true;
  }

  static async refreshAuthToken () {
    let user = await sessionService.loadUser();
    return axios({
      method: 'POST',
      url: `${BACKEND_URL}/auth/refresh?access_token=${user.token}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error;
      });
  }

  static getActivateStatus (code, sid) {
    return axios({
      method: 'GET',
      url: `${BACKEND_URL}/activate/status?signupCode=${code}&sid=${sid}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static validate (data) {
    return axios({
      method: 'POST',
      url: `${BACKEND_URL}/activate?signupCode=${data.signupCode}&sid=${data.sid}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static completeUserSetup (data) {
    return axios({
      method: 'PUT',
      url: `${BACKEND_URL}/users/me?access_token=${data.authToken}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static forgotPassword (data) {
    return axios({
      method: 'POST',
      url: `${BACKEND_URL}/auth/forgot?access_token=${process.env.DASH_REST_MASTER_KEY}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static resetPassword (data) {
    return axios({
      method: 'POST',
      url: `${BACKEND_URL}/auth/reset?access_token=${data.authToken}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }
}
