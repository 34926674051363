import * as actions from './actions';
import { VALID } from '../../../app/constants/invite-token-statuses';

export default function (state = {
  currentUser: null,
  sessionToken: null,
  authenticated: false,
  invite: {},
  activeOrganization: {},
  inviteTokenData: null,
  afterLoginRedirectPath: null
}, action) {
  let stateCopy = { ...state };
  switch (action.type) {
    case 'SESSION_GET_ACTIVATE_STATUS_SUCCESS':
      stateCopy.invite = action.payload;
      return stateCopy;
    // case '@@redux-react-session/GET_USER_SESSION_SUCCESS':
    //   if (action.user && action.user.organizationAffiliations && action.user.organizationAffiliations.length > 0) {
    //     const affiliation = action.user.organizationAffiliations.find((x) => x.type == 'primary');
    //     if (affiliation) {
    //       stateCopy.activeOrganization = action.user.organizationAffiliations.find((x) => x.type == 'primary').organization.id;
    //     }
    //   }
    //   return stateCopy;
    case actions.CHECK_INVITE_TOKEN_SUCCESS:
      if (action.payload.status !== VALID) {
        return state;
      }

      stateCopy.inviteTokenData = action.payload.payload;
      return stateCopy;

    case actions.SET_AFTER_LOGIN_REDIRECT:
      stateCopy.afterLoginRedirectPath = action.payload.path;
      return stateCopy;

    default:
      return state;
  }
}
