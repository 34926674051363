import { fork, all } from 'redux-saga/effects';
import Logic from '../logic';

let sagas = [];

Object.keys(Logic).map((module) => {
	// mmodule would be admin, general, patient, etc;
	Object.keys(Logic[module]).map((subModule) => {
		sagas.push(Logic[module][subModule].sagas);
	});
});

function * globalSagas () {
  const globalSagasForks = sagas.map((saga) => fork(saga));
  yield all([...globalSagasForks]);
}

export default globalSagas;
