import * as app from './app';
import * as router from './router';
import * as session from './session';
import * as users from './users';
import * as organizations from './organizations';
import * as collections from './collections';

export default {
  app,
  session,
  router,
  users,
  organizations,
  collections
};