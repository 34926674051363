import React from 'react';
import styled from 'styled-components';
import { PopoutMenu } from './popoutMenu';
import { List } from 'antd';
import Logic from '../../logic';
import { useDispatch } from 'react-redux';
import { stringBreakpoints } from '../../app/constants/breakpoints';

const tagMapping = {
  'Grocery': {
    background: '#d5733f',
    color: 'white'
  },
  'Trucking': {
    background: '#268bd5',
    color: 'white'
  },
  'Complete': {
    background: '#388659',
    color: 'white'
  },
  'Tutorial': {
    background: '#388659',
    color: 'white'
  }
};

const { navigateToRoute } = Logic.general.router.actions;

export const ContentCard = ({ coverImage, title, tags, contentId, collectionId, bordered, style, role, review, tutorial, actionTest }) => {
  const dispatch = useDispatch();

  return (
    <ContentCard.Wrapper bordered={bordered} style={style}>
      <ContentCard.CoverImage src={coverImage} onClick={() => {
        if (role && (role === 'employee' || role === 'ba')) {
          if (tutorial) {
            dispatch(navigateToRoute({ to: `/${role}/content/tutorial-view?content=${contentId}` }));
            return;
          }
          if (review) {
            dispatch(navigateToRoute({ to: `/${role}/content/past-view?content=${contentId}` }));
            return;
          } else {
            dispatch(navigateToRoute({ to: `/${role}/collections/${collectionId}/content-view?content=${contentId}` }));  
          }
          
        } else if (role === 'ma') {
          dispatch(navigateToRoute({ to: `/${role}/collections/${collectionId}/content-edit?content=${contentId}` }));
        }

      }} 
      />
      <ContentCard.Tags>
        {tags.map(tag => (
          <ContentCard.Tag key={tag} style={tagMapping[tag]}>
            {tag}
          </ContentCard.Tag>
        ))}
      </ContentCard.Tags>
      <ContentCard.TextContainer>
        <ContentCard.Container>
          <ContentCard.Title
            onClick={() => {
              if (role && (role === 'employee' || role === 'ba')) {
                if (tutorial) {
                  dispatch(navigateToRoute({ to: `/${role}/content/tutorial-view?content=${contentId}` }));
                  return;
                }
                if (review) {
                  dispatch(navigateToRoute({ to: `/${role}/content/past-view?content=${contentId}` }));
                  return;
                } else {
                  dispatch(navigateToRoute({ to: `/${role}/collections/${collectionId}/content-view?content=${contentId}` }));  
                }
                
              } else if (role === 'ma') {
                dispatch(navigateToRoute({ to: `/${role}/collections/${collectionId}/content-edit?content=${contentId}` }));
              }
            }}
          >{title}</ContentCard.Title>
          {
            role === 'ma' && 
            <PopoutMenu
              title="Content Menu"
              iconSize={30}
              content={
                <List>
                  <List.Item
                    onClick={() => dispatch(navigateToRoute({ to: `/ma/collections/${collectionId}/content-edit?content=${contentId}` }))}
                    style={{ cursor: 'pointer' }}
                  >
                    Edit
                  </List.Item>
                </List>
              } 
            />
          }
          
        </ContentCard.Container>
          {
            actionTest && 
            <div>{actionTest}</div>
          }
          
      </ContentCard.TextContainer>
    </ContentCard.Wrapper>
  );
};

ContentCard.Wrapper = styled.div`
  max-width: 26vw;
  height: 225px;
  position: relative;
  background: white;
  overflow: hidden;
  cursor: pointer;
  border: ${p => p.bordered ? '1px solid #f2f2f2' : 'none'};

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    max-width: 100%;
  }
`;

ContentCard.Tags = styled.div`
  position: absolute;
  top: 110px;
  right: 0;
  padding: 0 20px;
  display: flex;
`;

ContentCard.Tag = styled.div`
  padding: 5px 16px;
  &:first-child {
    margin-right: 5px;
  }
`;
ContentCard.CoverImage = styled.div`
  height: 125px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;
ContentCard.TextContainer = styled.div`
  padding: 22px 20px;
`;
ContentCard.Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;
ContentCard.Title = styled.h3`
  color: #5a9b75;
  cursor: pointer;
  margin-top: 10px;
`;
ContentCard.Description = styled.div`
  overflow: hidden;
  max-height: 10vh;
`;
