import React, { Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
// import Logic from '../../../logic';

import '../../styles/global.css';
import 'react-block-ui/style.css';


const DashboardStyleWrapper = styled.div`
  .block-ui-overlay {
    opacity: .7;
    background-color: black;
  }

  .block-ui-message-container {
    top: 20% !important;
  }

  .block-ui-message {
    color: white;
    font-size: 36px
  }

  .block-ui {
    z-index: 10
  }

  .internal-layout {
    font-family: Times New Roman;
    &__hero-container {
      position: relative;
      width: 100%;

      height: 40vh;

      &__page-title {
        position: absolute;
        bottom: -3vh;
        left: 20%;
        background-color: #388659;
        padding: 1vh;
        color: white;
        font-family: Times New Roman;
        font-size: 30px;
        z-index: 10;
      }
    }
    &__subheader {
      position: relative;
      height: 54px;
      background: #f2f2f2;
      margin-bottom: 22px;
    }
  }
`;

class Dashboard extends Component {
  constructor (props) {
    super(props);

    this.state = {

    };
  }

  render () {
    const { subheader, noGutter = false } = this.props;

    return (
      <DashboardStyleWrapper>
        <div className={'internal-layout'}>
          <div className={'internal-layout__hero-container'} style={{height: '20vh', ...this.props.backgroundImageStyle}}>
            {
              this.props.pageTitle &&
              <div className={'internal-layout__hero-container__page-title'}>{this.props.pageTitle}</div>
            }
          </div>

          {subheader && (
            <div className="internal-layout__subheader">
              {subheader}
            </div>
          )}

          <div>
            {!noGutter ? (
              <div style={{ width: '80%', margin: 'auto' }}>
                {this.props.children}
              </div>
            ) : this.props.children}
          </div>
        </div>
      </DashboardStyleWrapper>
    );
  }
}

function mapStateToProps () {
  return {
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
