import React from 'react';
import * as desktopLayouts from './desktop';


const LayoutProviderY = (props) => {
  switch (props.type) {
    case 'internal':
      return <desktopLayouts.Internal {...props}>
        {props.children}
      </desktopLayouts.Internal>;
    case 'external':
      return <desktopLayouts.External {...props}>
        {props.children}
      </desktopLayouts.External>;
    case 'pdf':
      return <desktopLayouts.PDF {...props}>
        {props.children}
      </desktopLayouts.PDF>;
    case 'none':
      return <div>
        {props.children}
      </div>;
    default:
      return <desktopLayouts.External {...props}>
        {props.children}
      </desktopLayouts.External>;
  }
};
 
export const LayoutProvider = LayoutProviderY;
