const ROOT = 'EMPLOYEES';

export const FETCH_EMPLOYEES_DASHBOARD_DATA = `${ROOT}/FETCH_EMPLOYEES_DASHBOARD_DATA`;
export const FETCH_EMPLOYEES_DASHBOARD_DATA_SUCCESS = `${ROOT}/FETCH_EMPLOYEES_DASHBOARD_DATA_SUCCESS`;
export const FETCH_EMPLOYEES_DASHBOARD_DATA_ERROR = `${ROOT}/FETCH_EMPLOYEES_DASHBOARD_DATA_ERROR`;

export function fetchEmployeesDashboardData () {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/users/employees/progress`,
      method: 'GET',
      params: {},
      query: {},
      body: {},
      auth: null
    },
    success: {
      actions: [FETCH_EMPLOYEES_DASHBOARD_DATA_SUCCESS]
    },
    error: {
      actions: [FETCH_EMPLOYEES_DASHBOARD_DATA_ERROR]
    }
  };
}

export const UPDATE_CONTENT_COMPLETION = `${ROOT}/UPDATE_CONTENT_COMPLETION`;
export const UPDATE_CONTENT_COMPLETION_SUCCESS = `${ROOT}/UPDATE_CONTENT_COMPLETION_SUCCESS`;
export const UPDATE_CONTENT_COMPLETION_ERROR = `${ROOT}/UPDATE_CONTENT_COMPLETION_ERROR`;

export function updateContentCompletion (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/contentCompletions/${payload.id}`,
      method: 'PUT',
      params: {},
      query: {},
      body: payload,
      auth: null
    },
    success: {
      actions: [UPDATE_CONTENT_COMPLETION_SUCCESS]
    },
    error: {
      actions: [UPDATE_CONTENT_COMPLETION_ERROR]
    }
  };
}

export const GENERATE_ORGANIZATION_REPORT = `${ROOT}/GENERATE_ORGANIZATION_REPORT`;
export const GENERATE_ORGANIZATION_REPORT_SUCCESS = `${ROOT}/GENERATE_ORGANIZATION_REPORT_SUCCESS`;
export const GENERATE_ORGANIZATION_REPORT_ERROR = `${ROOT}/GENERATE_ORGANIZATION_REPORT_ERROR`;

export function generateOrganizationReport (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/organizations/${payload.organization}/report`,
      method: 'POST',
      params: {},
      query: {},
      body: payload,
      auth: null,
      responseType: 'blob',
      responseHandler: (response) => {
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        return response.data;
      },
    },
    success: {
      actions: [GENERATE_ORGANIZATION_REPORT_SUCCESS]
    },
    error: {
      actions: [GENERATE_ORGANIZATION_REPORT_ERROR]
    }
  };
}

