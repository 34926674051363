import React, { useState, useRef } from 'react';
import { LayoutProvider } from '../../layouts';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col, Input, Icon, Button, Form, Modal, Avatar, notification } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import Logic from '../../../logic';
import { numberBreakpoints } from '../../constants/breakpoints';
import {
  CloseOutlined,
  SettingOutlined
} from '@ant-design/icons';
import withSizes from 'react-sizes';
import { ACCEPT_IMAGES_ONLY_RULE, MAX_IMAGE_UPLOAD_SIZE_MB } from '../../constants/files';

const { updateUserPassword } = Logic.general.session.actions;
const { updateSessionUser } = Logic.general.users.actions;

const FormItem = Form.Item;
const OrganizationStyleWrapper = styled.div`
  .ant-divider {
    margin: 5px 0px;
  }
  .organization-edit {
    padding: 10vh 10vw;
  }
  .organization {
    &__image {
      width: 100%
    }
    &__action-column {
      text-align: center;
      &__edit-button {
        margin-top: 10px;
      }
    }
  }
  h1 {
    font-family: Times New Roman;
    color: #388659;
    font-size: 40px;
  }
`;

const RightColumn = styled.div`
  min-width: 22%;
`;

RightColumn.Header = styled.h3`
  color: #549770;
  font-size: 22px;
  border-bottom: 3px solid #549770;
  margin-bottom: 25px;
`;
RightColumn.DescriptionText = styled.p`
  color: #b8b8b8;
  margin-bottom: 5px;
`;
RightColumn.ActionButton = styled.button`
  padding: 5px 23px;
  color: white;
  background: #5bba6f;
  border: none;
  box-shadow: none;
  margin-bottom: 20px;
  cursor: pointer;
`;

const StyledForm = styled(Form)`
  margin-top: 25px;
`;

const ContentEdit = styled(Form)`
  padding: 31px 4% 0 0;
  width: 78%;
  margin-left: auto;
`;

ContentEdit.Container = styled.div`
  display: flex;
  justify-content: ${props => props.justify ? props.justify : 'space-between'};
  flex-direction: ${props => props.direction ? props.direction : 'row'};
  align-items: ${props => props.align ? props.align : 'initial'};
  flex-wrap: wrap;
`;
ContentEdit.Column = styled.div`
  width: ${props => props.width ? props.width : 'auto'};
`;
ContentEdit.Label = styled.div`
`;
ContentEdit.Title = styled.div`
`;
ContentEdit.CoverPhoto = styled.img`
  height: 200px;
  width: 100%;
  margin-bottom: 25px;
`;
ContentEdit.FileTypeLimitations = styled.div`
  max-width: 27.5%;
  text-align: left;
  font-family: 'Lato';
  font-size: 10px;
`;
ContentEdit.FileTypeLimitations.Header = styled.h4`
  font-size: 1em;
  margin-bottom: 5px;
`;
ContentEdit.FileTypeLimitations.Description = styled.p`
  font-size: 0.8em;
`;
ContentEdit.BottomActionBar = styled.div`
  margin-top: 20px;

`;

ContentEdit.Inputs = {};
ContentEdit.Inputs.Title = styled(Input)`
`;
ContentEdit.Inputs.Attachments = styled(Input)`
`;

ContentEdit.Buttons = {};
ContentEdit.Buttons.Green = styled.button`
  padding: 6px 18px;
  color: white;
  border: none;
  background: #5bba6f;
  cursor: pointer;
`;
ContentEdit.Buttons.Orange = styled(Button)`
  padding: 6px 18px;
  color: white;
  border: none;
  cursor: pointer;
  background: #d5733f;
`;
ContentEdit.Buttons.Grey = styled(Button)`
`;

const StyledActionButton = styled.div`
  padding: 12.5px;
  background: #d5733f;
  cursor: pointer;
`;

// HELPERS
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const Profile = ({ isMobile }) => {
  // hooks should be on the top level only - they should be invoked every render.
  const user = useSelector(state => state.session.user);
  const [isModalShown, setIsModalShown] = useState(false);
  const [areOptionsShown, setAreOptionsShown] = useState(false);
  const dispatch = useDispatch();

  const closeModal = () => setIsModalShown(false);
  const openModal = () => setIsModalShown(true);

  const profileImageRef = useRef(null);
  
  if (!user) { return null; }

  const handleSubmitUser =  (values) => {
    let data = { ...values };
    dispatch(updateSessionUser({ ...data, id: user.id }));
  };

  const handleFormFailure =  (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const actionIconStyle = { fontSize: 30, color: 'white' };

  return (
    <LayoutProvider 
      type='internal' 
      backgroundImageStyle={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-business.png')"}}
      pageTitle={`${user.firstName} ${user.lastName}`}
      actionButton={
        isMobile && (
          <div className='mobile-action-items'>
            <StyledActionButton>
              <DashboardOutlined 
                style={actionIconStyle} 
                onClick={() => { dispatch(Logic.general.router.actions.navigateToRoute({ to: `/`, replace: false })); }}/>
            </StyledActionButton>
            <StyledActionButton onClick={() => setAreOptionsShown(!areOptionsShown)}>
              {!areOptionsShown ? <SettingOutlined style={actionIconStyle} /> : <CloseOutlined style={actionIconStyle} />}
            </StyledActionButton>
          </div>
        )
      }
      noGutter={isMobile}
    >
      <OrganizationStyleWrapper>
        <div className={'organization-edit'}>
          <div>
            <Row gutter={32}>
              {/* using display none to save entered data */}
              <Col span={isMobile ? 24 :  16} style={isMobile && areOptionsShown ? { display: "none" } : {}}>
                <div style={{marginBottom: '5vh'}}>
                  <h1>My Profile</h1>
                  <span>Edit Your Profile Details, Manage Your Password, and Upload a Profile Picture</span>
                </div>
                <Form layout="vertical" initialValues={user} onFinish={(values) => { handleSubmitUser(values); }} onFailure={(error) => { handleFormFailure(error); }} className="login-form">
                  <Row gutter={8}>
                    <Col span={24}>
                      <FormItem 
                        name="firstName"
                        label="First Name"
                        rules={[{ required: true, message: 'Please input the first name!' }]}
                      > 
                        <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Company Name" />
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <FormItem 
                        name="lastName"
                        label="Last Name"
                        rules={[{ required: true, message: 'Please input your last name!' }]}
                      >
                        <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Address"  />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={24}>
                      <FormItem 
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                      >
                        <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="City"/>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Button size='large' htmlType="submit" className="login-form-button" style={{borderRadius: '0px', cursor: 'pointer',  zIndex: '100', backgroundColor: '#5BBA6F', color: 'white'}}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form> 
              </Col>
              <Col span={isMobile ? 24 : 8} style={isMobile && !areOptionsShown ? { display: "none" } : {}}>
                <RightColumn.Header>Profile Options</RightColumn.Header>
                <RightColumn.DescriptionText>Select the button below to change your current password:</RightColumn.DescriptionText>
                <RightColumn.ActionButton 
                  onClick={openModal}>
                  Change Password
                </RightColumn.ActionButton>
                
                <RightColumn.Header>Profile Picture</RightColumn.Header>
                <RightColumn.DescriptionText>Select the button below to add or change your current profile picture:</RightColumn.DescriptionText>
                <div style={{textAlign: 'center', width: '100%', marginBottom: '5vh'}}>
                  <Avatar size={72} src={user.picture ? user.picture.link : 'https://invigor-dev.s3-us-west-2.amazonaws.com/user1.png'} />
                </div>
                
                <ContentEdit.Container style={{ marginBottom: 35 }} justify="space-around" >
                  <ContentEdit.Buttons.Green type="button" onClick={() => profileImageRef.current.click()}>Upload a new photo</ContentEdit.Buttons.Green>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept={ACCEPT_IMAGES_ONLY_RULE}
                    ref={profileImageRef}
                    onChange={async e => {
                      const file = e.target.files[0];

                      if (file.size > MAX_IMAGE_UPLOAD_SIZE_MB * 1024 * 1024) {
                        return notification.warning({
                          message: 'File size limit exceeded!',
                          description: `Select file up to ${MAX_IMAGE_UPLOAD_SIZE_MB}MB.`
                        });
                      }

                      const convertedFile = await toBase64(file);
                      const structuredFile = ({
                        data: convertedFile,
                        fileName: file.name
                      });

                      dispatch(updateSessionUser({ picture: structuredFile, id: user.id }));
                    }}
                  />
                </ContentEdit.Container>
              </Col>
            </Row>

            
          </div>
        </div>
        <Modal
          visible={isModalShown}
          onCancel={closeModal}
          footer={null}
        >
          {isModalShown && (
            <StyledForm
              layout="vertical"
              onFinish={(values) => {
                dispatch(updateUserPassword({ ...values, username: user.email, id: user.id }));
                closeModal();
              }}
            >
              <Form.Item
                name="password"
                label="Current Password"
                rules={[
                  { required: true, message: 'Current Password is required!' }
                ]}
              >
                <Input type='password'/>
              </Form.Item>

              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  { required: true, message: 'Current Password is required!' }
                ]}
              >
                <Input type='password'/>
              </Form.Item>

              <Form.Item
                name="newPasswordConfirm"
                label="Confirm New Password"
                rules={[
                  { required: true, message: 'Current Password is required!' }
                ]}
              >
                <Input type='password'/>
              </Form.Item>

              <RightColumn.ActionButton htmlType="submit">Change Password</RightColumn.ActionButton>
            </StyledForm>
          )}
        </Modal>
      </OrganizationStyleWrapper>
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.sm,
});

export default withRouter(withSizes(mapSizesToProps)(Profile));
