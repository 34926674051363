const ROOT = 'ORGANIZATIONS';

export const FETCH_ORGANIZATIONS = `${ROOT}/FETCH_ORGANIZATIONS`;
export const FETCH_ORGANIZATIONS_SUCCESS = `${ROOT}/FETCH_ORGANIZATIONS_SUCCESS`;
export const FETCH_ORGANIZATIONS_ERROR = `${ROOT}/FETCH_ORGANIZATIONS_ERROR`;


export function fetchOrganizations () {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/organizations`,
      method: 'GET',
      params: {},
      query: {},
      body: {},
      auth: null
    },
    success: {
      actions: [FETCH_ORGANIZATIONS_SUCCESS]
    },
    error: {
      actions: [FETCH_ORGANIZATIONS_ERROR]
    }

  };
}

export const FETCH_ORGANIZATION = `${ROOT}/FETCH_ORGANIZATION`;
export const FETCH_ORGANIZATION_SUCCESS = `${ROOT}/FETCH_ORGANIZATION_SUCCESS`;
export const FETCH_ORGANIZATION_ERROR = `${ROOT}/FETCH_ORGANIZATION_ERROR`;


export function fetchOrganization (payload) {
  let orgId = payload.routeData ? payload.routeData.orgId : payload.params.orgId;
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/organizations/${orgId}`,
      method: 'GET',
      params: {},
      query: {},
      body: {},
      auth: null
    },
    success: {
      actions: [FETCH_ORGANIZATION_SUCCESS]
    },
    error: {
      actions: [FETCH_ORGANIZATION_ERROR]
    }

  };
}

export const UPDATE_ORGANIZATION = `${ROOT}/UPDATE_ORGANIZATION`;
export const UPDATE_ORGANIZATION_SUCCESS = `${ROOT}/UPDATE_ORGANIZATION_SUCCESS`;
export const UPDATE_ORGANIZATION_ERROR = `${ROOT}/UPDATE_ORGANIZATION_ERROR`;


export function updateOrganization (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/organizations/${payload.id}`,
      method: 'PUT',
      params: {},
      query: {},
      body: payload,
      auth: null
    },
    success: {
      actions: [UPDATE_ORGANIZATION_SUCCESS]
    },
    error: {
      actions: [UPDATE_ORGANIZATION_ERROR]
    }

  };
}

export const DELETE_ORGANIZATION = `${ROOT}/DELETE_ORGANIZATION`;
export const DELETE_ORGANIZATION_SUCCESS = `${ROOT}/DELETE_ORGANIZATION_SUCCESS`;
export const DELETE_ORGANIZATION_ERROR = `${ROOT}/DELETE_ORGANIZATION_ERROR`;


export function deleteOrganization (payload) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/organizations/${payload.id}`,
      method: 'DELETE',
      params: {},
      query: {},
      body: payload,
      auth: null
    },
    success: {
      actions: [DELETE_ORGANIZATION_SUCCESS]
    },
    error: {
      actions: [DELETE_ORGANIZATION_ERROR]
    }

  };
}
