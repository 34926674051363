import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import matchPath from 'react-router/matchPath';
// import * as pages from '../app/pages';
import qs from 'query-string';
import Logic from '../logic';

const { resolveRoute, setParameters, configureRoute, fetchRouteData } = Logic.general.router.actions;

class RouteWrapperData extends Component {
  constructor (props) {
    super(props);

    this.state = {

    };
  }

  UNSAFE_componentWillMount () {
    const {
      path,
      exact,
      dataActions,
      location,
      session
    } = this.props;

    const match = matchPath(
      location.pathname, // global DOM variable
      { path, exact }
    );
    const queryValues = qs.parse(location.search);
    if (match && !this.props.router.isLoading && !this.props.router.isLoaded && dataActions) {
      this.props.configureRoute({ match, queryValues, dataActions });
      let generatedActions = dataActions.map((actionGenerator) => { return actionGenerator.action({query: queryValues, params: match.params, ...actionGenerator.data}); });
      this.props.fetchRouteData({dataActions: generatedActions, session});
    }
  }

  static defaultProps = {
    unmountActions: []
  }

  componentWillUnmount() {
    const { dispatch, unmountActions } = this.props;
    unmountActions.forEach(actionCreator => dispatch(actionCreator()));
  }

  render () {
    const {
      path,
      exact,
      component,
      // router,
      // session
    } = this.props;

    const match = matchPath(
      location.pathname, // global DOM variable
      { path, exact }
    );
    if (!match) {
      // Do nothing because the current
      // location doesn't match the path prop.

      return null;
    }
    // ALL RELATED LOGIC NOW WILL BE HANDLED BY THE GLOBAL SPINNER

    // if (!router.isConfigured) {
    //   if (session.user.id) {
    //     if (session.user.role === 'patient') {
    //       return <pages.Patient.IsLoading/>;
    //     }
    //     return <pages.General.IsLoading/>;
    //   } else {
    //     return <pages.Unauthenticated.IsLoadingUnauthenticated/>;
    //   }
    // }
    if (component) {
      return (
        <Route exact path={path} render={() => React.createElement(component, { match, key: Math.random().toString()
        })}>

        </Route>);
    }
    return null;
  }
}

function mapStateToProps (state) {
  return {
    session: state.session,
    router: state.containers['general::router']
  };
}

function mapDispatchToProps (dispatch) {
  const binded = bindActionCreators({
    resolveRoute,
    setParameters,
    configureRoute,
    fetchRouteData
  }, dispatch);

  return {
    ...binded,
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteWrapperData));
