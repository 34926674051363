import React from 'react';
import { LayoutProvider } from '../../layouts';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col, Input, Icon, Button, Form } from 'antd';
import Logic from '../../../logic';
import { stringBreakpoints } from '../../constants/breakpoints';

const { updateOrganization } = Logic.general.organizations.actions;

const FormItem = Form.Item;
const OrganizationStyleWrapper = styled.div`
  .ant-divider {
    margin: 5px 0px;
  }
  .organization-edit {
    padding: 10vh 10vw;
    @media only screen and (max-width: ${stringBreakpoints.sm}) {
      padding: 10vh 20px;
    }
  }
  .organization {
    &__image {
      width: 100%
    }
    &__action-column {
      text-align: center;
      &__edit-button {
        margin-top: 10px;
      }
    }
  }
  h1 {
    font-family: Times New Roman;
    color: #388659;
    font-size: 40px;
  }
`;


export const Dashboard = () => {
  // const dispatch = useDispatch();
  const organizations = useSelector(state => state.containers['general::organizations'].organizations);
  const currentOrganizationId = useSelector(state => state.containers['general::router'].parameters.params.orgId);
  let currentOrganization = null;
  if (organizations && currentOrganizationId) {
    currentOrganization = organizations.find(x => x.id === currentOrganizationId);
  }

  if (!currentOrganization) { return null; }
  const dispatch = useDispatch();

  const handleSubmitBusiness =  (values) => {
    let data = { ...values };
    dispatch(updateOrganization({ id: currentOrganizationId, ...data }));
  };

  const handleFormFailure =  (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // const colspan = isMobile ? 24 : 16;

  return (
    <LayoutProvider 
      type='internal' 
      backgroundImageStyle={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-business.png')"}}
      pageTitle={`${currentOrganization.company}`}
      noGutter
    >
      <OrganizationStyleWrapper>
        <div className={'organization-edit'}>
          <h1>Edit Business</h1>
          <span>Edit Your Business Details</span>
          <div style={{marginTop: '5vh'}}>
            <Row>
              <Col span={24}>
                <Form layout="vertical" initialValues={currentOrganization} onFinish={(values) => { handleSubmitBusiness(values); }} onFailure={(error) => { handleFormFailure(error); }} className="login-form">
                  <Row gutter={8}>
                    <Col span={24}>
                      <FormItem 
                        name="company"
                        label="Company"
                        rules={[{ required: true, message: 'Please input the company name!' }]}
                      > 
                        <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Company Name" />
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <FormItem 
                        name="address"
                        label="Address"
                        rules={[{ required: true, message: 'Please input your address!' }]}
                      >
                        <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Address"  />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <FormItem 
                        name="city"
                        label="City"
                        rules={[{ required: true, message: 'Please input the city!' }]}
                      >
                        <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="City"/>
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem 
                        name="zipCode"
                        label='Zip Code'
                        rules={[{ required: true, message: 'Please input the zip!' }]}
                      >
                        <Input size='large' prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Zipcode"/>
                      </FormItem>
                    </Col>
                  </Row>
                  
                  <FormItem
                    name="country"
                    label="Country"
                    rules={[{ required: true, message: 'Please input your country!' }]}
                  >
                    <Input size='large' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Country" />
                  </FormItem>
                  <FormItem
                    name="lniNumber"
                    label="LNI Number"
                    rules={[{ required: false, message: 'Please input your LNI Number!' }]}
                  >
                    <Input size='large' prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="LNI Number (Optional)" />
                  </FormItem>
                  <Row>
                    <Col span={12}>
                    </Col>
                    <Col span={12} style={{textAlign: 'right'}}>
                      <Button size='large' htmlType="submit" className="login-form-button" style={{borderRadius: '0px', cursor: 'pointer',  zIndex: '100', backgroundColor: '#5BBA6F', color: 'white'}}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form> 
              </Col>
              <Col span={8}>

              </Col>
            </Row>

            
          </div>
        </div>
      </OrganizationStyleWrapper>
    </LayoutProvider>
  );
};

export default withRouter(Dashboard);
