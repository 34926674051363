import axios from 'axios';
import qs from 'query-string';

const BACKEND_URL = process.env.WFIA_API_URL;

const createQueryEnpoint = (url, queryData) => {
  if (Object.keys(queryData).length > 0) {
    url = url + '?';
  }
  Object.keys(queryData).map((queryKey, index) => {
    url = index > 0 ? url.concat(`&${queryKey}=${queryData[queryKey]}`) : url.concat(`${queryKey}=${queryData[queryKey]}`);
  });
  return url;
};

const baseUrl = `${BACKEND_URL}/users`;

export default class UsersApi {
  static fetch (data) {

    return axios({
      method: 'GET',
      url: createQueryEnpoint(baseUrl, data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static fetchOne (data) {
    return axios({
      method: 'GET',
      url: `${BACKEND_URL}/users/${data.uid}?access_token=${data.access_token}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static save (data) {
    return axios({
      method: 'POST',
      url: `${BACKEND_URL}/users?access_token=${data.authToken}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static update (data) {
    return axios({
      method: 'PUT',
      url: `${BACKEND_URL}/users/${data.user}?access_token=${data.authToken}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static delete (data) {
    return axios({
      method: 'DELETE',
      url: `${BACKEND_URL}/users/${data.userId}?access_token=${data.authToken}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        console.log(response);
        return {'status':'success'};
      })
      .catch(error => {
        console.log("ERROR", error);
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static deleteAffiliation (data) {
    return axios({
      method: 'DELETE',
      url: `${BACKEND_URL}/organizationAffiliations/${data.organizationAffiliationId}?access_token=${data.authToken}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        console.log(response);
        return {'status':'success'};
      })
      .catch(error => {
        console.log("ERROR", error);
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static invite (data) {
    return axios({
      method: 'POST',
      url: `${BACKEND_URL}/users/admin/invite?access_token=${data.authToken}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static resetPassword (payload) {
    return axios({
      method: 'PUT',
      url: `${BACKEND_URL}/users/${payload.user}?access_token=${payload.access_token}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        password: payload.password
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static updateSessionUserPassword (payload) {
    return axios({
      method: 'PUT',
      url: `${BACKEND_URL}/users/me/password?access_token=${payload.authToken}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        oldPassword: payload.oldPassword,
        newPassword: payload.newPassword
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw Error(JSON.stringify(error));
      });
  }

  static checkInvitedStatus (payload) {
    return axios({
      method: 'GET',
      url: `${BACKEND_URL}/users/actions/invitedStatus?access_token=${payload.token}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static completeInviteSetup (payload) {
    const queryValues = qs.parse(location.search);
    const userToken = queryValues.token;
    return axios({
      method: 'POST',
      url: `${BACKEND_URL}/users/actions/completeInvite?access_token=${userToken}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }

  static getOrganizationMembers (payload) {
    return axios({
      method: 'GET',
      url: `${BACKEND_URL}/users/list/assignment?access_token=${payload.authToken}&organization=${payload.organization}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error.response && error.response.data ? error.response.data : error.message;
      });
  }
}
