import { put, takeEvery, all, call } from 'redux-saga/effects';
// import { notification } from 'antd';
import api from './api';
import * as actions from './actions';
import { sessionService } from 'redux-react-session';
import { notification } from 'antd';

function * fetchContentRoute (action) {
  try {
    const result = yield all(action.payload.collection.contents.map((content) => {
      return put(actions.fetchContent(content.id));
    }));
    if (result) {
      return;
    } else {
      yield put({
        type: actions.FETCH_CONTENT_ERROR,
        payload: result
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function * handleSetTutorialContentAsCompleteSuccess () {
  try {
    yield notification['success']({
      message: `Successfully Marked Content As Reviewed`,
    });
    const result = yield put(actions.getTutorialCollection());
    if (result) {
      
      return;
    } else {
      yield put({
        type: actions.GET_TUTORIAL_COLLECTION_ERROR,
        payload: result
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function * handleSetContentAsCompleteSuccess () {
  try {
    yield notification['success']({
      message: `Successfully Marked Content As Reviewed`,
    });
    const result = yield put(actions.getCurrentCollection());
    if (result) {
      return;
    } else {
      yield put({
        type: actions.GET_CURRENT_COLLECTION_ERROR,
        payload: result
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function * handleGetTutorialSuccess (action) {
  try {
    if (!action.payload.contentCompletion) {
      let user = yield sessionService.loadUser();
      const response = yield call(api.createContentCompletionRecord, { owner: user.id, relatedCollection: action.payload.id, access_token: user.token });
      if (response) {
        yield put({
          type: actions.HANDLE_CREATE_TUTORIAL_CONTENT_COMPLETION_RECORD_SUCCESS,
          payload: {...action.payload, contentCompletion: response}
        }); 
      }
    }
  } catch (err) {
    console.log(err);
  }
}

function * sagas () {
  yield takeEvery(actions.GET_CURRENT_COLLECTION_SUCCESS, fetchContentRoute);
  yield takeEvery(actions.GET_TUTORIAL_COLLECTION_SUCCESS, handleGetTutorialSuccess);
  yield takeEvery(actions.SET_TUTORIAL_CONTENT_AS_COMPLETE_SUCCESS, handleSetTutorialContentAsCompleteSuccess);
  yield takeEvery(actions.SET_CONTENT_AS_COMPLETE_SUCCESS, handleSetContentAsCompleteSuccess);
}

export default sagas;
