import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PlusCircleOutlined, DashboardOutlined } from '@ant-design/icons';
import { Modal, Input, Form, Button, Tag } from 'antd';
import { ContentCard } from '../../components/contentCard';
import { CustomGrid } from '../../components/grid';
import { LayoutProvider } from '../../layouts';
import { numberBreakpoints, stringBreakpoints } from '../../constants/breakpoints';
import withSizes from 'react-sizes';
import styled from 'styled-components';

import Logic from '../../../logic';

const { navigateToRoute } = Logic.general.router.actions;
const { createCollection } = Logic.general.collections.actions;

// STYLES
// const SubheaderWrapper = styled.div`
//   height: 100%;
//   display: flex;
//   align-items: center;
//   position: absolute;
//   right: 20%;
//   cursor: pointer;
// `;

// const SubheaderText = styled.span`
//   margin-right: 7px;
//   color: #81c890;
//   font-size: 0.7em;
// `;

const PlusButton = styled(PlusCircleOutlined)`
  font-size: 2em;
`;

const CollectionWrapper = styled.div`
  background: #f2f2f2;
  padding-top: ${p => p.gutter ? '40px' : 0};

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    background: transparent;
  }
`;

const CollectionBlock = styled.div`
  min-height: 114px;
  width: 60%;
  margin: auto;
  margin-bottom: 16px;
  padding-top: 42px;
  padding-bottom: 15px;

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    width: 100%;
    padding: 0 20px;
  }
`;

const CollectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    display: block;
  }
`;

const CollectionTitle = styled.h3`
  font-size: 2em;
  font-weight: normal;

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    background-color: #388659;
    padding: 1vh;
    color: white;
    font-family: Times New Roman;
    font-size: 30px;
    width: 56%;
    margin-bottom: 20px;
  }
`;

const Buttons = styled.button`
  padding: 6px 36px;
  cursor: pointer;

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    padding: 6px 12px;
    width: 45%;
  }
`;

// const RedButton = styled(Buttons)`
//   border: 2px solid #d77d40;
//   color: #d77d40;
// `;

const GreenButton = styled(Buttons)`
  background: #5bba6f;
  border: 2px solid #5bba6f;
  color: white;
  margin-right: 14px;

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    margin-right: 0px;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 25px;
`;

const ActionWrapper = styled.div`
  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledActionButton = styled.div`
  padding: 12.5px;
  background: #d5733f;
  cursor: pointer;
`;

const Collections = ({ isMobile }) => {
  const collections = useSelector((state) => state.containers['general::collections'].collections);
  const collectionMonths = useSelector((state) => state.containers['general::users'].initialData).collectionMonths || [];
  const dispatch = useDispatch();

  const [isModalShown, setIsModalShown] = useState(false);

  const closeModal = () => setIsModalShown(false);
  const openModal = () => setIsModalShown(true);

  const actionIconStyle = { fontSize: 30, color: 'white' };



  return (
    <LayoutProvider
      noGutter
      type='internal'
      backgroundImageStyle={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-business.png')" }}
      pageTitle={isMobile && collections.length ? collections[0].title : "Training Content Collections"}
      actionButton={
        isMobile && (
          <div className='mobile-action-items'>
            <StyledActionButton>
              <DashboardOutlined 
                style={actionIconStyle} 
                onClick={() => { dispatch(Logic.general.router.actions.navigateToRoute({ to: `/`, replace: false })); }}/>
            </StyledActionButton>
            <StyledActionButton onClick={openModal}>
              <PlusButton
                style={actionIconStyle}
              />
            </StyledActionButton>
          </div>
        )
      }
      // subheader={!isMobile && (
      //   <SubheaderWrapper onClick={openModal}>
      //     <SubheaderText>Create New Collection</SubheaderText>
      //     <PlusButton />
      //   </SubheaderWrapper>
      // )}
    >
      {
        collections.map((collection, i) => {
          const collMonths = collectionMonths.filter(x => x.collectionReference === collection.id);
          return (
            <CollectionWrapper key={collection.id} gutter={!i}>
              <CollectionBlock>
                <CollectionHeader>
                  {(isMobile && !!i || !isMobile) && <CollectionTitle>{collection.title}</CollectionTitle>}
                  <ActionWrapper>
                    <GreenButton
                      onClick={() => {
                        dispatch(navigateToRoute({ to: `/ma/collections/${collection.id}`, replace: true }));
                      }}
                    >
                      Edit Collection
                    </GreenButton>
                    {/* {
                      collection.collectionType === 'tutorial' ?
                      <RedButton>
                        Tutorial Collection
                      </RedButton>
                      :
                      <RedButton onClick={() => dispatch(deleteCollection(collection.id))}>
                        Delete Collection
                      </RedButton>  
                    } */}
                    
                  </ActionWrapper>
                </CollectionHeader>
                <CustomGrid>
                  {collection.contents.map(content => (
                    <ContentCard
                      coverImage={content.picture && content.picture.link}
                      description={content.description}
                      tags={content.tags}
                      title={content.title}
                      collectionId={collection.id}
                      contentId={content.id}
                      key={content.id}
                      bordered={isMobile}
                      role={'ma'}
                    />
                  ))}
                </CustomGrid>
                <div style={{marginTop: '20px'}}> 
                  { collMonths.map((cMonth, cKey) => {
                      var date = new Date(cMonth.monthStart);
                      date.setDate(date.getDate() + 1);
                      var monthName = new Intl.DateTimeFormat("en-US", { month: "long" }).format;
                      var longName = monthName(date);
                      return (
                        <Tag key={cKey}>{longName} {date.getFullYear()}</Tag>
                      );
                    })
                  }
                </div>
              </CollectionBlock>
            </CollectionWrapper>
          );
        })
      }

      <Modal
        visible={isModalShown}
        onCancel={closeModal}
        footer={null}
      >
        {isModalShown && (
          <StyledForm
            layout="vertical"
            onFinish={(values) => {
              dispatch(createCollection(values));
              closeModal();
            }}
          >
            <Form.Item
              name="title"
              label="Collection title"
              rules={[
                { required: true, message: 'Title is required!' }
              ]}
            >
              <Input />
            </Form.Item>

            <Button htmlType="submit">Create</Button>
          </StyledForm>
        )}
      </Modal>
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.xs,
});

export default withSizes(mapSizesToProps)(Collections);
