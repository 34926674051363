export const getAwsAccountsValidated = (session) => {
  try {
    if (session.user.organizationAffiliations[0].organization.awsAccounts.length > 0) { 
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
  
};

