import React from 'react';
import { LayoutProvider } from '../../layouts';
// import { Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CustomGrid } from '../../components/grid';
import { ContentCard } from '../../components/contentCard';
import { DashboardOutlined } from '@ant-design/icons';
import withSizes from 'react-sizes';
// import { Table, Avatar } from 'antd';
// import Logic from '../../../logic';
import { stringBreakpoints, numberBreakpoints } from '../../../app/constants/breakpoints';
import styled from 'styled-components';
import Logic from '../../../logic';

const OrganizationStyleWrapper = styled.div`
  .ant-divider {
    margin: 5px 0px;
  }
  .invite-form {
    margin-top: 20px;
  }
  .current-month-content {
    padding: 10vh 20vw;
    &__title {
      font-family: Times New Roman;
    }
    &__subtitle {
      margin-bottom: 20px;
    }
  }
  .future-collection {
    margin: 5vh 0vw;
    &__title {
      font-family: Times New Roman;
    }
    &__subtitle {
      margin-bottom: 50px;
    }
  }

  @media only screen and (max-width: ${stringBreakpoints.sm}) {
    .current-month-content {
      padding: 10vh 2vw;
    }
  }
`;

const MainWrapper = styled.div`
  
`;
const LeftColumn = styled.div`
  width: 100%;
`;

const StyledActionButton = styled.div`
  padding: 12.5px;
  background: #d5733f;
  cursor: pointer;
`;

const actionIconStyle = { fontSize: 30, color: 'white' };


export const Dashboard = ({ isMobile }) => {
  const upcomingCalendar = useSelector((state) => state.containers['employee::collections'].upcomingCalendar);
  const tutorialCollection = useSelector((state) => state.containers['employee::collections'].tutorialCollection);
  const { user } = useSelector(state => state.session);
  const dispatch = useDispatch();
  
  if (tutorialCollection && tutorialCollection.contentCompletion && !tutorialCollection.contentCompletion.isDone) {
    return (
      <LayoutProvider 
      type='internal' 
      noGutter
      backgroundImageStyle={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-bins.png')"}}
      pageTitle={'Monthly Training Content'}
      actionButton={
        isMobile && (
          <div className='mobile-action-items'>
            <StyledActionButton>
              <DashboardOutlined 
                style={actionIconStyle} 
                onClick={() => { dispatch(Logic.general.router.actions.navigateToRoute({ to: `/`, replace: false })); }}/>
            </StyledActionButton>
          </div>
        )
      }

    >
      <OrganizationStyleWrapper>
        <div className={'current-month-content'}>
          <div className="tutorial-content">
            <h1 className="tutorial-content__title">Tutorial Documents</h1>
            <div className="tutorial-content__subtitle">Please review and complete the following pieces of content that have been assigned for your review.</div>
            <MainWrapper>
            <LeftColumn>
              <div>
                {/* <h3 style={{ fontSize: '2em', fontWeight: 'normal' }}>{currentCollection.title}</h3> */}
                <CustomGrid>
                  {tutorialCollection.contents.map(content => (
                    <ContentCard
                      coverImage={content.picture && content.picture.link}
                      description={content.description}
                      tags={['Tutorial']}
                      title={content.title}
                      key={content.id}
                      contentId={content.id}
                      collectionId={tutorialCollection.id}
                      bordered
                      role={user.role || 'employee'}
                      tutorial
                    />
                  ))}
                </CustomGrid>
              </div>
            </LeftColumn>
            </MainWrapper>
          </div>
        </div>
      </OrganizationStyleWrapper>
    </LayoutProvider>
    );
  }

  if (upcomingCalendar.length === 0) {
    return (
      <LayoutProvider 
        type='internal' 
        noGutter
        backgroundImageStyle={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-bins.png')"}}
        pageTitle={'Monthly Content'}
      >
        <OrganizationStyleWrapper>
          <div className={'current-month-content'}>
            <h1 className={'current-month-content__title'}>No Current Year Past Content</h1>
          </div>
        </OrganizationStyleWrapper>
      </LayoutProvider>
      );
  }
  return (
    <LayoutProvider 
      type='internal' 
      noGutter
      backgroundImageStyle={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-bins.png')"}}
      pageTitle={'Monthly Content'}
      actionButton={
        isMobile && (
          <div className='mobile-action-items'>
            <StyledActionButton>
              <DashboardOutlined 
                style={actionIconStyle} 
                onClick={() => { dispatch(Logic.general.router.actions.navigateToRoute({ to: `/`, replace: false })); }}/>
            </StyledActionButton>
          </div>
        )
      }
    >
      <OrganizationStyleWrapper>
        <div className={'current-month-content'}>
          {
            upcomingCalendar.map((collection, collKey) => {
              return (
                <div key={collKey} className={'future-collection'}>
                  <LeftColumn>
                    <div>
                      <h1 className={'future-collection__title'}>{collection.month}</h1>
                      <div className={'future-collection__subtitle'}>The articles and content below represent all of the items that your employer requested for your review in the given month.</div>
                      <CustomGrid>
                        {collection.monthContent.contents.map(content => (
                          <ContentCard
                            coverImage={content.picture && content.picture.link}
                            description={content.description}
                            tags={content.tags}
                            title={content.title}
                            key={content.id}
                            contentId={content.id}
                            collectionId={collection.monthContent.id}
                            review={true}
                            bordered
                            role={user.role || 'employee'}
                          />
                        ))}
                      </CustomGrid>
                    </div>
                  </LeftColumn>
                </div>
              );
              
            })
          }
        </div>
      </OrganizationStyleWrapper>
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.sm,
});

export default withRouter(withSizes(mapSizesToProps)(Dashboard));
