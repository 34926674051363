import * as actions from './actions';

export default function (state = {
  providers: [],
  patients: [],
  userIdImage: null,
  initialData: {}
}, action) {
  let stateCopy = { ...state };
  switch (action.type) {
    case actions.FETCH_INITIAL_DATA_SUCCESS:
      stateCopy.initialDataLoaded = true;
      stateCopy.fetchingInitialData = false;
      stateCopy.initialData = action.payload;
      return stateCopy;
    case `APP/UPDATE_NOTIFICATION_SUCCESS`: {
      let test = {...stateCopy.initialData };
      test.notifications = [...stateCopy.initialData.notifications.filter(x => x.id !== action.payload.id), action.payload];
      stateCopy.initialData = test;
      return stateCopy;
    }
    case actions.FETCH_INITIAL_DATA_BEGIN:
      stateCopy.fetchingInitialData = true;
      return stateCopy;
    case actions.GET_ORGANIZATION_MEMBERS_SUCCESS: 
      stateCopy.organizationMembers = action.payload;
      return stateCopy;
    default:
      return state;
  }
}
