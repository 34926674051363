const xs = 576;
const sm = 768;
const md = 993;

export const stringBreakpoints = {
  xs: `${xs}px`,
  sm: `${sm}px`,
  md: `${md}px`
};

export const numberBreakpoints = {
  xs,
  sm,
  md
};
