import React, { Component} from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import RouteWrapperData from './base-routes-wrapper-data';
import * as pages from '../app/pages';
// import { getAvailableStates } from '../logic/general/app/actions';

export default class BaseUnuthenticatedRoutes extends Component {
  constructor (props) {
    super(props);

    this.state = {

    };
  }

  render () {
    return (
      <Switch>
        <Route
          exact
          path='/'
          component={pages.Unauthenticated.Home}
          key={'home-'}
        />
        <RouteWrapperData
          exact
          path='/register'
          component={pages.Unauthenticated.Register}
          key={'register-'}
          dataActions={[
            
          ]}
        />
        <RouteWrapperData
          exact
          path='/login'
          component={pages.Unauthenticated.Home}
          key={'login-' + Math.random()}
          routeId='LOGIN'
          dataActions={[

          ]}
        />
        <RouteWrapperData
          exact
          path='/forgot'
          component={pages.Unauthenticated.ForgotPassword}
          dataActions={[
          ]}
        />
        <RouteWrapperData
          exact
          path='/password-reset'
          component={pages.Unauthenticated.ResetPassword}
          dataActions={[
          ]}
        />

        <RouteWrapperData
          exact
          path='/account/create'
          component={pages.Unauthenticated.AccountCreate}
          dataActions={[
          ]}
        />

        <Route render={() => { 
          if (this.props.session.checked) {
            return <Redirect to={`/?onAuth=${document.location.pathname}${document.location.search}`} key={'home23-'}/>; 
          }
          else {
            return null;
          }
        }}/>

      </Switch>
    );
  }
}
