import React, { useEffect, useRef, useState } from 'react';
import { parse } from 'query-string';
import withSizes from 'react-sizes';
import { LayoutProvider } from '../../layouts';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Input, Tooltip, Form, Select, Modal, notification, Collapse, Row, Col } from 'antd';
import { Editor } from '../../components/editor';
import { CloseOutlined, CheckCircleOutlined, CheckOutlined, DashboardOutlined } from '@ant-design/icons';
import Logic from '../../../logic';
import { numberBreakpoints, stringBreakpoints } from '../../constants/breakpoints';
import { MAX_IMAGE_UPLOAD_SIZE_MB, ACCEPT_IMAGES_ONLY_RULE } from '../../constants/files';

// CONSTANTS
const PROPERTY_KEYS = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  ATTACHMENTS: 'attachments',
  PICTURE: 'picture',
  COLLECTION: 'relatedCollection',
  TAGS: 'tags',
  APPROVED: 'isApproved'
};

const MODES = {
  UPDATE: 'update',
  CREATE: 'create'
};

// STYLES
const SubheaderWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  right: 20%;
  color: #5dbb71;
`;

const SubheaderText = styled.span`
  margin-right: 7px;
  color: #81c890;
  font-size: 0.7em;
`;

const CircleIcon = styled(CheckCircleOutlined)`
  font-size: 2em;
`;

const ContentEdit = styled(Form)`
  padding: 2vh 15vw;
  width: 100%;
  margin-left: auto;

  .content-edit {
    &__first-row {
      width: 10%;
    }
    &__second-row {
      width: 62%;
    }
    &__third-row {
      width: 21.5%;
    }
  }

  @media only screen and (max-width: ${stringBreakpoints.md}) {
    width: 100%;
    padding: 7vh 20px 20px;

    .content-edit {
      &__first-row {
        display: none;
      }
      &__second-row {
        width: 100%;
      }
      &__third-row {
        width: 100%;
      }
    }
  }
`;

ContentEdit.Container = styled.div`
  display: flex;
  justify-content: ${props => props.justify ? props.justify : 'space-between'};
  flex-direction: ${props => props.direction ? props.direction : 'row'};
  align-items: ${props => props.align ? props.align : 'initial'};
  flex-wrap: wrap;
`;
ContentEdit.Column = styled.div`
  width: ${props => props.width ? props.width : 'auto'};
`;
ContentEdit.Label = styled.div`
`;
ContentEdit.Title = styled.div`
`;
ContentEdit.CoverPhoto = styled.img`
  height: 200px;
  width: 100%;
  margin-bottom: 25px;

  @media only screen and (max-width: ${stringBreakpoints.md}) {
    height: auto;
  }
`;
ContentEdit.FileTypeLimitations = styled.div`
  max-width: 27.5%;
  text-align: left;
  font-family: 'Lato';
  font-size: 10px;
`;
ContentEdit.FileTypeLimitations.Header = styled.h4`
  font-size: 1em;
  margin-bottom: 5px;
`;
ContentEdit.FileTypeLimitations.Description = styled.p`
  font-size: 0.8em;
`;
ContentEdit.Tooltip = styled(Tooltip)`
`;
ContentEdit.BottomActionBar = styled.div`
  margin-top: 20px;

`;

ContentEdit.Inputs = {};
ContentEdit.Inputs.Title = styled(Input)`
`;
ContentEdit.Inputs.Description = styled(Editor)`
`;
ContentEdit.Inputs.Attachments = styled(Input)`
`;

ContentEdit.Buttons = {};
ContentEdit.Buttons.Green = styled.button`
  padding: 6px 18px;
  color: white;
  border: none;
  background: #5bba6f;
  cursor: pointer;
`;
ContentEdit.Buttons.Orange = styled(Button)`
  padding: 6px 18px;
  color: white;
  border: none;
  cursor: pointer;
  background: #d5733f;
`;
ContentEdit.Buttons.Grey = styled(Button)`
  padding: 6px 18px;
  color: white;
  border: none;
  cursor: pointer;
  background: darkgrey;
`;

const BlockWrapper = styled.div`
  padding-top: 14.5px;
  padding-bottom: 30px;

  border-top: 1px solid #b4b4b4;
  border-bottom: 1px solid #b4b4b4;
`;
BlockWrapper.FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
BlockWrapper.FirstRow.Title = styled.h3`
  font-size: 22px;
  font-weight: normal;
  font-family: "Lora";
`;

const actionIconStyle = { fontSize: 30, color: 'white' };

const { Panel } = Collapse;

const Block = ({ children, title, buttonText, style, onClick }) => (
  <BlockWrapper style={style}>
    <BlockWrapper.FirstRow>
      <BlockWrapper.FirstRow.Title>{title}</BlockWrapper.FirstRow.Title>
      <ContentEdit.Buttons.Green type="button" onClick={onClick}>{buttonText}</ContentEdit.Buttons.Green>
    </BlockWrapper.FirstRow>
    {children}
  </BlockWrapper>
);

const FileView = ({ file, onClick }) => {
  return (
    <FileView.Wrapper>
      <FileView.FileName href={file.link} target="_blank">{file.name}</FileView.FileName>
      <FileView.CrossIcon onClick={onClick} />
    </FileView.Wrapper>
  );
};

FileView.Wrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;
FileView.FileName = styled.a`
  margin-right: 4px;
  color: black;
`;
FileView.CrossIcon = styled(CloseOutlined)`
  color: #d5733f;
  font-size: 1.3em;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

const StyledActionButton = styled.div`
  padding: 12.5px;
  background: #d5733f;
  cursor: pointer;
`;

// HELPERS
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

// ACTIONS
const { fetchContent, createContent, updateContent } = Logic.ma.contents.actions;
const { navigateToRoute } = Logic.general.router.actions;

// TARGET COMPONENT
export const PureContentEdit = ({ location, match, isMobile }) => {
  // Getting state data and store.dispatch
  const content = useSelector(state => state.containers['ma::contents'].content);
  const collections = useSelector(state => state.containers['general::collections'].collections);
  const currentCollection = useSelector(state => state.containers['general::collections'].currentCollection);
  const dispatch = useDispatch();

  // Determining the data mode we are working with
  const contentId = parse(location.search).content || (content && content.id);
  const mode = typeof contentId === 'string' ? MODES.UPDATE : MODES.CREATE;

  // Instantiating the handler that would be used for save action.
  // Since it has one determined interface the components can use the same data flow. 
  const saveContentActionHandler = mode === MODES.UPDATE
    ? (values) => dispatch(updateContent({ id: contentId, body: values }))
    : (values) => dispatch(createContent({ ...values, [PROPERTY_KEYS.COLLECTION]: match.params.collection }));

  // Fetching data if in required mode
  useEffect(() => {
    if (mode !== MODES.UPDATE) return;
    dispatch(fetchContent(contentId));
  }, []);

  // Storing the DOM object reference of the hidden file input
  const attachmentsFileInputRef = useRef(null);
  const coverImageFileInputRef = useRef(null);

  const [form] = Form.useForm();

  const [isTagEditable, setIsTagEditable] = useState(false);
  const [isCollectionEditable, setIsCollectionEditable] = useState(false);

  const handleSubmit = (values) => {
    saveContentActionHandler(values);
    if (isTagEditable) {
      setIsTagEditable(false);
    }
    if (isCollectionEditable) {
      setIsCollectionEditable(false);
    }
  };

  const initialValues = mode === MODES.UPDATE && content ? Object.keys(PROPERTY_KEYS).reduce((acc, key) => ({ ...acc, [PROPERTY_KEYS[key]]: content[PROPERTY_KEYS[key]] }), {}) : {};
  const shouldShowContentForm = mode === MODES.CREATE || (mode === MODES.UPDATE && content);

  return (
    <LayoutProvider
      noGutter
      type='internal'
      backgroundImageStyle={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundImage: "url('https://wfia-dev.s3-us-west-2.amazonaws.com/web-assets/wfia-business.png')" }}
      pageTitle={initialValues[PROPERTY_KEYS.TITLE] || "Collection"}
      actionButton={
        isMobile && (
          <div className='mobile-action-items'>
            <StyledActionButton>
              <DashboardOutlined 
                style={actionIconStyle} 
                onClick={() => { dispatch(Logic.general.router.actions.navigateToRoute({ to: `/`, replace: false })); }}/>
            </StyledActionButton>
          </div>
        )
      }
      subheader={isMobile ? null : content && content.isApproved ? (
        <SubheaderWrapper>
          <SubheaderText>Approved</SubheaderText>
          <CheckOutlined style={{ fontSize: '2em' }} />
        </SubheaderWrapper>

      ) : (
          <SubheaderWrapper
            onClick={() => saveContentActionHandler({ [PROPERTY_KEYS.APPROVED]: true })}
            style={{ cursor: 'pointer' }}
          >
            <SubheaderText>Publish</SubheaderText>
            <CircleIcon />
          </SubheaderWrapper>
        )}
    >
      {shouldShowContentForm && (
        <ContentEdit onFinish={handleSubmit} initialValues={initialValues} form={form} >
          <ContentEdit.Container>
            <Collapse accordion style={{width: '100%'}} defaultActiveKey={['1']} >
              <Panel header="Content Metadata" key="1" extra={<div>
              <a 
                onClick={() => {
                  dispatch(navigateToRoute({
                    to: `/ma/content/past-view?content=${contentId}`, 
                    replace: true
                  }));}} 
                style={{ width: '40%', padding: '12.5px 30px' }}
              >
                Preview
              </a>
            </div>}>
                <ContentEdit.Column className="content-edit__first-row">
                  <ContentEdit.Container align="center">
                    <ContentEdit.Label>Summary</ContentEdit.Label>
                  </ContentEdit.Container>
                </ContentEdit.Column>

                <ContentEdit.Column className="content-edit__second-row">
                  <Form.Item name={PROPERTY_KEYS.TITLE} style={{ width: '40%' }}>
                    <ContentEdit.Inputs.Title placeholder="Title" />
                  </Form.Item>
                  <Form.Item name={PROPERTY_KEYS.DESCRIPTION}>
                    {/* Editor has a different data flow, that's why is handled differently  */}
                    {
                      mode === MODES.UPDATE && content && (
                        <ContentEdit.Inputs.Description
                          style={{ minHeight: 250 }}
                          defaultValue={content[PROPERTY_KEYS.DESCRIPTION]}
                          onChange={v => form.setFieldsValue({ [PROPERTY_KEYS.DESCRIPTION]: v })}
                        />
                      )
                    }
                    {
                      mode === MODES.CREATE && (
                        <ContentEdit.Inputs.Description
                          style={{ minHeight: 250 }}
                          onChange={v => form.setFieldsValue({ [PROPERTY_KEYS.DESCRIPTION]: v })}
                        />
                      )
                    }
                  </Form.Item>
                  <ContentEdit.Buttons.Green type="submit">Save</ContentEdit.Buttons.Green>

                </ContentEdit.Column>
              </Panel>
              <Panel header="Content Files" key="2">
                <Row gutter={32}>
                  <Col xs={24} sm={12}>
                    <BlockWrapper.FirstRow.Title style={{ marginBottom: 20 }}>Feature Image</BlockWrapper.FirstRow.Title>
                    <ContentEdit.CoverPhoto src={content && content[PROPERTY_KEYS.PICTURE] ? content[PROPERTY_KEYS.PICTURE].link : ''} />
                    <ContentEdit.Container style={{ marginBottom: 35 }} justify="space-around" >
                      <ContentEdit.Buttons.Green style={{ marginBottom: 10 }} type="button" onClick={() => coverImageFileInputRef.current.click()}>Upload a new photo</ContentEdit.Buttons.Green>
                      <ContentEdit.Buttons.Orange type="button" onClick={() => saveContentActionHandler({ [PROPERTY_KEYS.PICTURE]: { action: 'delete' } })}>Delete Photo</ContentEdit.Buttons.Orange>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={coverImageFileInputRef}
                        accept={ACCEPT_IMAGES_ONLY_RULE}
                        onChange={async e => {
                          const file = e.target.files[0];

                          if (file.size > MAX_IMAGE_UPLOAD_SIZE_MB * 1024 * 1024) {
                            return notification.warning({
                              message: 'File size limit exceeded!',
                              description: `Select file up to ${MAX_IMAGE_UPLOAD_SIZE_MB}MB.`
                            });
                          }

                          const convertedFile = await toBase64(file);
                          const structuredFile = ({
                            data: convertedFile,
                            fileName: file.name
                          });
                          // form.submit();
                          saveContentActionHandler({
                            [PROPERTY_KEYS.PICTURE]: structuredFile
                          });
                        }}
                      />
                    </ContentEdit.Container>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Block
                      title="Attachments"
                      buttonText="Add"
                      style={{ width: '70%' }}
                      onClick={() => attachmentsFileInputRef.current.click()}
                    >
                      <div>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={attachmentsFileInputRef}
                          onChange={async e => {
                            const file = e.target.files[0];
                            const convertedFile = await toBase64(file);
                            const structuredFile = ({
                              data: convertedFile,
                              fileName: file.name,
                            });

                            const attachments = mode === MODES.UPDATE && content
                              ? [...content[PROPERTY_KEYS.ATTACHMENTS], structuredFile]
                              : [structuredFile];

                            saveContentActionHandler({
                              [PROPERTY_KEYS.ATTACHMENTS]: attachments
                            });
                          }}
                        />
                        {content && !!content[PROPERTY_KEYS.ATTACHMENTS].length && (
                          <div>
                            {content[PROPERTY_KEYS.ATTACHMENTS].map(attachment => (
                              <FileView
                                file={attachment}
                                key={attachment.id}
                                onClick={() => saveContentActionHandler({
                                  [PROPERTY_KEYS.ATTACHMENTS]: content[PROPERTY_KEYS.ATTACHMENTS].filter(el => el.id !== attachment.id)
                                })}
                              />)
                            )}
                          </div>
                        )}
                      </div>
                    </Block>
                    <ContentEdit.FileTypeLimitations>
                      <ContentEdit.FileTypeLimitations.Header>Filetype limitations</ContentEdit.FileTypeLimitations.Header>
                      <ContentEdit.FileTypeLimitations.Description>
                        JPG, PNG, PDF, DOC, XLSX, MP4 accepted
                          </ContentEdit.FileTypeLimitations.Description>
                    </ContentEdit.FileTypeLimitations>
                  </Col>
                </Row>
              </Panel>
              <Panel header="Content Assignments" key="3">
                <Block title="Tagging" buttonText="Edit" onClick={() => setIsTagEditable(true)}>
                  <Select style={{width: '100%'}} mode="multiple" onChange={(e) => {
                    saveContentActionHandler({
                      [PROPERTY_KEYS.TAGS]: e
                    });
                  }}>
                    <Select.Option value="Grocery">Grocery</Select.Option>
                    <Select.Option value="Trucking">Trucking</Select.Option>
                  </Select>
                </Block>
                {
                  mode === MODES.UPDATE && (
                    <Block title="Collection" buttonText="Edit" onClick={() => setIsCollectionEditable(true)}>
                      Collection: {currentCollection && currentCollection.title}
                    </Block>
                  )
                }
                <Modal
                  visible={isCollectionEditable}
                  onCancel={() => setIsCollectionEditable(false)}
                  footer={null}
                >
                  {isCollectionEditable && (
                    <Form
                      onFinish={(v) => {
                        handleSubmit(v); 
                        setTimeout(function () {
                          dispatch(navigateToRoute({
                            to: `/ma/collections/${v[PROPERTY_KEYS.COLLECTION]}/content-edit?content=${contentId}`, 
                            replace: true
                          }));
                        }, 2000);
                        
                      }} 
                      style={{ marginTop: 15 }}
                      layout="vertical"
                    >
                      <Form.Item 
                        label="Collection to move content into"
                        name={PROPERTY_KEYS.COLLECTION}
                        rules={[
                          { required: true, message: 'Please select a collection!' }
                        ]}
                      >
                        <Select>
                          {collections.filter(coll => coll.id !== match.params.collection).map(coll => (
                            <Select.Option
                              key={coll.id}
                              value={coll.id}
                            >
                              {coll.title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Button htmlType="submit">Move content</Button>
                    </Form>
                  )}
                </Modal>
              </Panel>
            </Collapse>       
          </ContentEdit.Container>
        </ContentEdit>)
      }
    </LayoutProvider>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < numberBreakpoints.md,
});

export default withRouter(withSizes(mapSizesToProps)(PureContentEdit));
