import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { sessionReducer } from 'redux-react-session';
import Logic from '../logic';

const reducers = {};
Object.keys(Logic).map((module) => {
	// mmodule would be admin, general, patient, etc;
	Object.keys(Logic[module]).map((subModule) => {
		reducers[`${module}::${subModule}`] = Logic[module][subModule].reducer;
	});
});

const containersReducer = {
  containers: combineReducers(reducers)
};
// reducers.js

const createGlobalReducer = (history) => combineReducers({
  session: sessionReducer,
  router: connectRouter(history),
  ...containersReducer
});

export default createGlobalReducer;