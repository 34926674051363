const ROOT = 'APP';

export const FETCH_CONTENT = `${ROOT}/FETCH_CONTENT`;
export const FETCH_CONTENT_SUCCESS = `${ROOT}/FETCH_CONTENT_SUCCESS`;
export const FETCH_CONTENT_ERROR = `${ROOT}/FETCH_CONTENT_ERROR`;

export function fetchContent (contentId) {
  contentId = typeof contentId === 'object' ? contentId.query.content : contentId;
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/contents/${contentId}`,
      method: 'GET',
      params: {},
      query: {},
      body: {},
      auth: null
    },
    success: {
      actions: [FETCH_CONTENT_SUCCESS]
    },
    error: {
      actions: [FETCH_CONTENT_ERROR]
    }
  };
}

export const CREATE_CONTENT = `${ROOT}/CREATE_CONTENT`;
export const CREATE_CONTENT_SUCCESS = `${ROOT}/CREATE_CONTENT_SUCCESS`;
export const CREATE_CONTENT_ERROR = `${ROOT}/CREATE_CONTENT_ERROR`;

export function createContent (body) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/contents`,
      method: 'POST',
      params: {},
      query: {},
      body,
      auth: null
    },
    success: {
      actions: [CREATE_CONTENT_SUCCESS]
    },
    error: {
      actions: [CREATE_CONTENT_ERROR]
    }
  };
}

export const UPDATE_CONTENT = `${ROOT}/UPDATE_CONTENT`;
export const UPDATE_CONTENT_SUCCESS = `${ROOT}/UPDATE_CONTENT_SUCCESS`;
export const UPDATE_CONTENT_ERROR = `${ROOT}/UPDATE_CONTENT_ERROR`;

export function updateContent ({ id, body }) {
  return {
    type: `APP/MAKE_API_REQUEST`,
    payload: {},
    apiCall: {
      authenticated: true,
      master: true,
      route: `/contents/${id}`,
      method: 'PUT',
      params: {},
      query: {},
      body,
      auth: null
    },
    success: {
      actions: [UPDATE_CONTENT_SUCCESS]
    },
    error: {
      actions: [UPDATE_CONTENT_ERROR]
    }
  };
}

export const RESET_CONTENTS_REDUCER = `${ROOT}/RESET_CONTENTS_REDUCER`;

export const resetContentsReducer = () => ({
  type: RESET_CONTENTS_REDUCER
});
